import dataProvider from "./dataProvider";

const myDataProvider = {
    ...dataProvider,
    create: (resource:any, params:any) => {
        if (resource !== 'blog' &&
            resource !== 'job' &&
            resource !== 'notification' &&
            resource !== 'attachment' &&
            resource !== 'coworker' &&
            resource !== 'office' &&
            resource !== 'employer-contact-person' &&
            resource !== 'document'
        ) {
            return dataProvider.create(resource, params);
        }
        const isImageProp = resource === 'notification' || resource === 'job' || resource === 'office';
        const isProfilePicProp = resource === 'coworker' || resource === 'employer-contact-person';
        const isUrlProp = params.data.hasOwnProperty('url') && resource === 'document';
        const imageOrThumbnail =  resource === 'blog' ? params.data.thumbnail :
            isImageProp ? params.data.image :
                isProfilePicProp ? params.data.profilePicture :
                    isUrlProp ? params.data.url :
                        params.data.path;

        const isRequired = resource === 'blog' || resource === 'attachment' || resource === 'office' || isUrlProp;
        if(isRequired && (imageOrThumbnail === null || typeof imageOrThumbnail === 'undefined')) {
            return Promise.reject(new Error(isUrlProp ? 'Fájl megadása kötelező' : 'Kép megadása kötelező'))
        }
        const imageArray = typeof imageOrThumbnail !== 'undefined' ? [imageOrThumbnail] : [];
        const newPictures = imageArray.filter(
            (p:any) => p.rawFile instanceof File
        );

        return Promise.all(newPictures.map(convertFileToBase64))
            .then(transformedNewPictures =>
                dataProvider.create(resource, resource === 'blog' ? {
                    ...params,
                    data: {
                        ...params.data,
                        thumbnail: transformedNewPictures[0]
                    },
                } : isImageProp ? {
                    ...params,
                    data: {
                        ...params.data,
                        image: transformedNewPictures[0],
                    },
                } : isProfilePicProp ? {
                    ...params,
                    data: {
                        ...params.data,
                        profilePicture: transformedNewPictures[0],
                    },
                } : resource === 'document' ? isUrlProp ? {
                    ...params,
                    data: {
                        ...params.data,
                        url: transformedNewPictures[0],
                        name: params.data.url.title
                    },
                } : {
                    ...params,
                    data: {
                        ...params.data,
                        url: null,
                    },
                }: {
                    ...params,
                    data: {
                        ...params.data,
                        path: transformedNewPictures[0],
                    },
                })
            );
    },
    update: (resource:any, params:any) => {
        if(resource === 'admin-user') {
            return dataProvider.update(resource, {
                ...params,
                data: {
                    roles: params.data.roles,
                },
            })
        }
        if (resource !== 'blog' &&
            resource !== 'job' &&
            resource !== 'notification' &&
            resource !== 'attachment' &&
            resource !== 'coworker' &&
            resource !== 'office' &&
            resource !== 'employer-contact-person'
        ) {
            return dataProvider.update(resource, params);
        }
        const isImageProp = resource === 'notification' || resource === 'job' || resource === 'office';
        const isProfilePicProp = resource === 'coworker' || resource === 'employer-contact-person';
        const imageOrThumbnail =  resource === 'blog' ? params.data.thumbnail : isImageProp ? params.data.image : isProfilePicProp ? params.data.profilePicture : params.data.path;

        const isRequired = resource === 'blog' || resource === 'attachment' || resource === 'office';

        if(isRequired && (imageOrThumbnail === null || typeof imageOrThumbnail === 'undefined')) {
            return Promise.reject(new Error('Kép megadása kötelező'))
        }
        const imageArray = imageOrThumbnail !== null ? [imageOrThumbnail] : [];
        const newPictures = imageArray.filter(
            (p:any) => p.rawFile instanceof File
        );

        return Promise.all(newPictures.map(convertFileToBase64))
            .then(transformedNewPictures =>
                dataProvider.update(resource, resource === 'blog' ? {
                    ...params,
                    data: {
                        ...params.data,
                        thumbnail: newPictures.length === 0 ? imageOrThumbnail !== null ? params.data.thumbnail : null : transformedNewPictures[0],
                    },
                } : isImageProp ? {
                    ...params,
                    data: {
                        ...params.data,
                        image: newPictures.length === 0 ? imageOrThumbnail !== null ? params.data.image : null : transformedNewPictures[0],
                    },
                } : isProfilePicProp ? {
                    ...params,
                    data: {
                        ...params.data,
                        profilePicture: newPictures.length === 0 ? imageOrThumbnail !== null ?  params.data.profilePicture : null : transformedNewPictures[0],
                    },
                } : {
                    ...params,
                    data: {
                        ...params.data,
                        path: newPictures.length === 0 ?  imageOrThumbnail !== null ?  params.data.path : null : transformedNewPictures[0],
                    },
                })
            );
    },
};

/**
 * Convert a `File` object returned by the upload input into a base 64 string.
 * That's not the most optimized way to store images in production, but it's
 * enough to illustrate the idea of data provider decoration.
 */
const convertFileToBase64 = (file:any) =>
    new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.onload = () => resolve(reader.result);
        reader.onerror = reject;

        reader.readAsDataURL(file.rawFile);
    });

export default myDataProvider;
