import * as React from "react";
import {Create, Edit, ImageField, ImageInput, SimpleForm, TextInput, useRecordContext, NumberInput} from 'react-admin';
import {Box, Grid, Typography} from "@mui/material";

const OfficeForm = () => {
    const record = useRecordContext();

    return <SimpleForm>
        <Grid container item xs={12} spacing={2}>
            <Grid item xs={12}>
                <TextInput source="name" label={"Név (kötelező)"} name={"name"} fullWidth required/>
            </Grid>
        </Grid>
        <Grid container item xs={12} spacing={2}>
            <Grid item xs={6}>
                <TextInput source="phone" label={"Telefonszám (kötelező)"} name={"phone"} fullWidth required/>
            </Grid>
            <Grid item xs={6}>
                <TextInput source="email" label={"E-mail (kötelező)"} name={"email"} fullWidth required/>
            </Grid>
        </Grid>
        <Grid container item xs={12} spacing={2}>
            <Grid item xs={12}>
                <Typography fontSize={'14px'}>Cím</Typography>
            </Grid>
            <Grid item xs={2}>
                <TextInput source="address.country" label={"Ország (kötelező)"}
                           name={"address.country"} fullWidth required defaultValue={"Magyarország"}/>
            </Grid>
            <Grid item xs={2}>
                <TextInput source="address.zip" label={"Ir.szám (kötelező)"} name={"address.zip"}
                           fullWidth required/>
            </Grid>
            <Grid item xs={4}>
                <TextInput source="address.city" label={"Város (kötelező)"} name={"address.city"}
                           fullWidth required/>
            </Grid>
            <Grid item xs={4}>
                <TextInput source="address.address" label={"Cím (kötelező)"}
                           name={"address.address"} fullWidth required/>
            </Grid>
        </Grid>
        <Grid container item xs={12} spacing={2}>
            <Grid item xs={6}>
                <NumberInput source="location.latitude" label={"Szélességi fok (kötelező)"} name={"location.latitude"} fullWidth required/>
            </Grid>
            <Grid item xs={6}>
                <NumberInput source="location.longitude" label={"Hosszúsági fok (kötelező)"} name={"location.longitude"} fullWidth required/>
            </Grid>
        </Grid>
        <Grid container item xs={12} spacing={2}>
            <Grid item xs={6}>
                <TextInput source="openingHours" label={"Nyitvatartás (kötelező)"} name={"openingHours"} multiline fullWidth required/>
            </Grid>
        </Grid>
        {(record && record.hasOwnProperty("image")) && record.image !== null && <Box>
            <Typography sx={{fontSize: '0.75em', color: '#B2BAC2', marginBottom: '0.2em'}}>Jelenlegi kép</Typography>
            <img src={`${record.image}`} alt={"current picture"}
                 style={{margin: '10px 0px', maxHeight: '200px'}}/>
        </Box>
        }
        <ImageInput source="image" label="Index kép (kötelező)" accept="image/*" placeholder={<p>Kép megadása</p>} name={"image"}
                    isRequired>
            <ImageField source="src" title="title"/>
        </ImageInput>
    </SimpleForm>
}

export const OfficeCreate = () => (
    <Create title={'Iroda létrehozása'}>
        <OfficeForm/>
    </Create>
);

export const OfficeEdit = () => (
    <Edit title={'Iroda szerkesztése'}>
        <OfficeForm/>
    </Edit>
);