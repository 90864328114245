import * as React from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import {FileField, FileInput, FormDataConsumer, SimpleForm, useNotify, useRefresh} from "react-admin";
import AddCircleOutlineOutlinedIcon from "@mui/icons-material/AddCircleOutlineOutlined";
import Box from "@mui/material/Box";
import {apiUrl, httpClient} from "../dataProvider";
import {Stack} from "@mui/material";
import FileDownloadOutlinedIcon from "@mui/icons-material/FileDownloadOutlined";
import {exportExcel} from "../export-excel";
import {dateTimeFormatter} from "../date-formatter/date-formatter";
import {getJsDateFromExcel} from 'excel-date-to-js';
import {loadExcel} from "../employee/bulk-employee-dialog";

interface Props {
    isOpen: boolean;
    setIsOpen: any;
}

const getSalaryType = (type:any) => {
    if(type === '' || type === null || typeof type === 'undefined') {
        return null;
    }
    switch (type) {
        case "Fix":
            return "Fix"
        case "Órabér":
            return "HourlyWage"
        default:
            return null;
    }
}

const getEmploymentType = (type:any) => {
    if(type === '' || type === null || typeof type === 'undefined') {
        return null;
    }
    switch (type) {
        case "Bejelentve":
            return "White"
        case "Nincs bejelentve":
            return "Black"
        default:
            return null;
    }
}

export const BulkJobApplicationDialog = (props: Props) => {
    const handleClose = () => {
        props.setIsOpen(false);
    };

    const notify = useNotify();
    const excelValues = [{
        'Munkavállaló e-mail (kötelező)': 'pelda.balazs@gmail.com (EZT A SORT TÖRÖLJÜK KI)',
        'Munka ID (kötelező)': '14',
        'Bér': '5000',
        'Bérezés típusa': 'Fix/Órabér',
        'Bejelentés státusza': 'Bejelentve/Nincs bejelentve',
        'Mikortól tud jönni': 'dátum (óra, perc is)',
        'Meddig tud jönni': 'dátum (óra, perc is)',
    }];

    const refresh = useRefresh();

    return <Dialog open={props.isOpen} onClose={handleClose} fullWidth
                   maxWidth="sm">
        <DialogTitle><Stack direction={"row"} alignItems={"center"} justifyContent={"space-between"}>
            <Box>Munkára jelentkezések feltöltése</Box>
            <Button variant={'contained'}
                    startIcon={<FileDownloadOutlinedIcon/>}
                    sx={{width: '200px', height: '35px'}}
                    onClick={async () => {
                        exportExcel({
                            excelData: excelValues,
                            fileName: `jelentkezesek-minta`
                        }).then(r => {
                        });
                    }}>
                Minta letöltése
            </Button>
        </Stack></DialogTitle>
        <DialogContent>
            <SimpleForm toolbar={<></>}>
                <FileInput source="excelFile"
                           label="Fájl (kötelező)"
                           accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
                           isRequired>
                    <FileField source="src" title="title"/>
                </FileInput>
                <Box sx={{height: '10px'}}/>
                <FormDataConsumer>
                    {({formData, ...rest}) =>
                        <Button variant={'contained'}
                                startIcon={<AddCircleOutlineOutlinedIcon/>}
                                sx={{width: '100%', marginTop: '-10px'}}
                                onClick={async () => {
                                    loadExcel(formData.excelFile.rawFile)
                                        .then(async data => {
                                            const formattedData = data.map((d: any) => {
                                                let startAt = d['Mikortól tud jönni'] ? dateTimeFormatter(getJsDateFromExcel(d['Mikortól tud jönni'])).substring(0, 16) : null;
                                                if (startAt !== null) {
                                                    const firstPart = startAt.substring(0, 11);
                                                    const hours = parseInt(startAt.substring(11, 13));
                                                    const minutes = startAt.substring(13, 16);
                                                    if (hours >= 2) {
                                                        const newHour = hours - 2;
                                                        const newHourString = (newHour > 9) ? newHour.toString() : `0${newHour.toString()}`;
                                                        startAt = firstPart + newHourString + minutes;
                                                    }
                                                }
                                                let finishAt = d['Meddig tud jönni'] ? dateTimeFormatter(getJsDateFromExcel(d['Meddig tud jönni'])).substring(0, 16) : null;
                                                if (finishAt !== null) {
                                                    const firstPart = finishAt.substring(0, 11);
                                                    const hours = parseInt(finishAt.substring(11, 13));
                                                    const minutes = finishAt.substring(13, 16);
                                                    if (hours >= 2) {
                                                        const newHour = hours - 2;
                                                        const newHourString = (newHour > 9) ? newHour.toString() : `0${newHour.toString()}`;
                                                        finishAt = firstPart + newHourString + minutes;
                                                    }
                                                }

                                                const hasSalary = d['Bér'] !== '' && (d['Bérezés típusa'] === 'Fix' || d['Bérezés típusa'] === 'Órabér');
                                                return {
                                                    employeeEmail: d['Munkavállaló e-mail (kötelező)'],
                                                    jobId: d['Munka ID (kötelező)'] ?? null,
                                                    salary: hasSalary ? {
                                                        salary: parseInt(d['Bér']) ?? null,
                                                        type: getSalaryType(d['Bérezés típusa']),
                                                    } : null,
                                                    employmentType: getEmploymentType(d['Bejelentés státusza']),
                                                    canStartAt: startAt,
                                                    canFinishAt: finishAt,
                                                    status: 'Active',
                                                }
                                            });
                                             await httpClient(`${apiUrl}/job-application/bulk`, {
                                                 method: 'POST',
                                                 body: JSON.stringify(formattedData),
                                             }).then(r => {
                                                 if (r.status === 200) {
                                                     notify(`Munkára jelentkezések felvéve`, {type: 'success'});
                                                     props.setIsOpen(false);
                                                     refresh();
                                                 } else {
                                                     notify(`Hiba történt a munkára jelentkezések létrehozásakor`, {type: 'error'});
                                                 }
                                             })
                                        })
                                        .catch();
                                }}>
                            Feltöltés
                        </Button>
                    }
                </FormDataConsumer>
            </SimpleForm>
        </DialogContent>
    </Dialog>;
}