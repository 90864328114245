import * as React from "react";
import {useEffect, useState} from "react";
import {Box, Grid, Stack, Typography} from "@mui/material";
import {ScheduleGrid} from "./schedule-grid";
import dataProvider, {apiUrl, httpClient} from "../../dataProvider";
import {addDays, getDateString, ScheduleFilters, setToMonday} from "./schedule-filters";
import {useFormContext} from "react-hook-form";
import {ScheduleDayDialog} from "./schedule-day/schedule-day-dialog";

export interface ScheduleProp {
    jobRecord: any
    establishmentId: number
}

export const ScheduleList = (props: ScheduleProp) => {
    const [scheduleTimeTableItems, setScheduleTimeTableItems]: any = useState();
    const [fetchedTimeTableItems, setFetchedTimeTableItems] = useState(false);
    const [timeTableRefresh, setTimeTableRefresh] = useState(false);
    const form = useFormContext();
    const formValues = form.getValues();
    useEffect(() => {
        if (typeof props.establishmentId !== 'undefined') {
            let filterDateStart = setToMonday(new Date());
            let filterDateEnd = addDays(filterDateStart, 7);

            const filterStartString = getDateString(filterDateStart);
            const filterEndString = getDateString(filterDateEnd);

            let dateQueryString = '';
            if (typeof formValues.from !== "undefined" && formValues.from !== '') {
                dateQueryString += `&from=${formValues.from.substring(0, 10)}`
            } else {
                dateQueryString += `&from=${filterStartString}`
            }
            if (typeof formValues.to !== "undefined" && formValues.to !== '') {
                dateQueryString += `&to=${formValues.to.substring(0, 10)}`
            } else {
                dateQueryString += `&to=${filterEndString}`
            }
            if(formValues.jobId) {
                dateQueryString += `&jobId=${formValues.jobId}`;
            }
            httpClient(`${apiUrl}/schedule-time-table?establishmentId=${props.establishmentId}&page=1&size=100${dateQueryString}`, {
                method: 'GET',
            }).then(({json}) => {
                setScheduleTimeTableItems(json.items);
                setFetchedTimeTableItems(true);
            })
        }
    }, [timeTableRefresh])

    const [fetchedApps, setFetchedApps] = useState(false);
    const [applications, setApplications]: any = useState([]);
    useEffect(() => {
        if (typeof props.establishmentId !== 'undefined' && !fetchedApps) {
            httpClient(`${apiUrl}/job-application?establishmentId=${props.establishmentId}&status=Active&page=1&size=100`, {
                method: 'GET',
            }).then(({json}) => {
                setApplications(json.items);
                setFetchedApps(true)
            })
        }
    }, [timeTableRefresh]);

    const empty: any[] = [];
    const [applicationWithName, setApplicationsWithName] = useState(empty);
    useEffect(() => {
        if (fetchedApps) {
            const ids = applications.map((a: any) => a.employeeId);
            dataProvider.getMany('employee', {
                ids: ids
            }).then(({data}) => {
                let newApps: any[] = [];
                applications.forEach((a: any) => {
                    const found = data.find((d: any) => d.id === a.employeeId);
                    newApps.push({
                        id: a.id,
                        name: found.name ?? ''
                    })
                })
                setApplicationsWithName(newApps);
            })
        }
    }, [fetchedApps]);

    const [isOpen, setIsOpen] = useState(false);

    return <>
        {fetchedTimeTableItems && typeof scheduleTimeTableItems !== 'undefined' &&
            <Box sx={{width: '100%'}}>
                {scheduleTimeTableItems.length === 0 ?
                    <Box>
                        <Stack direction={'row'} justifyContent={'space-between'}>
                            <Box/>
                        <Stack direction={'row'}>
                            <ScheduleFilters timeTableRefresh={timeTableRefresh}
                                             setTimeTableRefresh={setTimeTableRefresh}
                                             record={props.jobRecord}
                                             isOpen={isOpen}
                                             setIsOpen={setIsOpen}
                             establishmentId={props.establishmentId}/>
                        </Stack>
                        <ScheduleDayDialog isOpen={isOpen}
                                           setIsOpen={setIsOpen}
                                           establishmentId={props.establishmentId}
                                           timeTableRefresh={timeTableRefresh}
                                           setTimeTableRefresh={setTimeTableRefresh}/>
                        </Stack>
                        <Typography fontSize={16}>Nincsenek beosztások</Typography>
                    </Box> :
                    <>
                        <Grid container spacing={0} sx={{marginBottom: '10px'}}>
                            <Grid item xs={12}>
                                <Stack direction={'row'} justifyContent={'flex-end'}>
                                    <ScheduleDayDialog isOpen={isOpen}
                                                       setIsOpen={setIsOpen}
                                                       establishmentId={props.establishmentId}
                                                       timeTableRefresh={timeTableRefresh}
                                                       setTimeTableRefresh={setTimeTableRefresh}/>
                                    <ScheduleFilters timeTableRefresh={timeTableRefresh}
                                                     setTimeTableRefresh={setTimeTableRefresh}
                                                     record={props.jobRecord}
                                                     isOpen={isOpen}
                                                     setIsOpen={setIsOpen}
                                                     establishmentId={props.establishmentId}
                                    />
                                </Stack>
                            </Grid>
                        </Grid>
                        <ScheduleGrid scheduleItems={scheduleTimeTableItems}
                                      applications={applicationWithName}
                                      timeTableRefresh={timeTableRefresh}
                                      setTimeTableRefresh={setTimeTableRefresh}
                                      establishmentId={props.establishmentId}
                        />
                    </>}
            </Box>
        }
    </>
};