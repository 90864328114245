import * as React from "react";
import {CreateButton, Datagrid, DateField, FunctionField, List, TextField} from 'react-admin';
import {Stack} from "@mui/material";

export const OfficeList = () => (
    <List title={'Irodák'} actions={
        <Stack direction="row"
               justifyContent="flex-end"
               alignItems={'center'}
               sx={{width: '100%', paddingBottom: '5px'}}>
            <CreateButton/>
        </Stack>}>
        <Datagrid rowClick={'show'} bulkActionButtons={false}>
            <TextField source="id" label={"ID"}/>
            <TextField source="name" label={"Név"}/>
            <TextField source="phone" label={"Telefonszám"}/>
            <TextField source="email" label={"E-mail"}/>
            <FunctionField
                label="Cím"
                render={(record: any) => `${record.address.country}, ${record.address.zip}, ${record.address.city}, ${record.address.address}`}
            />
        </Datagrid>
    </List>
);