import * as React from "react";
import {AutocompleteInput, FormDataConsumer, NumberInput, ReferenceInput, TextInput, useNotify} from 'react-admin';
import {Box, Button, Divider, Grid, Stack, Typography} from "@mui/material";
import AddCircleOutlineOutlinedIcon from "@mui/icons-material/AddCircleOutlineOutlined";
import {apiUrl, httpClient} from "../../dataProvider";
import EditOutlinedIcon from "@mui/icons-material/EditOutlined";
import DeleteOutlineOutlinedIcon from "@mui/icons-material/DeleteOutlineOutlined";
import RemoveIcon from '@mui/icons-material/Remove';
import CheckCircleOutlinedIcon from '@mui/icons-material/CheckCircleOutlined';
import WarningAmberOutlinedIcon from '@mui/icons-material/WarningAmberOutlined';
import {dateTimeFormatter} from "../../date-formatter/date-formatter";

interface SubtractionsProps {
    fetchSubs: boolean
    setFetchSubs: any
    substractions: any[] | undefined
    required: boolean
    feedBackId: number
}

export const JobApplicationSubtractionsTab = (props: SubtractionsProps) => {
    const notify = useNotify();
    const filterToQuery = (searchText:string) => ({ query: `%${searchText}%` });

    return <>
        <Box sx={{width: '100%'}}>
            <Typography fontWeight={600} sx={{marginBottom: '8px'}}>Új levonás</Typography>
            <Grid container spacing={1}>
                <Grid item xs={3}>
                    <ReferenceInput source="newSubtractionTypeId"
                                    reference="job-subtraction-type"
                                    name={"newSubtractionTypeId"}>
                        <AutocompleteInput source="name" label={"Levonás típus"} size={"small"} fullWidth
                                           isRequired={props.required}
                                           filterToQuery={filterToQuery}/>
                    </ReferenceInput>
                </Grid>
                <Grid item xs={2}>
                    <NumberInput source="newSubtractionAmount" name={"newSubtractionAmount"} fullWidth
                                 label={"Érték (Ft)"} required={props.required}/>
                </Grid>
                <Grid item xs={7}>
                    <TextInput source="newSubtractionNote" label={"Megjegyzés"}
                               name={"newSubtractionNote"} fullWidth required={props.required}
                               multiline/>
                </Grid>
                <Grid item xs={10}/>
                <Grid item xs={2} sx={{display: 'flex', alignItems: 'flex-start', justifyContent: 'center'}}>
                    <FormDataConsumer>
                        {({formData, ...rest}) =>
                            <Button variant={'contained'}
                                    startIcon={<AddCircleOutlineOutlinedIcon/>}
                                    sx={{width: '100%'}}
                                    onClick={async () => {
                                        await httpClient(`${apiUrl}/job-subtraction`, {
                                            method: 'POST',
                                            body: JSON.stringify({
                                                jobFeedbackId: props.feedBackId,
                                                typeId: formData.newSubtractionTypeId,
                                                amount: formData.newSubtractionAmount,
                                                note: formData.newSubtractionNote
                                            }),
                                        }).then(r => {
                                            if (r.status === 201) {
                                                props.setFetchSubs(false);
                                                notify(`Levonás létrehozva`, {type: 'success'});
                                            } else {
                                                notify(`Hiba történt a levonás létrehozásakor`, {type: 'error'});
                                            }
                                        })
                                    }}>
                                Hozzáadás
                            </Button>}
                    </FormDataConsumer>
                </Grid>
            </Grid>
            <Divider sx={{width: '100%', borderBottomWidth: '2px', marginBottom: '10px', marginTop: '20px'}}/>
        </Box>
        {props.fetchSubs && props.substractions &&
            props.substractions.length > 0 && props.substractions.map((s, index) => {
                const subtractedAtDate = new Date(s.createdAt);
                const isSubtracted = s.subtractedAt !== null;
                return <Box sx={{width: '100%'}}>
                    <Typography fontWeight={600} sx={{marginBottom: '12px'}}>{index + 1}. levonás</Typography>
                    <Grid container spacing={1}>
                        <Grid item xs={3}>
                            <ReferenceInput source={`${s.id}typeId`}
                                            reference="job-subtraction-type"
                                            name={`${s.id}typeId`}>
                                <AutocompleteInput source="name" label={"Levonás típus"} size={"small"} fullWidth
                                                   isRequired defaultValue={s.typeId}
                                                   filterToQuery={filterToQuery}/>
                            </ReferenceInput>
                        </Grid>
                        <Grid item xs={2}>
                            <NumberInput source={`${s.id}amount`} defaultValue={s.amount} name={`${s.id}amount`}
                                         fullWidth
                                         label={"Érték (Ft)"} required/>
                        </Grid>
                        <Grid item xs={7}>
                            <TextInput source={`${s.id}substractionNote`} label={"Megjegyzés"}
                                       name={`${s.id}substractionNote`}
                                       fullWidth
                                       multiline defaultValue={s.note} required/>
                        </Grid>
                        <Grid item xs={3}>
                            {
                                isSubtracted ? <Stack direction={'row'} alignItems={'center'}>
                                    <CheckCircleOutlinedIcon color={'success'}/>
                                    <Typography fontWeight={700}
                                                sx={{marginLeft: '10px'}}>Levonva: {subtractedAtDate.toLocaleDateString('hu')}</Typography>
                                </Stack> : <Stack direction={'row'} alignItems={'center'}>
                                    <WarningAmberOutlinedIcon color={'warning'}/>
                                    <Typography fontWeight={700}
                                                sx={{marginLeft: '10px'}}>Még nincs levonva</Typography>
                                </Stack>
                            }
                        </Grid>
                        <Grid item xs={isSubtracted ? 5 : 3}/>
                        <Grid item xs={2}
                              sx={{display: 'flex', alignItems: 'flex-start', justifyContent: 'center'}}>
                            <FormDataConsumer>
                                {({formData, ...rest}) =>
                                    <Button variant={'contained'} color={"warning"}
                                            startIcon={<EditOutlinedIcon/>}
                                            sx={{color: 'white', width: '100%'}}
                                            onClick={async () => {
                                                await httpClient(`${apiUrl}/job-subtraction/${s.id}`, {
                                                    method: 'PUT',
                                                    body: JSON.stringify({
                                                        jobFeedbackId: props.feedBackId,
                                                        typeId: formData[`${s.id}typeId`],
                                                        amount: formData[`${s.id}amount`],
                                                        note: formData[`${s.id}substractionNote`]
                                                    }),
                                                }).then(r => {
                                                    if (r.status === 200) {
                                                        props.setFetchSubs(false);
                                                        notify(`Levonás módosítva`, {type: 'success'});
                                                    } else {
                                                        notify(`Hiba történt a levonás módosításakor`, {type: 'error'});
                                                    }
                                                })
                                            }}>
                                        Módosítás
                                    </Button>}
                            </FormDataConsumer>
                        </Grid>
                        {!isSubtracted && <Grid item xs={2}
                                                sx={{
                                                    display: 'flex',
                                                    alignItems: 'flex-start',
                                                    justifyContent: 'center'
                                                }}>
                            <FormDataConsumer>
                                {({formData, ...rest}) =>
                                    <Button variant={'contained'} color={"primary"}
                                            startIcon={<RemoveIcon/>}
                                            sx={{color: 'white', width: '100%'}}
                                            onClick={async () => {
                                                const date = new Date();
                                                const dateString = dateTimeFormatter(date);
                                                await httpClient(`${apiUrl}/job-subtraction/${s.id}`, {
                                                    method: 'PUT',
                                                    body: JSON.stringify({
                                                        jobFeedbackId: props.feedBackId,
                                                        typeId: formData[`${s.id}typeId`],
                                                        amount: formData[`${s.id}amount`],
                                                        note: formData[`${s.id}substractionNote`],
                                                        subtractedAt: `${dateString}:00Z`
                                                    }),
                                                }).then(r => {
                                                    if (r.status === 200) {
                                                        props.setFetchSubs(false);
                                                        notify(`Levonás módosítva`, {type: 'success'});
                                                    } else {
                                                        notify(`Hiba történt a levonás módosításakor`, {type: 'error'});
                                                    }
                                                })
                                            }}>
                                        Levonás
                                    </Button>}
                            </FormDataConsumer>
                        </Grid>}
                        <Grid item xs={2}
                              sx={{display: 'flex', alignItems: 'flex-start', justifyContent: 'center'}}>
                            <FormDataConsumer>
                                {({formData, ...rest}) =>
                                    <Button variant={'contained'} color={"error"}
                                            startIcon={<DeleteOutlineOutlinedIcon/>}
                                            sx={{width: '100%'}}
                                            onClick={async () => {
                                                await httpClient(`${apiUrl}/job-subtraction/${s.id}`, {
                                                    method: 'DELETE',
                                                }).then(r => {
                                                    if (r.status === 204) {
                                                        props.setFetchSubs(false);
                                                        notify(`Levonás törölve`, {type: 'success'});
                                                    } else {
                                                        notify(`Hiba történt a levonás törlésekor`, {type: 'error'});
                                                    }
                                                })
                                            }}>
                                        Törlés
                                    </Button>}
                            </FormDataConsumer>
                        </Grid>
                    </Grid>
                    <Divider sx={{width: '100%', borderBottomWidth: '2px', marginBottom: '10px', marginTop: '20px'}}/>
                </Box>
            })
        }
    </>
}
