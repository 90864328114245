import * as React from 'react';
import {
    ChipField,
    Datagrid,
    DateField,
    FunctionField,
    ImageField,
    NumberField,
    ReferenceArrayField,
    ReferenceField,
    Tab,
    SimpleShowLayout,
    SingleFieldList,
    TextField,
    TabbedShowLayout,
    useShowContext, useRecordContext, Show, BooleanField
} from 'react-admin';
import {Box, Chip, Divider, Grid, Stack, Typography} from "@mui/material";
import {getEmployeeTypeName, getEmploymentTypeName} from "../enum-translates";
import {useEffect, useState} from "react";
import dataProvider, {apiUrl, httpClient} from "../dataProvider";

const JobShowLayout = () => {
    const {
        error,
        isLoading,
        record,
    } = useShowContext();
    const [applications, setApplications]: any = useState();
    const [fetchedApplications, setFetchedApplications] = useState(false);
    useEffect(() => {
        if (typeof applications === 'undefined' && typeof record !== 'undefined') {
            httpClient(`${apiUrl}/job-application?jobId=${record.id}&page=1&size=100`, {
                method: 'GET',
            }).then(({json}) => {
                setApplications(json.items);
                setFetchedApplications(true);
            })
        }
    }, []);
    const [appliedEmployees, setAppliedEmployees]: any = useState();
    const [fetchedAppliedEmployees, setFetchedAppliedEmployees] = useState(false);
    useEffect(() => {
        if (typeof appliedEmployees === 'undefined' && typeof applications !== 'undefined') {
            const ids = applications.map((a: any) => a.employeeId);
            dataProvider.getMany('employee', {
                ids: ids
            }).then(({data}) => {
                setAppliedEmployees(data);
                setFetchedAppliedEmployees(true);
            })
        }
    }, [fetchedApplications]);
    if (isLoading) {
        return <div>Loading...</div>;
    }
    if (error) {
        return <div>Error!</div>;
    }
    return (
        <TabbedShowLayout>
            <Tab label="Általános információk (alkalmazás)">
                <Grid container spacing={0}>
                    <Grid item xs={12}>
                        <SimpleShowLayout>
                            <TextField source="id" label={"ID"}/>
                            {record.image !== null && <ImageField source={"image"} label={"Kép"}/>}
                        </SimpleShowLayout>
                    </Grid>
                    <Grid item xs={4}>
                        <SimpleShowLayout>
                            <TextField source="name" label={"Munka megnevezése"}/>
                            <BooleanField source={"isActive"} label={"Aktív"}/>
                            <TextField source="durationDescription" label={"Munkavégzés ideje"}/>
                        </SimpleShowLayout>
                    </Grid>
                    <Grid item xs={4}>
                        <SimpleShowLayout>
                            <ReferenceField source="employerId"
                                            reference="employer"
                                            link="show"
                                            label={"Projekt"}>
                                <TextField source="name"/>
                            </ReferenceField>
                            <TextField source="area" label={"Térség"}/>
                            <DateField source="startsAt" locales={['hu-HU']} label={"Kezdés"} showTime/>
                        </SimpleShowLayout>
                    </Grid>
                    <Grid item xs={4}>
                        <SimpleShowLayout>
                            <FunctionField
                                label="Típus"
                                render={(record: any) => `${getEmploymentTypeName(record.employmentType)}`}
                            />
                            <TextField source="expectedSalary" label={"Várható fizetés"}/>
                            <DateField source="endsAt" locales={['hu-HU']} label={"Befejezés"} showTime/>
                        </SimpleShowLayout>
                    </Grid>
                    <SimpleShowLayout>
                        <Box>
                            <Typography
                                sx={{
                                    fontSize: '0.75em',
                                    color: '#d7dbdf',
                                    marginBottom: '10px'
                                }}>Kategóriák</Typography>
                            <ReferenceArrayField label="" reference="category" source="categories">
                                <SingleFieldList>
                                    <ChipField source="name"/>
                                </SingleFieldList>
                            </ReferenceArrayField>
                        </Box>
                        <JobEmployeeTypes/>
                        <Box>
                            <Typography
                                sx={{fontSize: '0.75em', color: '#d7dbdf', marginBottom: '10px'}}>Elvárt
                                tudások</Typography>
                            <ReferenceArrayField label="" reference="skill" source="requiredSkills">
                                <SingleFieldList>
                                    <ChipField source="name"/>
                                </SingleFieldList>
                            </ReferenceArrayField>
                        </Box>
                        <TextField source="description" label={"Leírás"}/>
                        <TextField source="contactNote" label={"Kapcsolat megjegyzés"}/>
                    </SimpleShowLayout>
                </Grid>
            </Tab>
            <Tab label="Kapcsolattartók">
                <ReferenceArrayField label="" reference="employer-contact-person"
                                     source="contactPeople">
                    <Datagrid bulkActionButtons={false}>
                        <TextField source="name" label={"Név"}/>
                        <TextField source="email" label={"E-mail"}/>
                        <TextField source="phone" label={"Telefon"}/>
                    </Datagrid>
                </ReferenceArrayField>
            </Tab>
            <Tab label="Belső információk">
                <Grid container spacing={0}>
                    <Grid item xs={12}>
                        <SimpleShowLayout>
                            <ReferenceField source="administratorInCharge"
                                            reference="administrator"
                                            label={"Felelős"}>
                                <TextField source="name"/>
                            </ReferenceField>
                            <TextField source="internalNote" label={"Belső megjegyzés"}/>
                            <FunctionField
                                label="Pontos cím"
                                render={(record: any) => `${record.address.country}, ${record.address.zip}, ${record.address.city}, ${record.address.address}`}
                            />
                            <NumberField source="highlightOrder" label={"Kiemelés mértéke"}/>
                        </SimpleShowLayout>
                    </Grid>
                    <Grid item xs={4}>
                        <SimpleShowLayout>
                            <NumberField source="numberOfEmployeesNeeded" label={"Szükséges munkavállalók"}/>
                        </SimpleShowLayout>
                    </Grid>
                    <Grid item xs={4}>
                        <SimpleShowLayout>
                            <FunctionField
                                label="Jelentkezett munkavállalók"
                                render={(record: any) => {
                                    return <Box>
                                        {record.numberOfApplications ?? 0}
                                    </Box>
                                }}
                            />
                        </SimpleShowLayout>
                    </Grid>
                    <Grid item xs={4}>
                        <SimpleShowLayout>
                            <FunctionField
                                label="Megerősített munkavállalók"
                                render={(record: any) => {
                                    return <Box sx={{
                                        fontWeight: 700,
                                        color: record.numberOfAcceptedApplications ? record.numberOfAcceptedApplications >= record.numberOfEmployeesNeeded ? '#66bb6a' : record.numberOfAcceptedApplications === 0 ? '#f44336' : '#ffa726' : '#f44336'
                                    }}>
                                        {record.numberOfAcceptedApplications ?? 0}
                                    </Box>
                                }}
                            />
                        </SimpleShowLayout>
                    </Grid>
                </Grid>
            </Tab>
            <Tab label="Jelentkezők">
                {fetchedAppliedEmployees && typeof appliedEmployees !== 'undefined' && appliedEmployees.length === 0 ?
                    <Typography fontSize={16}>Nincsenek jelentkezők</Typography> :
                    <>
                        <Grid container spacing={1}>
                            <Grid item xs={3}>
                                <Typography fontSize={14} fontWeight={600}>ID</Typography>
                            </Grid>
                            <Grid item xs={3}>
                                <Typography fontSize={14} fontWeight={600}>Név</Typography>
                            </Grid>
                            <Grid item xs={3}>
                                <Typography fontSize={14} fontWeight={600}>E-mail</Typography>
                            </Grid>
                            <Grid item xs={3}>
                                <Typography fontSize={14} fontWeight={600}>Telefon</Typography>
                            </Grid>
                            <Grid item xs={12}>
                                <Divider sx={{width: '100%', borderBottomWidth: '3px'}}/>
                            </Grid>
                            {/*//@ts-ignore*/}
                            {typeof appliedEmployees !== 'undefined' && appliedEmployees.map((s, index) => {
                                return <Grid item xs={12} container spacing={1}>
                                    <Grid item xs={3}>
                                        <FunctionField
                                            render={() => `${s.id}`}
                                        />
                                    </Grid>
                                    <Grid item xs={3}>
                                        <FunctionField
                                            render={() => `${s.name}`}
                                        />
                                    </Grid>
                                    <Grid item xs={3}>
                                        <FunctionField
                                            render={() => `${s.email}`}
                                        />
                                    </Grid>
                                    <Grid item xs={3}>
                                        <FunctionField
                                            render={() => `${s.phone}`}
                                        />
                                    </Grid>
                                    <Grid item xs={12}>
                                        <Divider sx={{width: '100%'}}/>
                                    </Grid>
                                </Grid>
                            })}
                        </Grid>
                    </>
                }
            </Tab>
        </TabbedShowLayout>
    );
};

export const JobShow = () => {
    return <Show title={'Munka'}>
        <JobShowLayout/>
    </Show>;
};

const JobEmployeeTypes = () => {
    const record = useRecordContext();
    if (!record) return null;
    return <Box>
        <Typography sx={{fontSize: '0.75em', color: '#d7dbdf', marginBottom: '10px'}}>Munkavállaló foglalkoztatási formák</Typography>
        <Stack direction={"row"} spacing={1}>
            {record.employeeTypes.map((r: any) => {
                    return <Chip label={`${getEmployeeTypeName(r)}`} variant={"filled"}
                                 sx={{backgroundColor: '#0D8D8A', color: '#fff'}}/>
                }
            )}
        </Stack>
    </Box>;
};
