import * as React from "react";
import {useEffect, useState} from "react";
import {
    AutocompleteInput,
    DateTimeInput, DeleteButton,
    Edit,
    FormDataConsumer,
    FormTab,
    ReferenceInput, SaveButton,
    TabbedForm,
    TextInput, Toolbar,
    useEditContext,
    useNotify,
    useRedirect
} from 'react-admin';
import {Box, Button, Divider, Grid, Typography} from "@mui/material";
import AddCircleOutlineOutlinedIcon from "@mui/icons-material/AddCircleOutlineOutlined";
import dataProvider, {apiUrl, httpClient} from "../dataProvider";
import EditOutlinedIcon from "@mui/icons-material/EditOutlined";
import DeleteOutlineOutlinedIcon from "@mui/icons-material/DeleteOutlineOutlined";

const CustomToolbar = (props: any) => (
    <Toolbar
        sx={{display: 'flex', justifyContent: 'space-between'}}
    >
        <SaveButton/>
        {!props.noDelete && <DeleteButton/>}
    </Toolbar>
);

const filterToQuery = (searchText: string) => ({query: `%${searchText}%`});

const EmployerForm = () => {
    const {record} = useEditContext();
    const [currentTab, setCurrentTab] = useState(`${record.id}`);
    const notify = useNotify();
    const redirect = useRedirect();

    useEffect(() => {
        if (typeof window !== 'undefined') {
            const tab = window.location.href.match('([^\\/]+$)');
            const link = window.location.href.split('employer');
            const counter = link[1].split('/');
            let newValue = tab !== null ? tab[0] : '';
            if (counter.length < 3) {
                newValue += 'tab';
            }
            setCurrentTab(newValue)
        }
    }, [window.location.href]);

    const [establishments, setEstablishments] = useState();
    const [fetchEst, setFetchEst] = useState(false);
    useEffect(() => {
        if (typeof establishments === 'undefined' || !fetchEst) {
            httpClient(`${apiUrl}/establishment?employerId=${record.id}&page=1&size=100`, {
                method: 'GET',
            }).then(({json}) => {
                setEstablishments(json.items)
                setFetchEst(true)
            })
        }
    }, [fetchEst]);

    const firstTabRequired = currentTab === `${record.id}tab`;
    const secondTabRequired = currentTab === `1`;

    return <TabbedForm toolbar={secondTabRequired ? <></> : <CustomToolbar/>} onSubmit={async (values) => {
        if (firstTabRequired) {
            await dataProvider.update('employer', {
                id: record.id,
                data: {
                    id: record.id,
                    name: values.name,
                    taxNumber: values.taxNumber,
                    importedBy: values.importedBy,
                    importedAt: values.importedAt,
                    headquarters: {
                        country: values.headquarters.country,
                        zip: values.headquarters.zip,
                        city: values.headquarters.city,
                        address: values.headquarters.address,
                    },
                    note: values.note === '' ? null : values.note,
                }
            }).then(r => {
                redirect('/employer')
            })
        }
    }}
    >
        <FormTab label={'Telephelyek'}>
            <Box sx={{width: '100%'}}>
                <Typography fontWeight={600} sx={{marginBottom: '8px'}}>Új telephely</Typography>
                <Grid container spacing={1}>
                    <Grid item xs={12}>
                        <TextInput source="newName" label={"Név (kötelező)"}
                                   name={"newName"} fullWidth required={secondTabRequired}/>
                    </Grid>
                    <Grid item xs={2}>
                        <TextInput source="newAddress.country" label={"Ország (kötelező)"}
                                   name={"newAddress.country"} fullWidth defaultValue={"Magyarország"}
                                   required={secondTabRequired}/>
                    </Grid>
                    <Grid item xs={2}>
                        <TextInput source="newAddress.zip" label={"Ir.szám (kötelező)"} name={"newAddress.zip"}
                                   fullWidth required={secondTabRequired}/>
                    </Grid>
                    <Grid item xs={4}>
                        <TextInput source="newAddress.city" label={"Város (kötelező)"} name={"newAddress.city"}
                                   fullWidth required={secondTabRequired}/>
                    </Grid>
                    <Grid item xs={4}>
                        <TextInput source="newAddress.address" label={"Cím (kötelező)"}
                                   name={"newAddress.address"} fullWidth required={secondTabRequired}/>
                    </Grid>
                    <Grid item xs={12}>
                        <TextInput source="establishmentNote" label={"Megjegyzés"}
                                   name={"establishmentNote"} fullWidth/>
                    </Grid>
                    <Grid item xs={10}/>
                    <Grid item xs={2} sx={{display: 'flex', alignItems: 'flex-start', justifyContent: 'center'}}>
                        <FormDataConsumer>
                            {({formData, ...rest}) =>
                                <Button variant={'contained'}
                                        startIcon={<AddCircleOutlineOutlinedIcon/>}
                                        sx={{width: '100%'}}
                                        onClick={async () => {
                                            await httpClient(`${apiUrl}/establishment`, {
                                                method: 'POST',
                                                body: JSON.stringify({
                                                    //@ts-ignore
                                                    name: formData.newName,
                                                    address: {
                                                        country: formData.newAddress.country,
                                                        city: formData.newAddress.city,
                                                        zip: formData.newAddress.zip,
                                                        address: formData.newAddress.address,
                                                    },
                                                    employerId: record.id,
                                                    note: formData.establishmentNote
                                                }),
                                            }).then(r => {
                                                if (r.status === 201) {
                                                    setFetchEst(false);
                                                    notify(`Telephely létrehozva`, {type: 'success'});
                                                } else {
                                                    notify(`Hiba történt a telephely létrehozásakor`, {type: 'error'});
                                                }
                                            })
                                        }}>
                                    Hozzáadás
                                </Button>}
                        </FormDataConsumer>
                    </Grid>
                </Grid>
                <Divider sx={{width: '100%', borderBottomWidth: '2px', marginBottom: '10px', marginTop: '30px'}}/>
            </Box>
            {fetchEst && establishments &&
                //@ts-ignore
                establishments.length > 0 && establishments.map((s, index) => {
                    return <Box sx={{width: '100%'}}>
                        <Typography fontWeight={600} sx={{marginBottom: '8px'}}>{index + 1}. telephely</Typography>
                        <Grid container spacing={1}>
                            <Grid item xs={12}>
                                <TextInput source={`${s.id}name`} label={"Név (kötelező)"}
                                           name={`${s.id}name`} fullWidth defaultValue={s.name}
                                           required={secondTabRequired}/>
                            </Grid>
                            <Grid item xs={2}>
                                <TextInput source={`${s.id}address.country`} label={"Ország (kötelező)"}
                                           name={`${s.id}address.country`} fullWidth defaultValue={s.address.country}
                                           required={secondTabRequired}/>
                            </Grid>
                            <Grid item xs={2}>
                                <TextInput source={`${s.id}address.zip`} label={"Ir.szám (kötelező)"}
                                           name={`${s.id}address.zip`}
                                           fullWidth required={secondTabRequired} defaultValue={s.address.zip}/>
                            </Grid>
                            <Grid item xs={4}>
                                <TextInput source={`${s.id}address.city`} label={"Város (kötelező)"}
                                           name={`${s.id}address.city`}
                                           fullWidth required={secondTabRequired} defaultValue={s.address.city}/>
                            </Grid>
                            <Grid item xs={4}>
                                <TextInput source={`${s.id}address.address`} label={"Cím (kötelező)"}
                                           name={`${s.id}address.address`} fullWidth required={secondTabRequired}
                                           defaultValue={s.address.address}
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <TextInput source={`${s.id}note`} label={"Megjegyzés"}
                                           name={`${s.id}note`} fullWidth required={secondTabRequired}
                                           defaultValue={s.note}
                                />
                            </Grid>
                            <Grid item xs={8}/>
                            <Grid item xs={2}
                                  sx={{display: 'flex', alignItems: 'flex-start', justifyContent: 'center'}}>
                                <FormDataConsumer>
                                    {({formData, ...rest}) =>
                                        <Button variant={'contained'} color={"warning"}
                                                startIcon={<EditOutlinedIcon/>}
                                                sx={{color: 'white', width: '100%'}}
                                                onClick={async () => {
                                                    await httpClient(`${apiUrl}/establishment/${s.id}`, {
                                                        method: 'PUT',
                                                        body: JSON.stringify({
                                                            name: formData[`${s.id}name`],
                                                            address: {
                                                                country: formData[`${s.id}address`].country,
                                                                city: formData[`${s.id}address`].city,
                                                                zip: formData[`${s.id}address`].zip,
                                                                address: formData[`${s.id}address`].address,
                                                            },
                                                            employerId: record.id,
                                                            note: formData[`${s.id}note`],
                                                        }),
                                                    }).then(r => {
                                                        if (r.status === 200) {
                                                            setFetchEst(false);
                                                            notify(`Telephely módosítva`, {type: 'success'});
                                                        } else {
                                                            notify(`Hiba történt a telephely módosításakor`, {type: 'error'});
                                                        }
                                                    })
                                                }}>
                                            Módosítás
                                        </Button>}
                                </FormDataConsumer>
                            </Grid>
                            <Grid item xs={2}
                                  sx={{display: 'flex', alignItems: 'flex-start', justifyContent: 'center'}}>
                                <FormDataConsumer>
                                    {({formData, ...rest}) =>
                                        <Button variant={'contained'} color={"error"}
                                                startIcon={<DeleteOutlineOutlinedIcon/>}
                                                sx={{width: '100%'}}
                                                onClick={async () => {
                                                    await httpClient(`${apiUrl}/establishment/${s.id}`, {
                                                        method: 'DELETE',
                                                    }).then(r => {
                                                        if (r.status === 204) {
                                                            setFetchEst(false);
                                                            notify(`Telephely törölve`, {type: 'success'});
                                                        } else {
                                                            notify(`Hiba történt a telephely törlésekor`, {type: 'error'});
                                                        }
                                                    })
                                                }}>
                                            Törlés
                                        </Button>}
                                </FormDataConsumer>
                            </Grid>
                        </Grid>
                        <Divider
                            sx={{width: '100%', borderBottomWidth: '2px', marginBottom: '10px', marginTop: '30px'}}/>
                    </Box>
                })
            }
        </FormTab>
        <FormTab label="Információk">
            <Grid container item xs={12} spacing={2}>
                <Grid item xs={6}>
                    <TextInput source="name" label={"Név (kötelező)"} name={"name"} fullWidth required/>
                </Grid>
                <Grid item xs={6}>
                    <TextInput source="taxNumber" label={"Adószám (kötelező)"} name={"taxNumber"} fullWidth required/>
                </Grid>
            </Grid>
            <Grid container item xs={12} spacing={2}>
                <Grid item xs={6}>
                    <ReferenceInput source="importedBy" reference="administrator" name={"importedBy"}>
                        <AutocompleteInput label={"Behozta (kötelező)"} source={"name"} size={"small"} isRequired
                                           filterToQuery={filterToQuery}/>
                    </ReferenceInput>
                </Grid>
                <Grid item xs={6}>
                    <DateTimeInput source="importedAt" label={"Behozás ideje (kötelező)"} name={"importedAt"} fullWidth
                                   required
                                   inputProps={{
                                       max: '2099-12-31T23:59'
                                   }}/>
                </Grid>
            </Grid>
            <Grid container spacing={1}>
                <Grid item xs={12}>
                    <Typography fontSize={'14px'}>Székhely</Typography>
                </Grid>
                <Grid item xs={2}>
                    <TextInput source="headquarters.country" label={"Ország (kötelező)"}
                               name={"headquarters.country"} fullWidth required defaultValue={"Magyarország"}/>
                </Grid>
                <Grid item xs={2}>
                    <TextInput source="headquarters.zip" label={"Ir.szám (kötelező)"} name={"headquarters.zip"}
                               fullWidth required/>
                </Grid>
                <Grid item xs={4}>
                    <TextInput source="headquarters.city" label={"Város (kötelező)"} name={"headquarters.city"}
                               fullWidth required/>
                </Grid>
                <Grid item xs={4}>
                    <TextInput source="headquarters.address" label={"Cím (kötelező)"}
                               name={"headquarters.address"} fullWidth required/>
                </Grid>
            </Grid>
            <TextInput source="note" label={"Megjegyzés"} name={"note"} fullWidth/>
        </FormTab>
    </TabbedForm>
}

export const EmployerEdit = () => (
    <Edit title={'Projekt szerkesztése'}>
        <EmployerForm/>
    </Edit>
);
