import * as React from "react";
import {useEffect, useState} from "react";
import {
    Datagrid,
    ExportButton,
    FilterButton,
    FilterForm,
    FunctionField,
    List,
    RaRecord,
    ReferenceField,
    SelectInput,
    TextField,
    TextInput,
    TopToolbar,
    useGetList, useGetOne,
    useListContext,
    usePermissions
} from 'react-admin';
import {Box, Button, Divider, Stack} from "@mui/material";
import {apiUrl, httpClient} from "../dataProvider";
import {canAccess} from "../layout/custom-menu";
import {exportExcel} from "../export-excel";
import {BulkJobApplicationDialog} from "./bulk-job-application-dialog";
import FileUploadOutlinedIcon from "@mui/icons-material/FileUploadOutlined";
import {getJobApplicationStatus} from "../enum-translates";
import {EmployerFieldWrapper} from "./employer-field";
import {EstablishmentFieldWrapper} from "./establishment-field";

const exporter = (values: any) => {
    const currentDate = new Date();
    const excelValues = values.map((v: any, index: number) => {
        return {
            'ID': v.id,
            'Munka (ID)': v.jobId,
            'Munkaválalló (ID)': v.employeeId,
            'Státusz': v.status,
            'Fizetés': v.salary !== null ? v.salary.salary : '',
            'Fizetés típus': v.salary !== null ? v.salary.type : '',
            'Tud jönni': v.willBeAbleToCome,
            'Mikortól tud jönni': v.canStartAt,
            'Meddig tud jönni': v.canFinishAt,
            'Típus': v.employmentType,
        }
    });
    exportExcel({
        excelData: excelValues,
        fileName: `jelentkezesek-${currentDate.getFullYear()}_${currentDate.getMonth() + 1}_${currentDate.getDate()}`
    }).then(r => {
    });
};

const CustomToolbar = () => {
    const {filterValues} = useListContext();
    let querystring = '';
    const keys = Object.keys(filterValues);
    keys.forEach((k: any) => {
        querystring += `&${k}=${filterValues[k]}`
    })
    const {permissions} = usePermissions();
    const [isOpen, setIsOpen] = useState(false);


    const [establishments, setEstablishments] = useState([]);
    const {data, total, isLoading, error} = useGetList(
        'establishment',
        {
            pagination: {page: 1, perPage: 1000},
            filter: {employerId: filterValues.employerId}
        }
    );
    useEffect(() => {
        if (data) {
            const temp = data.map((d: RaRecord) => {
                return {
                    id: d.id,
                    name: d.name
                }
            })
            // @ts-ignore
            setEstablishments(temp);
        }
    }, [data])

    const [employers, setEmployers] = useState([]);
    useEffect(() => {
        httpClient(`${apiUrl}/employer?export=true&page=1&size=1${querystring}`, {
            method: 'GET',
        }).then(({json}) => {
            setEmployers(json.items.map((d: RaRecord) => {
                return {
                    id: d.id,
                    name: d.name
                }
            }))
        })
    }, [])

    const jobApplicationFilters = [
        <SelectInput source="employerId" label={"Projekt"}
                     fullWidth
                     choices={employers}/>,
        <SelectInput source="establishmentId" label={"Telephely"}
                     fullWidth
                     choices={establishments}/>,
        <TextInput source="jobName" label={"Munka"} name={"jobName"} fullWidth/>,
        <SelectInput source="status" label={"Státusz"}
                     fullWidth
                     choices={[
                         {id: 'Active', name: 'Aktív'},
                         {id: 'Inactive', name: 'Inaktív'},
                         {id: 'NotYetDecided', name: 'Döntés alatt'},
                     ]}/>
    ];

    return <TopToolbar sx={{width: '100%', paddingTop: '20px'}}>
        <Stack direction={"column"} sx={{width: '100%'}}>
            <Stack direction="row"
                   justifyContent="flex-end"
                   alignItems={'center'}
                   sx={{width: '100%', paddingBottom: '5px'}}>
                <FilterForm filters={jobApplicationFilters}/>
                <div>
                    <FilterButton filters={jobApplicationFilters} style={{marginRight: '20px'}}/>
                </div>
                <Divider orientation={'vertical'} style={{height: '30px'}} sx={{borderRightWidth: 2}}/>
                {canAccess('Super', permissions) && <ExportButton onClick={() => {
                    httpClient(`${apiUrl}/job-application?export=true&page=1&size=1${querystring}`, {
                        method: 'GET',
                    }).then(({json}) => {
                        exporter(json.items)
                    })
                }
                } sx={{margin: '0px 30px'}}/>}
                {canAccess('Super', permissions) &&
                    <Divider orientation={'vertical'} style={{height: '30px'}} sx={{borderRightWidth: 2}}/>}
                <Button onClick={() => setIsOpen(true)} startIcon={<FileUploadOutlinedIcon/>}
                        style={{marginLeft: '50px', marginRight: '30px'}}>
                    Csoportos feltöltés
                </Button>
            </Stack>
        </Stack>
        <BulkJobApplicationDialog isOpen={isOpen} setIsOpen={setIsOpen}/>
    </TopToolbar>
}

export const JobApplicationList = () => (
    <List title={'Munkára jelentkezések'} actions={<CustomToolbar/>} exporter={false}>
        <Datagrid rowClick={'show'} bulkActionButtons={false}>
            <TextField source="id" label={"ID"}/>
            <FunctionField
                label="Projekt"
                render={(record: any) => {
                    return <EmployerFieldWrapper jobId={record.jobId}/>
                }}
            />
            <FunctionField
                label="Telephely"
                render={(record: any) => {
                    return <EstablishmentFieldWrapper jobId={record.jobId}/>
                }}
            />
            <ReferenceField source="jobId"
                            reference="job"
                            link={false}
                            label={"Munka"}>
                <TextField source="name"/>
            </ReferenceField>
            <ReferenceField source="employeeId"
                            reference="employee"
                            link={false}
                            label={"Munkavállaló"}>
                <TextField source="name"/>
            </ReferenceField>
            <FunctionField
                label="Státusz"
                render={(record: any) => `${getJobApplicationStatus(record.status)}`}
            />
        </Datagrid>
    </List>
);
