import * as React from 'react';
import {EditButton, Show, SimpleShowLayout, TextField, TopToolbar, useShowContext} from 'react-admin';
import {Typography} from "@mui/material";

const BlogShowActions = () => (
    <TopToolbar>
        <EditButton/>
    </TopToolbar>
);

const BlogShowLayout = () => {
    const {
        error,
        isLoading,
        record,
    } = useShowContext();

    if (isLoading) {
        return <div>Loading...</div>;
    }
    if (error) {
        return <div>Error!</div>;
    }
    return (
        <SimpleShowLayout>
            <TextField source="id" label={"ID"}/>
            <TextField source="title" label={"Cím"}/>
            <TextField source="url" label={"Url"}/>
            <Typography sx={{fontSize: '0.75em', color: '#B2BAC2', marginBottom: '0.2em'}}>Tartalom</Typography>
            <div dangerouslySetInnerHTML={{__html: record.content}}/>
        </SimpleShowLayout>
    );
};


export const StaticPageShow = () => {
    return <Show title={'Statikus oldal'} actions={<BlogShowActions/>}>
        <BlogShowLayout/>
    </Show>;
};
