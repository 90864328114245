import {
    Datagrid,
    FunctionField,
    ListContextProvider,
    ReferenceField,
    TextField,
    useGetList,
    useList
} from 'react-admin';
import * as React from "react";
import {getJobApplicationStatus} from "../enum-translates";
import Box from "@mui/material/Box";

interface Props {
    jobId: number;
}

export const EstablishmentJobApplications = (props: Props) => {
    const {data, isLoading} = useGetList(
        'job-application',
        {
            filter: {
                jobId: props.jobId
            },
            meta: undefined,
            pagination: {page: 1, perPage: 1000},
            sort: undefined,
        }
    );
    const listContext = useList({data, isLoading});
    if(data?.length === 0) {
        return <div>Nincsenek jelentkezők</div>
    }
    return (
        <Box>
            <ListContextProvider value={listContext}>
                <Datagrid bulkActionButtons={false}>
                    <TextField source="id" label={"Jelentkezés azonosító (ID)"}/>
                    <ReferenceField source="employeeId"
                                    reference="employee"
                                    link={false}
                                    label={"Munkavállaló"}>
                        <TextField source="name"/>
                    </ReferenceField>
                    <FunctionField
                        label="Státusz"
                        render={(record: any) => `${getJobApplicationStatus(record.status)}`}
                    />
                </Datagrid>
            </ListContextProvider>
        </Box>
    );
};