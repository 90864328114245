import * as React from "react";
import {useEffect, useState} from "react";
import {Button, Grid, IconButton, Stack, Tooltip, Typography} from '@mui/material';
import {ListContextProvider, SimpleForm, TextInput, useListController, useNotify, useRefresh} from 'react-admin';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import dataProvider, {apiUrl, httpClient} from "../dataProvider";
import AddIcon from '@mui/icons-material/Add';
import Dialog from "@mui/material/Dialog";
import {DocumentCreate} from "./document-create";
import {LinkedDoc} from "./linked-doc";
import DeleteOutlineOutlinedIcon from '@mui/icons-material/DeleteOutlineOutlined';
import DeleteIcon from '@mui/icons-material/Delete';
import EditOutlinedIcon from '@mui/icons-material/EditOutlined';
import EditIcon from '@mui/icons-material/Edit';
import Box from "@mui/material/Box";
import {DocumentDeleteWarningDialog} from "./document-delete-warning-dialog";

export interface ApiDocument {
    createdAt: Date,
    id: number | null,
    name: string,
    parentId: number | null,
    type: "Folder" | "File",
    url: string | null,
}

export const DocumentList = () => {
    const notify = useNotify();
    const listContext = useListController();
    const empty: ApiDocument = {
        createdAt: new Date(),
        id: null,
        name: '',
        parentId: null,
        type: "Folder",
        url: null,
    };
    const [previousDoc, setPreviousDoc] = useState(empty);
    const [isOpen, setIsOpen] = useState(false);

    useEffect(() => {
        listContext.setPerPage(500);
    }, []);

    const refresh = useRefresh();

    const [hoverEdit, setHoverEdit] = useState(false);
    const [editOpen, setEditOpen] = useState(false);
    const [hoverDelete, setHoverDelete] = useState(false);
    const [deleteOpen, setDeleteOpen] = useState(false);
    useEffect(() => {
        if (listContext.filterValues["parentId"]) {
            let url = `${apiUrl}/document/${listContext.filterValues["parentId"]}`;

            httpClient(url, {
                method: 'GET',
            }).then(({json}) => {
                setPreviousDoc(json);
            })
        } else {
            setPreviousDoc(empty);
        }
    }, [listContext.filterValues, editOpen, deleteOpen])

    const hideFolderOptions = Object.keys(listContext.filterValues).length === 0;

    if (listContext.isLoading) {
        return <div>Loading...</div>;
    }

    return <ListContextProvider value={listContext}>
        <Grid container>
            <Grid xs={12}>
                <Stack direction={"row"} alignItems={"center"} sx={{padding: '15px 0px'}}
                       justifyContent={'space-between'}>
                    <Stack direction={"row"} alignItems={"center"}>
                        <ArrowBackIcon
                            sx={{
                                cursor: 'pointer',
                            }}
                            onClick={() => {
                                if (previousDoc.parentId === null) {
                                    listContext.setFilters({}, null);
                                } else {
                                    listContext.setFilters({
                                        parentId: previousDoc.parentId
                                    }, null);
                                }
                            }}/>
                        {previousDoc !== empty && <Stack direction={'row'} alignItems={'center'}>
                            <Typography fontWeight={700} fontSize={18} sx={{marginLeft: '10px'}}>
                                {previousDoc.name}
                            </Typography>
                            {!hideFolderOptions &&
                                <Box sx={{marginLeft: '15px', marginRight: '5px', cursor: 'pointer'}}
                                     onMouseEnter={() => setHoverEdit(true)}
                                     onMouseLeave={() => setHoverEdit(false)}
                                >
                                    <Tooltip title={'Átnevezés'}>
                                        <IconButton color={'inherit'}
                                                    onClick={() => {
                                                        setEditOpen(true)
                                                    }}
                                        >
                                            {hoverEdit ? <EditIcon/> :
                                                <EditOutlinedIcon sx={{fontSize: 24}}/>}
                                        </IconButton>
                                    </Tooltip>
                                </Box>}
                            {!hideFolderOptions && <Box sx={{cursor: 'pointer'}}
                                                        onMouseEnter={() => setHoverDelete(true)}
                                                        onMouseLeave={() => setHoverDelete(false)}>
                                <Tooltip
                                    title={listContext.data.length === 0 ? 'Törlés' : 'A mappa nem törölhető mivel elem(ek)et tartalmaz'}>
                                    <Box>
                                        <IconButton color={listContext.data.length === 0 ? 'error' : 'inherit'}
                                                    onClick={() => setDeleteOpen(true)}
                                                    disabled={listContext.data.length > 0}
                                        >
                                            {hoverDelete && listContext.data.length === 0 ?
                                                <DeleteIcon sx={{fontSize: 24}}/> :
                                                <DeleteOutlineOutlinedIcon sx={{fontSize: 24,}}/>}
                                        </IconButton>
                                    </Box>
                                </Tooltip>
                            </Box>}
                            <DocumentDeleteWarningDialog title={'Mappa törlése'}
                                                         description={'Biztosan törölni szeretnéd ezt a mappát?'}
                                                         isOpen={deleteOpen}
                                                         setIsOpen={setDeleteOpen}
                                                         onAccept={() => {
                                                             if (previousDoc.parentId === null) {
                                                                 listContext.setFilters({}, null);
                                                             } else {
                                                                 listContext.setFilters({
                                                                     parentId: previousDoc.parentId
                                                                 }, null);
                                                             }
                                                             httpClient(`${apiUrl}/document/${previousDoc.id}`, {
                                                                 method: 'DELETE',
                                                             }).then(r => {
                                                                 if (r.status === 204) {
                                                                     notify(`Dokumentum eltávolítva`, {type: 'success'});
                                                                 } else {
                                                                     notify(`Hiba történt a dokumentum eltávolításakor`, {type: 'error'});
                                                                 }
                                                                 setDeleteOpen(false)
                                                             })
                                                         }}/>
                        </Stack>
                        }
                    </Stack>
                    <Button variant={'contained'} onClick={() => {
                        setIsOpen(true)
                    }} startIcon={<AddIcon/>}
                            style={{margin: '0px 30px'}}>
                        Új
                    </Button>
                </Stack>
            </Grid>
        </Grid>
        <Grid container spacing={2}>
            {listContext.data.map((doc) => {
                return <Grid item xs={12} sm={6} md={4}>
                    <LinkedDoc document={doc} setParentIdFilter={listContext.setFilters}/>
                </Grid>
            })}
        </Grid>
        <Dialog open={isOpen}
                onClose={() => {
                    setIsOpen(false);
                    refresh();
                }}
                fullWidth
                maxWidth="sm">
            <Typography sx={{marginTop: '25px', marginLeft: '20px'}} fontSize={20}>Új mappa/fájl</Typography>
            <DocumentCreate
                parentId={listContext.data.length > 0 ? listContext.data[0].parentId : previousDoc !== empty ? previousDoc.id : null}/>
        </Dialog>
        <Dialog open={editOpen}
                onClose={() => {
                    setEditOpen(false);
                    refresh();
                }}
                fullWidth
                maxWidth="sm">
            <Typography sx={{marginTop: '25px', marginLeft: '20px'}} fontSize={20}>Mappa átnevezése</Typography>
            <FolderRenameForm document={previousDoc} setEditOpen={setEditOpen}/>
        </Dialog>
    </ListContextProvider>
};

interface RenameProps {
    document: ApiDocument
    setEditOpen: any
}

const FolderRenameForm = (props: RenameProps) => {
    const notify = useNotify();
    return <SimpleForm onSubmit={async (values) => {
        const neededValues = {
            id: props.document.id,
            parentId: props.document.parentId,
            url: props.document.url,
            type: props.document.type,
            name: values.name,
        }
        await dataProvider.update('document', {
            id: props.document.id,
            data: neededValues
        }).then(res => {
            if (res.data) {
                notify(`Sikeres átnevezés`, {type: 'success'});
                props.setEditOpen(false);
            } else {
                notify(`Hiba történt`, {type: 'error'});
            }
        })
    }}>
        <TextInput defaultValue={props.document.name} source={'name'} name={'name'} label={'Név (kötelező)'} fullWidth/>
    </SimpleForm>
}
