import * as React from "react";
import {Datagrid, List, TextField, TopToolbar} from 'react-admin';

export const SystemParamsList = () => (
    <List title={'Rendszer paraméterek'} actions={<TopToolbar/>}>
        <Datagrid rowClick="edit" bulkActionButtons={false}>
            <TextField source="id" label={"ID"} sortable={false}/>
            <TextField source="name" label={"Név"} sortable={false}/>
            <TextField source="value" label={"Érték"} sortable={false}/>
        </Datagrid>
    </List>
);