import * as React from "react";
import {
    Create,
    Edit,
    SimpleForm,
    TextInput,
    useRecordContext,
    ImageField,
    ImageInput,
    DateTimeInput
} from 'react-admin';
import {
    RichTextInput,
    RichTextInputToolbar,
    LevelSelect,
    FormatButtons,
    ListButtons,
    LinkButtons,
    QuoteButtons,
    ClearButtons
} from "ra-input-rich-text";
import {Box, Typography} from "@mui/material";
import {MyImageButton} from "../layout/image-upload-button";

const BlogForm = () => {
    const record = useRecordContext();

    return <SimpleForm>
        <TextInput source="title" label={"Cím (kötelező)"} name={"title"} fullWidth required/>
        <TextInput source="lead" label={"Alcím (kötelező)"} name={"lead"} fullWidth required/>
        <RichTextInput source="content" label={"Tartalom (kötelező)"} name={"content"} fullWidth isRequired
                       toolbar={
                           <RichTextInputToolbar>
                               <LevelSelect/>
                               <FormatButtons/>
                               <ListButtons/>
                               <LinkButtons/>
                               <QuoteButtons/>
                               <ClearButtons/>
                               <MyImageButton/>
                           </RichTextInputToolbar>
                       }/>
        {(record && record.hasOwnProperty("thumbnail")) && record.thumbnail !== null && <Box>
            <Typography sx={{fontSize: '0.75em', color: '#B2BAC2', marginBottom: '0.2em'}}>Jelenlegi kép</Typography>
            <img src={`${record.thumbnail}`} alt={"current news picture"}
                 style={{margin: '10px 0px', maxHeight: '200px'}}/>
        </Box>
        }
        <ImageInput source="thumbnail" label="Index kép (kötelező)" accept="image/*" placeholder={<p>Kép megadása</p>}
                    name={"thumbnail"}
                    isRequired>
            <ImageField source="src" title="title"/>
        </ImageInput>
        {
            <Box sx={{opacity: 0}}>
                <DateTimeInput source="createdAt" label={""} name={"createdAt"}
                               fullWidth
                               inputProps={{
                                   max: '2099-12-31T23:59'
                               }}/>
            </Box>
        }
    </SimpleForm>
}

export const BlogCreate = () => (
    <Create title={'Blog bejegyzés létrehozása'}>
        <BlogForm/>
    </Create>
);

export const BlogEdit = () => (
    <Edit title={'Blog bejegyzés szerkesztése'}>
        <BlogForm/>
    </Edit>
);
