import * as React from "react";
import {
    AutocompleteInput,
    Create,
    Edit,
    ReferenceInput,
    SimpleForm,
    TextInput,
    FileInput,
    FileField,
    DateTimeInput
} from 'react-admin';
import {Box} from "@mui/material";

const filterToQuery = (searchText: string) => ({query: `%${searchText}%`});

const AttachmentForm = () => (
    <SimpleForm>
        <TextInput source="name" label={"Név (kötelező)"} name={"name"} required fullWidth/>
        <FileInput source="path" label="Fájl (kötelező)" isRequired>
            <FileField source="src" title="title"/>
        </FileInput>
        <ReferenceInput source="typeId"
                        reference="attachment-type"
                        name={"typeId"}>
            <AutocompleteInput source="name" label={"Csatolmány típus (kötelező)"} size={"small"} fullWidth isRequired
                               filterToQuery={filterToQuery}/>
        </ReferenceInput>
        {
            <Box sx={{opacity: 0}}>
                <DateTimeInput source="createdAt" label={""} name={"createdAt"}
                               fullWidth
                               inputProps={{
                                   max: '2099-12-31T23:59'
                               }}/>
            </Box>
        }
    </SimpleForm>
)

export const AttachmentCreate = () => (
    <Create title={'Csatolmány létrehozása'}>
        <AttachmentForm/>
    </Create>
);

export const AttachmentEdit = () => (
    <Edit title={'Csatolmány szerkesztése'}>
        <AttachmentForm/>
    </Edit>
);
