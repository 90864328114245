import * as React from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import Box from "@mui/material/Box";
import {Stack, Typography} from "@mui/material";

interface Props {
    isOpen: boolean;
    setIsOpen: any;
    onAccept: () => void;
    title: string
    description: string
}

export const DocumentDeleteWarningDialog = (props: Props) => {
    const handleClose = () => {
        props.setIsOpen(false);
    };

    return <Dialog open={props.isOpen} onClose={handleClose} fullWidth
                   maxWidth="xs">
        <DialogTitle>{props.title}</DialogTitle>
        <DialogContent>
            <Typography>{props.description}</Typography>
            <Box sx={{height: '25px'}}/>
            <Stack direction={'row'} alignItems={'center'} justifyContent={"space-between"}>
                <Button variant={'contained'}
                        color={"error"}
                        sx={{width: '150px'}}
                        onClick={handleClose}>
                    Mégsem
                </Button>
                <Button variant={'contained'}
                        sx={{width: '150px'}}
                        onClick={props.onAccept}>
                    Igen
                </Button>
            </Stack>
        </DialogContent>
    </Dialog>;
}
