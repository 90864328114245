import * as React from "react";
import {Datagrid, DateField, List, TextField, TextInput} from 'react-admin';

const notificationFilters = [
    <TextInput source="title" label={"Cím"} name={"title"} fullWidth required/>
];

export const NotificationList = () => (
    <List title={'Értesítések'} exporter={false} filters={notificationFilters}>
        <Datagrid rowClick={'show'} bulkActionButtons={false}>
            <TextField source="id" label={"ID"}/>
            <TextField source={"title"} label={"Cím"}/>
            <DateField source="createdAt" locales={['hu-HU']} label={"Létrehozva"}/>
        </Datagrid>
    </List>
);