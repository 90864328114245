import * as React from "react";
import {Datagrid, List, ReferenceField, TextField, TextInput} from 'react-admin';

const attachmentFilters = [
    <TextInput source="query" label={"Kulcsszó"} name={"query"} fullWidth required/>
];

export const ContactPersonList = () => (
    <List title={'Kapcsolattartók'} filters={attachmentFilters} exporter={false}>
        <Datagrid rowClick="show" bulkActionButtons={false}>
            <TextField source="id" label={"ID"}/>
            <ReferenceField source="employerId"
                            reference="employer"
                            label={"Projekt"}>
                <TextField source="name"/>
            </ReferenceField>
            <TextField source="name" label={"Név"}/>
            <TextField source="email" label={"E-mail"}/>
        </Datagrid>
    </List>
);