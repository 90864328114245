import * as React from "react";
import {Create, Edit, SelectInput, SimpleForm, TextInput} from 'react-admin';
import {Grid} from "@mui/material";

const EmployeeApplicationForm = () => {
    return <SimpleForm>
        <Grid container item xs={12} spacing={2} sx={{alignItems: 'center'}}>
            <Grid item xs={4}>
                <TextInput source="name" label={"Név (kötelező)"} name={"name"} fullWidth required/>
            </Grid>
            <Grid item xs={4}>
                <TextInput source="email" label={"E-mail"} name={"email"} fullWidth/>
            </Grid>
            <Grid item xs={4}>
                <TextInput source="phone" label={"Telefonszám"} name={"phone"} fullWidth/>
            </Grid>
            <Grid item xs={4}>
                <SelectInput source="status" label={"Státusz"}
                             fullWidth
                             defaultValue={'Initial'}
                             choices={[
                                 {id: 'Initial', name: 'Feldolgozásra vár'},
                                 {id: 'Disapproved', name: 'Elutasítva'},
                                 {id: 'DidNotAnswer', name: 'Nem vette fel'},
                                 {id: 'NeedsCallback', name: 'Vissza kell hívni'},
                                 {id: 'Approved', name: 'Elfogadva'},
                             ]}/>
            </Grid>
            <Grid item xs={8}>
                <TextInput source="campaign" label={"Kampány"} name={"campaign"} fullWidth/>
            </Grid>
            <Grid item xs={12}>
                <TextInput multiline source="note" label={"Megjegyzés"} name={"note"} fullWidth/>
            </Grid>
        </Grid>
    </SimpleForm>
}

export const EmployeeApplicationCreate = () => (
    <Create title={'Jelentkező létrehozása'} redirect={'/employee-application'}>
        <EmployeeApplicationForm/>
    </Create>
);
