import * as React from 'react';
import {EditButton, Show, SimpleShowLayout, TextField, TopToolbar, useShowContext} from 'react-admin';
import {Stack, Typography} from "@mui/material";

const EmailTemplateShowActions = () => (
    <TopToolbar>
        <EditButton/>
    </TopToolbar>
);

const EmailTemplateShowLayout = () => {
    const {
        error,
        isLoading,
        record,
    } = useShowContext();

    if (isLoading) {
        return <div>Loading...</div>;
    }
    if (error) {
        return <div>Error!</div>;
    }
    return (
        <SimpleShowLayout>
            <Stack direction={'column'}>
                <Typography fontWeight={700} fontSize={18}>Magyarázat</Typography>
                <Typography>{`{{password}}`} - jelszó</Typography>
                <Typography>{`{{name}}`} - név</Typography>
                <Typography>{`https://app.biztoskesz.hu/{{url_slug}}?token={{token}}`} - applikációba irányító
                    url</Typography>
                <Typography>{`{{address}}`} - pontos cím (pl.: munka helye)</Typography>
                <Typography>{`{{image}}`} - kep</Typography>
                <Typography>{`{{data}}`} - leírás</Typography>
                <Typography>{`{{start_date}}`} - kezdés időpontja (óra-perc)</Typography>
                <Typography>{`{{end_date}}`} - befejezés időpontja (óra-perc)</Typography>
                <Typography>{`{{title}}`} - cím</Typography>
                <Typography>{`{{content}}`} - értesítés tartalma</Typography>
                <Typography>{`https://app.biztoskesz.hu/auth/passwordReset?token={{token}}`} - jelszó helyreállítás
                    url</Typography>
            </Stack>
            <TextField source="id" label={"ID"}/>
            <TextField source="subject" label={"Tárgy"}/>
            <Typography sx={{fontSize: '0.75em', color: '#B2BAC2', marginBottom: '0.2em'}}>Tartalom</Typography>
            <div dangerouslySetInnerHTML={{__html: record.content}}/>
        </SimpleShowLayout>
    );
};


export const EmailTemplateShow = () => {
    return <Show title={'E-mail sablon'} actions={<EmailTemplateShowActions/>}>
        <EmailTemplateShowLayout/>
    </Show>;
};
