import * as React from 'react';
import {useEffect, useState} from 'react';
import {
    ChipField,
    DateField,
    FunctionField,
    ReferenceArrayField,
    Show,
    SimpleShowLayout,
    SingleFieldList,
    Tab,
    TabbedShowLayout,
    TextField,
    useGetOne,
    useShowContext
} from 'react-admin';
import {Box, Divider, Grid, Typography} from "@mui/material";
import {
    getEmployeeBankAccountOwner,
    getEmployeeGender,
    getEmployeeStatus,
    getEmployeeTypeName,
    getEmploymentTypeName
} from "../enum-translates";
import dataProvider, {apiUrl, httpClient} from "../dataProvider";
import {PersonalScheduleGrid} from "../job/schedule/schedule-worker/personal-schedule/personal-schedule-grid";
import {SubstractionsGrid} from "../job-application/substractions-grid";
import {EmployeeDocumentsForm} from "./employee-documents-form";

export const EmployeeShowLayout = () => {
    const {
        record,
    } = useShowContext();
    const [applications, setApplications]: any = useState();
    const [fetchedApplications, setFetchedApplications] = useState(false);
    useEffect(() => {
        if (typeof applications === 'undefined' && typeof record !== 'undefined') {
            httpClient(`${apiUrl}/job-application?employeeId=${record.id}&page=1&size=100`, {
                method: 'GET',
            }).then(({json}) => {
                setApplications(json.items);
                setFetchedApplications(true);
            })
        }
    });
    const [appliedJobs, setAppliedJobs]: any[] = useState();
    const [fetchedAppliedJobs, setFetchedAppliedJobs] = useState(false);
    const [establishments, setEstablishments]: any = useState();
    const [fetchedEstablishments, setFetchedEstablishments] = useState(false);
    useEffect(() => {
        if (typeof appliedJobs === 'undefined' && typeof applications !== 'undefined') {
            const ids = applications.map((a: any) => a.jobId);
            dataProvider.getMany('job', {
                ids: ids
            }).then(({data}) => {
                setAppliedJobs(data);
                setFetchedAppliedJobs(true);
                const estIds = data.map((a: any) => a.establishmentId);
                dataProvider.getMany('establishment', {
                    ids: estIds
                }).then(({data}) => {
                    setEstablishments(data);
                    setFetchedEstablishments(true);
                })
            })
        }
    }, [fetchedApplications]);

    const [complexJobs, setComplexJobs]: any[] = useState();
    useEffect(() => {
        if (typeof appliedJobs !== 'undefined' && typeof establishments !== 'undefined') {
            let tempJobs: any[] = [];
            appliedJobs.forEach((aj: any) => {
                const jobEstablishment = establishments.find((e: any) => e.id === aj.establishmentId);
                if (typeof jobEstablishment !== 'undefined') {
                    tempJobs.push({
                        ...aj,
                        establishment: jobEstablishment
                    })
                }
            })
            setComplexJobs(tempJobs);
        }
    }, [fetchedEstablishments])

    const [substractions, setSubstractions] = useState();
    useEffect(() => {
        // @ts-ignore
        httpClient(`${apiUrl}/job-subtraction?employeeId=${record.id}&page=1&size=100`, {
            method: 'GET',
        }).then(({json}) => {
            setSubstractions(json.items)
        })
    }, [])

    return <Box>
        {record && <Typography sx={{paddingLeft: '18px', paddingTop: '15px'}} fontSize={24}
                               fontWeight={700}>
            {record.name}
        </Typography>}
        <TabbedShowLayout>
            <Tab label="Beosztás">
                <PersonalScheduleGrid employee={record} isOpen={false}/>
            </Tab>
            <Tab label="Levonások">
                {typeof substractions !== 'undefined' && <SubstractionsGrid substractions={substractions}/>}
            </Tab>
            <Tab label="Alap információk">
                <Grid container spacing={0}>
                    <Grid container item xs={12}>
                        <Grid item xs={12}>
                            <SimpleShowLayout>
                                <TextField source="id" label={"ID"}/>
                            </SimpleShowLayout>
                        </Grid>
                    </Grid>
                    <Grid item xs={4}>
                        <SimpleShowLayout>
                            <TextField source="name" label={"Név"}/>
                            <TextField source="motherName" label={"Anyja neve"}/>
                            <FunctionField
                                label="Nem"
                                render={(record: any) => `${getEmployeeGender(record.gender)}`}
                            />
                            <TextField source="socialSecurityNumber" label={"TAJ szám"}/>
                        </SimpleShowLayout>
                    </Grid>
                    <Grid item xs={4}>
                        <SimpleShowLayout>
                            <FunctionField
                                label="Foglalkoztatási forma"
                                render={(record: any) => `${getEmployeeTypeName(record.type)}`}
                            />
                            <TextField source="email" label={"E-mail"}/>
                            <TextField source="birthLocation" label={"Születési hely"}/>
                            <FunctionField
                                label="Állandó lakcím (tartózkodási cím)"
                                render={(record: any) => record.homeAddress !== null ? `${record.homeAddress.country}, ${record.homeAddress.zip}, ${record.homeAddress.city}, ${record.homeAddress.address}` : '-'}
                            />
                        </SimpleShowLayout>
                    </Grid>
                    <Grid item xs={4}>
                        <SimpleShowLayout>
                            <FunctionField
                                label="Státusz"
                                render={(record: any) => `${getEmployeeStatus(record.status)}`}
                            />
                            <TextField source="phone" label={"Telefonszám"}/>
                            <DateField source="birthDate" locales={['hu-HU']} label={"Születési idő"}/>
                            <FunctionField
                                label="Postázási cím"
                                render={(record: any) => record.mailingAddress !== null ? `${record.mailingAddress.country}, ${record.mailingAddress.zip}, ${record.mailingAddress.city}, ${record.mailingAddress.address}` : '-'}
                            />
                        </SimpleShowLayout>
                    </Grid>
                    <Grid container item xs={12}>
                        <Grid item xs={12}>
                            <SimpleShowLayout>
                                <TextField source="taxId" label={"Adóazonosító"}/>
                            </SimpleShowLayout>
                        </Grid>
                    </Grid>
                    <Grid container item xs={12}>
                        <Grid item xs={4}>
                            <SimpleShowLayout>
                                <FunctionField
                                    label="Bankszámla tulajdonos"
                                    render={(record: any) => `${getEmployeeBankAccountOwner(record.bankAccountOwner)}`}
                                />
                                <TextField source="bankAccountNumber" label={"Bankszámlaszám"}/>
                            </SimpleShowLayout>
                        </Grid>
                        <Grid item xs={4}>
                            <SimpleShowLayout>
                                <DateField source="healthBookletExpiresAt" locales={['hu-HU']}
                                           label={"Eü kiskönyv érvényes"}/>
                            </SimpleShowLayout>
                        </Grid>
                    </Grid>
                    <Grid item xs={12}>
                        <SimpleShowLayout>
                            <Box>
                                <Typography
                                    sx={{
                                        fontSize: '0.75em',
                                        color: '#d7dbdf',
                                        marginBottom: '10px'
                                    }}>Tudások</Typography>
                                <ReferenceArrayField label="" reference="skill" source="skills">
                                    <SingleFieldList>
                                        <ChipField source="name"/>
                                    </SingleFieldList>
                                </ReferenceArrayField>
                            </Box>
                            <TextField source="note" label={"Megjegyzés"}/>
                        </SimpleShowLayout>
                    </Grid>
                </Grid>
            </Tab>
            <Tab label="Munkára jelentkezések">
                {fetchedAppliedJobs && typeof complexJobs !== 'undefined' && complexJobs.length === 0 ?
                    <Typography fontSize={16}>Nincsenek munkára jelentkezések</Typography> :
                    <>
                        <Grid container spacing={1}>
                            <Grid item xs={3}>
                                <Typography fontSize={14} fontWeight={600}>Projekt</Typography>
                            </Grid>
                            <Grid item xs={3}>
                                <Typography fontSize={14} fontWeight={600}>Telephely</Typography>
                            </Grid>
                            <Grid item xs={3}>
                                <Typography fontSize={14} fontWeight={600}>Munka</Typography>
                            </Grid>
                            <Grid item xs={3}>
                                <Typography fontSize={14} fontWeight={600}>Típus</Typography>
                            </Grid>
                            <Grid item xs={12}>
                                <Divider sx={{width: '100%', borderBottomWidth: '3px'}}/>
                            </Grid>
                            {/*//@ts-ignore*/}
                            {typeof complexJobs !== 'undefined' && complexJobs.map((s, index) => {
                                return <Grid item xs={12} container spacing={1}>
                                    <Grid item xs={3}>
                                        <EmployerField employerId={s.establishment.employerId}/>
                                    </Grid>
                                    <Grid item xs={3}>
                                        <FunctionField
                                            render={() => `${s.establishment.name}`}
                                        />
                                    </Grid>
                                    <Grid item xs={3}>
                                        <FunctionField
                                            render={() => `${s.name}`}
                                        />
                                    </Grid>
                                    <Grid item xs={3}>
                                        <FunctionField
                                            textAlign={"right"}
                                            render={() => `${getEmploymentTypeName(s.employmentType)}`}
                                        />
                                    </Grid>
                                    <Grid item xs={12}>
                                        <Divider sx={{width: '100%'}}/>
                                    </Grid>
                                </Grid>
                            })}
                        </Grid>
                    </>
                }
            </Tab>
            <Tab label="Dokumentumok">
                <EmployeeDocumentsForm record={record}/>
            </Tab>
        </TabbedShowLayout>
    </Box>;
};

export const EmployeeShow = () => {
    return <Show title={'Munkavállaló'}>
        <EmployeeShowLayout/>
    </Show>;
};

interface EmployerFieldProps {
    employerId: number,
}

const EmployerField = (props: EmployerFieldProps) => {
    const {data: employer, isLoading, error} = useGetOne('employer', {id: props.employerId});
    if (isLoading) {
        return <Box/>;
    }
    if (error) {
        return <p>ERROR</p>;
    }
    return <FunctionField
        render={() => `${employer.name}`}
    />
}
