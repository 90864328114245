import * as React from "react";
import {Card, CardContent, Typography} from '@mui/material';
import {Title} from 'react-admin';
import {ThemeChangeButton} from "./theme-change-button";

const ThemeChangePage = () => {
    return <Card>
        <Title title="Téma változtatás"/>
        <CardContent>
            <Typography>Téma</Typography>
            <ThemeChangeButton/>
        </CardContent>
    </Card>
};

export default ThemeChangePage;