import * as React from 'react';
import {useEffect, useState} from 'react';
import {
    BooleanField,
    DateField,
    FunctionField,
    NumberField,
    ReferenceField,
    Show,
    Tab,
    TabbedShowLayout,
    TextField,
    useShowContext
} from 'react-admin';
import {
    getJobApplicationEmploymentType,
    getJobApplicationSalaryType,
    getJobApplicationStatus
} from "../enum-translates";
import {apiUrl, httpClient} from "../dataProvider";
import {Typography} from "@mui/material";
import {SubstractionsGrid} from "./substractions-grid";

const JobApplicationShowLayout = () => {
    const {
        error,
        isLoading,
        record
    } = useShowContext();

    const [feedBack, setFeedBack]: any = useState();
    const [fetched, setFetched] = useState(false);
    useEffect(() => {
        if (typeof feedBack === 'undefined' && typeof record !== 'undefined') {
            httpClient(`${apiUrl}/job-feedback?applicationId=${record.id}&page=1&size=1`, {
                method: 'GET',
            }).then(({json}) => {
                setFeedBack(json.items[0]);
                setFetched(true);
            })
        }
    })

    const [substractions, setSubstractions] = useState();
    useEffect(() => {
        if (typeof feedBack !== 'undefined') {
            // @ts-ignore
            httpClient(`${apiUrl}/job-subtraction?feedbackId=${feedBack.id}&page=1&size=100`, {
                method: 'GET',
            }).then(({json}) => {
                setSubstractions(json.items)
            })
        }
    }, [feedBack])

    if (isLoading) {
        return <div>Loading...</div>;
    }
    if (error) {
        return <div>Error!</div>;
    }

    return (
        <TabbedShowLayout>
            <Tab label="Alap információk">
                <TextField source="id" label={"ID"}/>
                <ReferenceField source="jobId"
                                reference="job"
                                label={"Munka"}>
                    <TextField source="name"/>
                </ReferenceField>
                <ReferenceField source="employeeId"
                                reference="employee"
                                label={"Munkavállaló"}>
                    <TextField source="name"/>
                </ReferenceField>
                <FunctionField
                    label="Státusz"
                    render={(record: any) => `${getJobApplicationStatus(record.status)}`}
                />
                <NumberField source="salary.salary" label={"Bér"}/>
                {record.salary !== null && record.salary.type !== null && <FunctionField
                    label="Bér típusa"
                    render={(record: any) => `${getJobApplicationSalaryType(record.salary.type)}`}
                />}
                <BooleanField source={"willBeAbleToCome"} label={"Tud jönni"}/>
                {record.employmentType !== null && <FunctionField
                    label="Bejelentés státusza"
                    render={(record: any) => `${getJobApplicationEmploymentType(record.employmentType)}`}
                />}
                <DateField showTime source={"canStartAt"} label={"Mikortól tud jönni"} locales={'hu'}/>
                <DateField showTime source={"canFinishAt"} label={"Meddig tud jönni"} locales={'hu'}/>
            </Tab>
            <Tab label="Utókövetés">
                {fetched && <>
                    <Typography fontSize={14} fontWeight={600} sx={{marginBottom: '4px', marginTop: '20px'}}>Valós
                        munkaórák száma</Typography>
                    {typeof feedBack !== 'undefined' && feedBack.actualWorkingHours !== null && <FunctionField
                        render={() => `${feedBack.actualWorkingHours}`}
                    />}
                    <Typography fontSize={14} fontWeight={600}
                                sx={{marginBottom: '4px', marginTop: '20px'}}>Megjegyzés</Typography>
                    {typeof feedBack !== 'undefined' && feedBack.note !== null && <FunctionField
                        label="Megjegyzés"
                        render={() => `${feedBack.note}`}
                    />}
                </>
                }
            </Tab>
            {typeof feedBack !== 'undefined' && <Tab label="Levonások">
                {/*//@ts-ignore*/}
                {typeof substractions !== 'undefined' && <SubstractionsGrid substractions={substractions}/>}
            </Tab>}
        </TabbedShowLayout>
    );
};

export const JobApplicationShow = () => {
    return <Show title={'Jelentkezés'}>
        <JobApplicationShowLayout/>
    </Show>;
};
