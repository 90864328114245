import * as React from 'react';
import {EditButton, ImageField, Show, SimpleShowLayout, TextField, TopToolbar, useShowContext} from 'react-admin';

const CoworkerShowActions = () => (
    <TopToolbar>
        <EditButton/>
    </TopToolbar>
);

const CoworkerShowLayout = () => {
    const {
        error,
        isLoading,
        record,
    } = useShowContext();

    if (isLoading) {
        return <div>Loading...</div>;
    }
    if (error) {
        return <div>Error!</div>;
    }
    return (
        <SimpleShowLayout>
            <TextField source="id" label={"ID"}/>
            {record.profilePicture !== null && <ImageField source={"profilePicture"} label={"Profil kép"}/>}
            <TextField source="name" label={"Név"}/>
            <TextField source="position" label={"Pozíció"}/>
            <TextField source="email" label={"E-mail"}/>
            <TextField source="phone" label={"Telefonszám"}/>
            <TextField source="facebookLink" label={"Facebook link"}/>
        </SimpleShowLayout>
    );
};


export const CoworkerShow = () => {
    return <Show title={'Munkatárs'} actions={<CoworkerShowActions/>}>
        <CoworkerShowLayout/>
    </Show>;
};
