import * as React from "react";
import {Datagrid, List, TextField, TextInput} from 'react-admin';

const faqFilters = [
    <TextInput label="Kulcsszó" source="query" name={"query"}/>,
];

export const FaqList = () => (
    <List title={'Gyakori kérdések'} exporter={false} filters={faqFilters}>
        <Datagrid rowClick={'edit'} bulkActionButtons={false}>
            <TextField source="id" label={"ID"}/>
            <TextField source="question" label={"Kérdés"}/>
            <TextField source="answer" label={"Válasz"}/>
        </Datagrid>
    </List>
);