import * as React from "react";
import {Datagrid, List, TextField, TextInput} from 'react-admin';

const areaFilters = [
    <TextInput label="Kulcsszó" source="query" name={"query"}/>,
];

export const AreaList = () => (
    <List title={'Térség'} exporter={false} filters={areaFilters}>
        <Datagrid rowClick={'edit'} bulkActionButtons={false}>
            <TextField source="id" label={"ID"}/>
            <TextField source="name" label={"Név"}/>
        </Datagrid>
    </List>
);