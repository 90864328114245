import * as React from "react";
import {Create, Edit, SimpleForm, TextInput} from 'react-admin';
import {Grid, Typography} from "@mui/material";

const CategoryForm = () => (
    <SimpleForm>
        <Grid container spacing={1}>
            <Grid item xs={6}>
                <TextInput source="name" label={"Név (kötelező)"} name={"name"} required fullWidth/>
            </Grid>
        </Grid>
        <Grid container spacing={1}>
            <Grid item xs={6}>
                <Typography sx={{marginBottom: '15px'}}>Emojik: <a href={'https://getemoji.com/'} target={'_blank'}>https://getemoji.com/</a></Typography>
                <TextInput source="emoji" label={"Jel"} name={"emoji"} fullWidth/>
            </Grid>
        </Grid>
    </SimpleForm>
)

export const CategoryCreate = () => (
<Create title={'Kategória létrehozása'} redirect={"/category"}>
    <CategoryForm/>
</Create>
);

export const CategoryEdit = () => (
<Edit title={'Kategória szerkesztése'}>
    <CategoryForm/>
</Edit>
);
