import * as React from "react";
import {AutocompleteInput, BooleanInput, DateTimeInput, NumberInput, ReferenceInput, SelectInput} from 'react-admin';
import {Grid} from "@mui/material";
import {dateParser, dateTimeFormatter} from "../../date-formatter/date-formatter";
import {inputEmployeeText, inputText, renderEmployeeOption, renderOption} from "../job-application-create";

interface InfosProps {
    required: boolean
}

const filterToQuery = (searchText: string) => ({query: `%${searchText}%`});

export const JobApplicationInfosTab = (props: InfosProps) => {
    return <>
        <Grid container item xs={12} spacing={2} sx={{alignItems: 'center'}}>
            <Grid item xs={6}>
                <ReferenceInput source="jobId"
                                reference="job"
                                name={"jobId"}>
                    <AutocompleteInput source="name"
                                       label={"Munka (kötelező)"}
                                       size={"small"}
                                       fullWidth
                                       isRequired={props.required}
                                       inputText={inputText}
                                       optionText={renderOption}
                                       optionValue={"id"}
                                       filterToQuery={filterToQuery}
                    />
                </ReferenceInput>
            </Grid>
            <Grid item xs={6}>
                <ReferenceInput source="employeeId"
                                reference="employee"
                                name={"employeeId"}>
                    <AutocompleteInput source="name"
                                       optionValue={"id"}
                                       label={"Munkavállaló (kötelező)"}
                                       size={"small"}
                                       fullWidth
                                       isRequired={props.required}
                                       inputText={inputEmployeeText}
                                       optionText={renderEmployeeOption}
                                       filterToQuery={filterToQuery}
                    />
                </ReferenceInput>
            </Grid>
        </Grid>
        <Grid container item xs={12} spacing={2} sx={{alignItems: 'center'}}>
            <Grid item xs={12}>
                <SelectInput source="status" label={"Státusz (felhasználó is látja)"}
                             fullWidth
                             size={"small"}
                             choices={[
                                 {id: 'Active', name: 'Aktív'},
                                 {id: 'Inactive', name: 'Inaktív'},
                                 {id: 'NotYetDecided', name: 'Döntés alatt'},
                             ]}/>
            </Grid>
        </Grid>
        <Grid container item xs={12} spacing={2} sx={{alignItems: 'center'}}>
            <Grid item xs={6}>
                <NumberInput source="salary.salary" label={"Bér"} name={"salary.salary"} fullWidth/>
            </Grid>
            <Grid item xs={6}>
                <SelectInput source="salary.type" label={"Bérezés típusa"}
                             fullWidth
                             choices={[
                                 {id: 'Fix', name: 'Fix'},
                                 {id: 'HourlyWage', name: 'Órabér'},
                             ]}/>
            </Grid>
        </Grid>
        <Grid container item xs={12} spacing={2} sx={{alignItems: 'center'}}>
            <Grid item xs={6}>
                <BooleanInput source="willBeAbleToCome" label={"Tud jönni"} name={"willBeAbleToCome"} fullWidth/>
            </Grid>
            <Grid item xs={6}>
                <SelectInput source="employmentType" label={"Bejelentés státusza"}
                             fullWidth
                             size={"small"}
                             choices={[
                                 {id: 'White', name: 'Bejelentve'},
                                 {id: 'Black', name: 'Nincs bejelentve'},
                             ]}/>
            </Grid>
        </Grid>
        <Grid container item xs={12} spacing={2} sx={{alignItems: 'center'}}>
            <Grid item xs={6}>
                <DateTimeInput source="canStartAt" label={"Mikortól tud jönni"} name={"canStartAt"}
                               fullWidth format={dateTimeFormatter} parse={dateParser}
                               inputProps={{
                                   max: '2099-12-31T23:59'
                               }}/>
            </Grid>
            <Grid item xs={6}>
                <DateTimeInput source="canFinishAt" label={"Meddig tud jönni"} name={"canFinishAt"} fullWidth
                               format={dateTimeFormatter} parse={dateParser}
                               inputProps={{
                                   max: '2099-12-31T23:59'
                               }}/>
            </Grid>
        </Grid>
    </>
}
