import * as React from 'react';
import {
    ArrayField,
    ChipField,
    DateField, FunctionField,
    ImageField, ReferenceArrayField,
    Show,
    SimpleShowLayout, SingleFieldList,
    TextField,
    TopToolbar,
    useRecordContext,
    useShowContext
} from 'react-admin';
import {Box, Chip, Stack, Typography} from "@mui/material";

const NotificationShowLayout = () => {
    const {
        error,
        isLoading,
        record,
    } = useShowContext();

    if (isLoading) {
        return <div>Loading...</div>;
    }
    if (error) {
        return <div>Error!</div>;
    }
    return (
        <SimpleShowLayout>
            <TextField source="id" label={"ID"}/>
            <TextField source={"title"} label={"Cím"}/>
            <Typography sx={{fontSize: '0.75em', color: '#B2BAC2', marginBottom: '0.2em'}}>Tartalom</Typography>
            <div dangerouslySetInnerHTML={{__html: record.content}}/>
            <ImageField source={"image"} label={"Kép"}/>
            {record && record.recipients && record.recipients.topics && record.recipients.topics.length > 0 && <Box>
                <Typography fontSize={12}>Címzettek</Typography>
                <Typography fontWeight={700} fontSize={14}>Minden munkavállaló</Typography>
            </Box>}
            {record && record.recipients && record.recipients.userIds && record.recipients.userIds.length > 0 && <Box>
                <Typography
                    sx={{fontSize: '0.75em', color: '#d7dbdf', marginBottom: '10px'}}>Címzett felhasználók</Typography>
                <ReferenceArrayField label="" reference="employee" source="recipients.userIds">
                    <SingleFieldList>
                        <ChipField source="name"/>
                    </SingleFieldList>
                </ReferenceArrayField>
            </Box>}
            {record && record.recipients && record.recipients.jobIds && record.recipients.jobIds.length > 0 && <Box>
                <Typography
                    sx={{fontSize: '0.75em', color: '#d7dbdf', marginBottom: '10px'}}>Munkák</Typography>
                <ReferenceArrayField label="" reference="job" source="recipients.jobIds">
                    <SingleFieldList>
                        <ChipField source="name"/>
                    </SingleFieldList>
                </ReferenceArrayField>
            </Box>}
            <br/>
            <DateField source="createdAt" locales={['hu-HU']} label={"Létrehozva"}/>
        </SimpleShowLayout>
    );
};

export const NotificationShow = () => {
    return <Show title={'Értesítés'} actions={<TopToolbar/>}>
        <NotificationShowLayout/>
    </Show>;
};

const RecipientTopics = () => {
    const record = useRecordContext();
    if (!record) return null;
    if (!record.recipients.topics) return null;
    return <Box>
        <Typography sx={{fontSize: '0.75em', color: '#B2BAC2', marginBottom: '10px'}}>Topicok</Typography>
        <Stack direction={"row"} spacing={1}>
            {record.recipients.topics.map((r: any) => {
                    return <Chip label={`${r}`} variant={"filled"} color={"info"}/>
                }
            )}
        </Stack>
    </Box>;
};