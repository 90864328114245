import * as React from "react";
import {Datagrid, FunctionField, List, TextField, TextInput} from 'react-admin';
import {getAttachmentTypeScope} from "../enum-translates";

const attachmentTypeFilters = [
    <TextInput source="query" label={"Kulcsszó"} name={"query"} fullWidth required/>
];

export const AttachmentTypeList = () => (
    <List title={'Csatolmány típusok'} filters={attachmentTypeFilters} exporter={false}>
        <Datagrid rowClick="edit" bulkActionButtons={false}>
            <TextField source="id" label={"ID"}/>
            <TextField source="name" label={"Név"}/>
            <FunctionField
                label="Csatolás"
                render={(record: any) => `${getAttachmentTypeScope(record.scope)}`}
            />
        </Datagrid>
    </List>
);