import * as React from "react";
import {NumberInput, TextInput} from 'react-admin';
import {Grid} from "@mui/material";

interface FeedBackProps {
    fetched: boolean
    feedBack: any
    required: boolean
}

export const JobApplicationFeedbackTab = (props: FeedBackProps) => {
    return <>
        {props.fetched && <>
            <Grid container item xs={12} spacing={0} sx={{alignItems: 'center'}}>
                <Grid item xs={6}>
                    <NumberInput name={"actualWorkingHours"} source={"actualWorkingHours"}
                                 label={"Valós munkaórák száma"}
                                 fullWidth
                                 defaultValue={props.feedBack ? props.feedBack.actualWorkingHours : null}
                    />
                </Grid>
                <Grid item xs={12}>
                    <TextInput source="note" label={"Megjegyzés"} name={"note"} fullWidth multiline
                               defaultValue={props.feedBack ? props.feedBack.note : null}
                    />
                </Grid>
            </Grid>
        </>}
    </>
}