import * as React from "react";
import {Admin, CustomRoutes, Resource, useTheme} from 'react-admin';
import {Route} from "react-router-dom";
import {NotificationList} from "./notification/notification-list";
import {CustomLayout} from "./layout/custom-layout";
import {brandingDarkTheme, brandingLightTheme} from "./theme";
import {NotificationShow} from "./notification/notification-show";
import {NotificationCreate} from "./notification/notification-create-and-edit";
import polyglotI18nProvider from 'ra-i18n-polyglot';
import {injectCustomMessages} from "./custom-admin-messages";
import {authProvider} from "./auth/authProvider";
import {AdministratorList} from "./administrator/administrator-list";
import {AdministratorCreate} from "./administrator/administrator-create";
import {customHungarianMessages} from "./hungarian-messages";
import PasswordChangePage from "./password-change/password-change-page";
import {SystemParamsList} from "./system-params/system-params-list";
import {SystemParamEdit} from "./system-params/system-params-edit";
import myDataProvider from "./data-provider-with-image-convert";
import {JobList} from "./job/job-list";
import {JobShow} from "./job/job-show";
import {JobCreate} from "./job/job-create";
import {EmployerList} from "./employer/employer-list";
import {EmployerCreate} from "./employer/employer-create";
import {EmployerShow} from "./employer/employer-show";
import {EmployeeList} from "./employee/employee-list";
import {EmployeeCreate, EmployeeEdit} from "./employee/employee-create-and-edit";
import {EmployeeShow} from "./employee/employee-show";
import {SkillList} from "./skill/skill-list";
import {SkillCreate, SkillEdit} from "./skill/skill-create-and-edit";
import {CategoryList} from "./category/category-list";
import {CategoryCreate, CategoryEdit} from "./category/category-create-and-edit";
import {FaqCreate, FaqEdit} from "./faq/faq-create-and-edit";
import {FaqList} from "./faq/faq-list";
import {BlogCreate, BlogEdit} from "./blog/blog-create-and-edit";
import {BlogList} from "./blog/blog-list";
import {BlogShow} from "./blog/blog-show";
import {OfficeCreate, OfficeEdit} from "./office/office-create-and-edit";
import {OfficeList} from "./office/office-list";
import {OfficeShow} from "./office/office-show";
import {AreaList} from "./area/area-list";
import {AreaCreate, AreaEdit} from "./area/area-create-and-edit";
import {JobApplicationShow} from "./job-application/job-application-show";
import {JobApplicationList} from "./job-application/job-application-list";
import {StaticPageList} from "./static-page/static-page-list";
import {StaticPageShow} from "./static-page/static-page-show";
import {StaticPageCreate, StaticPageEdit} from "./static-page/static-page-create-and-edit";
import {AttachmentTypeCreate, AttachmentTypeEdit} from "./attachment-type/attachment-type-create-and-edit";
import {AttachmentTypeList} from "./attachment-type/attachment-type-list";
import {EmailTemplateEdit} from "./email-template/email-template-create-and-edit";
import {EmailTemplateShow} from "./email-template/email-template-show";
import {EmailTemplateList} from "./email-template/email-template-list";
import {CoworkerCreate, CoworkerEdit} from "./coworker/coworker-create-and-edit";
import {CoworkerList} from "./coworker/coworker-list";
import {CoworkerShow} from "./coworker/coworker-show";
import {SubtractionTypeList} from "./job-subtraction-type/subtraction-type-list";
import {SubtractionTypeCreate, SubtractionTypeEdit} from "./job-subtraction-type/subtraction-type-create-and-edit";
import {JobApplicationCreate} from "./job-application/job-application-create";
import {AttachmentList} from "./attachment/attachment-list";
import {AttachmentShow} from "./attachment/attachment-show";
import {AttachmentCreate, AttachmentEdit} from "./attachment/attachment-create-and-edit";
import {ContactPersonList} from "./contact-person/contact-person-list";
import {ContactPersonShow} from "./contact-person/contact-person-show";
import ThemeChangePage from "./theme-change/theme-change-page";
import {JobEdit} from "./job/job-edit";
import {EmployerEdit} from "./employer/employer-edit";
import {AdministratorEdit} from "./administrator/administrator-edit";
import {darkRedTheme, lightRedTheme} from "./theme-red";
import {darkPinkTheme, lightPinkTheme} from "./theme-pink";
import {darkBlueTheme, lightBlueTheme} from "./theme-blue";
import {darkPurpleTheme, lightPurpleTheme} from "./theme-purple";
import {DocumentList} from "./document/document-list";
import {DocumentCreate} from "./document/document-create";
import {EstablishmentShow} from "./establishment/establishment-show";
import {JobApplicationEdit} from "./job-application/edit/job-application-edit";
import {EmployeeApplicationList} from "./employee-application/employee-application-list";
import {
    EmployeeApplicationCreate,
} from "./employee-application/employee-application-create";
import {EmployeeApplicationEdit} from "./employee-application/employee-application-edit";

const messages = {
    'hu': injectCustomMessages(customHungarianMessages),
};
const i18nProvider = polyglotI18nProvider(() => messages['hu'], 'hu');

export const getCurrentTheme = () => {
    const themeColor = localStorage.getItem('myThemeType');
    let newTheme = brandingDarkTheme;
    switch (themeColor as string) {
        case 'dark-default':
            newTheme = brandingDarkTheme;
            break;
        case 'light-default':
            newTheme = brandingLightTheme;
            break;
        case 'dark-red':
            newTheme = darkRedTheme;
            break;
        case 'light-red':
            newTheme = lightRedTheme;
            break;
        case 'dark-pink':
            newTheme = darkPinkTheme;
            break;
        case 'light-pink':
            newTheme = lightPinkTheme;
            break;
        case 'dark-blue':
            newTheme = darkBlueTheme;
            break;
        case 'light-blue':
            newTheme = lightBlueTheme;
            break;
        case 'dark-purple':
            newTheme = darkPurpleTheme;
            break;
        case 'light-purple':
            newTheme = lightPurpleTheme;
            break;
        default:
            newTheme = brandingDarkTheme;
    }
    return newTheme;
};

const App = () => {
    const [theme, setTheme] = useTheme();
    return <Admin dataProvider={myDataProvider}
                  i18nProvider={i18nProvider}
                  authProvider={authProvider}
                  theme={theme}
                  layout={CustomLayout}>
        <Resource name="employer"
                  list={EmployerList}
                  edit={EmployerEdit}
                  create={EmployerCreate}
                  show={EmployerShow}
        />
        <Resource name="job"
                  list={JobList}
                  edit={JobEdit}
                  create={JobCreate}
                  show={JobShow}
        />
        <Resource name="employee"
                  list={EmployeeList}
                  edit={EmployeeEdit}
                  show={EmployeeShow}
                  create={EmployeeCreate}
        />
        <Resource name="employee-application"
                  list={EmployeeApplicationList}
                  edit={EmployeeApplicationEdit}
                  create={EmployeeApplicationCreate}
        />
        <Resource name="notification"
                  list={NotificationList}
                  create={NotificationCreate}
                  show={NotificationShow}
        />
        <Resource name="administrator"
                  list={AdministratorList}
                  create={AdministratorCreate}
                  edit={AdministratorEdit}
        />
        <Resource name="system-params"
                  list={SystemParamsList}
                  edit={SystemParamEdit}
        />
        <Resource name="skill"
                  list={SkillList}
                  edit={SkillEdit}
                  create={SkillCreate}
        />
        <Resource name="category"
                  list={CategoryList}
                  edit={CategoryEdit}
                  create={CategoryCreate}
        />
        <Resource name="faq"
                  list={FaqList}
                  edit={FaqEdit}
                  create={FaqCreate}
        />
        <Resource name="blog"
                  list={BlogList}
                  edit={BlogEdit}
                  create={BlogCreate}
                  show={BlogShow}
        />
        <Resource name="office"
                  list={OfficeList}
                  edit={OfficeEdit}
                  create={OfficeCreate}
                  show={OfficeShow}
        />
        <Resource name="area"
                  list={AreaList}
                  edit={AreaEdit}
                  create={AreaCreate}
        />
        <Resource name="job-application"
                  list={JobApplicationList}
                  show={JobApplicationShow}
                  edit={JobApplicationEdit}
                  create={JobApplicationCreate}
        />
        <Resource name="static-page"
                  list={StaticPageList}
                  show={StaticPageShow}
                  edit={StaticPageEdit}
                  create={StaticPageCreate}
        />
        <Resource name="attachment"
                  list={AttachmentList}
                  edit={AttachmentEdit}
                  create={AttachmentCreate}
                  show={AttachmentShow}
        />
        <Resource name="attachment-type"
                  list={AttachmentTypeList}
                  edit={AttachmentTypeEdit}
                  create={AttachmentTypeCreate}
        />
        <Resource name="email-template"
                  list={EmailTemplateList}
                  edit={EmailTemplateEdit}
                  show={EmailTemplateShow}
        />
        <Resource name="coworker"
                  list={CoworkerList}
                  edit={CoworkerEdit}
                  create={CoworkerCreate}
                  show={CoworkerShow}
        />
        <Resource name="job-subtraction-type"
                  list={SubtractionTypeList}
                  edit={SubtractionTypeEdit}
                  create={SubtractionTypeCreate}
        />
        <Resource name="employer-contact-person"
                  list={ContactPersonList}
                  show={ContactPersonShow}
        />
        <Resource name="document"
                  list={DocumentList}
                  create={DocumentCreate}
        />
        <Resource name="establishment"
                  show={EstablishmentShow}
        />
        <CustomRoutes>
            <Route path="/password-change" element={<PasswordChangePage/>}/>
            <Route path="/theme-change" element={<ThemeChangePage/>}/>
        </CustomRoutes>
    </Admin>
};

export default App;
