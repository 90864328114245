import * as React from "react";
import {Datagrid, DeleteWithConfirmButton, List, TextField, TextInput, usePermissions} from 'react-admin';
import {canAccess} from "../layout/custom-menu";

const adminFilters = [
    <TextInput label="Kulcsszó" source="query" name={"query"}/>,
];

export const AdministratorList = () => {
    const {permissions} = usePermissions();
    return <List title={'Felhasználók'}
          exporter={false} filters={adminFilters}
          sort={{field: 'createdAt', order: 'DESC'}}
    >
        <Datagrid bulkActionButtons={false} rowClick={canAccess('Super', permissions) ? "edit" : ""}>
            <TextField source="id" label={"ID"} sortable={false}/>
            <TextField source="name" label={"Név"}/>
            <TextField source="email" label={"E-mail"}/>
            {canAccess('Super', permissions) && <DeleteWithConfirmButton
                confirmTitle={"Felhasználó eltávolítása"}
                confirmContent={"A felhasználó véglegesen kikerül a Felhasználók közül"}
            />}
        </Datagrid>
    </List>
};