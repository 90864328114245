import * as React from "react";
import {Create, Edit, SaveButton, SimpleForm, TextInput, Toolbar, useRecordContext} from 'react-admin';
import {useState} from "react";
import {FaqDeleteWarningDialog} from "./faq-delete-warning-dialog";
import {Button, Stack, Typography} from "@mui/material";
import DeleteIcon from '@mui/icons-material/Delete';

interface Props {
    setIsOpen: any;
    isEdit: boolean;
}

const CustomToolbar = (props: Props) => (
    <Toolbar
        sx={{display: 'flex', justifyContent: 'space-between'}}
    >
        <SaveButton/>
        {props.isEdit && <Button onClick={() => props.setIsOpen(true)} color={'error'}>
            <Stack direction={'row'} alignItems={'center'}>
                <DeleteIcon/>
                <Typography sx={{marginLeft: '5px'}}>Törlés</Typography>
            </Stack>
        </Button>}
    </Toolbar>
);

interface FormProps {
    isEdit: boolean
}

const FaqForm = (props: FormProps) => {
    const [isOpen, setIsOpen] = useState(false);
    const record = useRecordContext();

    return <SimpleForm toolbar={<CustomToolbar setIsOpen={setIsOpen} isEdit={props.isEdit}/>}>
        <TextInput source="question" label={"Kérdés"} name={"question"} fullWidth/>
        <TextInput source="answer" label={"Válasz"} name={"answer"} fullWidth multiline/>
        {record && <FaqDeleteWarningDialog isOpen={isOpen} setIsOpen={setIsOpen} record={record}/>}
    </SimpleForm>
}

export const FaqCreate = () => (
    <Create title={'Gyakori kérdés létrehozása'}>
        <FaqForm isEdit={false}/>
    </Create>
);

export const FaqEdit = () => (
    <Edit title={'Gyakori kérdés szerkesztése'}>
        <FaqForm isEdit={true}/>
    </Edit>
);
