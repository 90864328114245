import React from "react";
import {Logout, MenuItemLink, useAuthenticated, UserMenu} from "react-admin";
import VpnKeyOutlinedIcon from '@mui/icons-material/VpnKeyOutlined';
import PaletteOutlinedIcon from '@mui/icons-material/PaletteOutlined';

const CustomUserMenu = (props:any) => {
    useAuthenticated();
    return (
        <UserMenu {...props}>
            <MenuItemLink
                to="/password-change"
                primaryText="Jelszó változtatás"
                leftIcon={<VpnKeyOutlinedIcon />}
            />
            <MenuItemLink
                to="/theme-change"
                primaryText="Téma változtatás"
                leftIcon={<PaletteOutlinedIcon />}
            />
            <Logout/>
        </UserMenu>
    );
};

export default CustomUserMenu;
