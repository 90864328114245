import * as React from 'react';
import {useEffect, useState} from 'react';
import {SimpleShowLayout} from "react-admin";
import Box from "@mui/material/Box";
import {Divider, Grid, Typography} from "@mui/material";
import {PersonalScheduleGridRow} from "./personal-schedule-grid-row";
import {apiUrl, httpClient} from "../../../../dataProvider";

interface Props {
    isOpen: boolean;
    employee: any;
}

export const PersonalScheduleGrid = (props: Props) => {
    const [scheduleItems, setScheduleItems]: any = useState();
    useEffect(() => {
        if (props.employee) {
            let sItems: any[] = [];
            let schedule: any[] = [];
            httpClient(`${apiUrl}/schedule?employeeId=${props.employee.id}&page=1&size=10000`, {
                method: 'GET',
            }).then(({json}) => {
                sItems = json.items;
                httpClient(`${apiUrl}/schedule-time-table?employeeId=${props.employee.id}&page=1&size=10000`, {
                    method: 'GET',
                }).then(({json}) => {
                    schedule = json.items
                    const pairedScheduleInfos = sItems.map((si: any) => {
                        const match = schedule.find(sc => sc.id === si.scheduleTimeTableId);
                        if (typeof match !== 'undefined') {
                            return {
                                ...si,
                                establishmentId: match.establishmentId
                            }
                        }
                    })
                    setScheduleItems(pairedScheduleInfos);
                })
            })
        }
    }, [props.employee, props.isOpen])

    return <SimpleShowLayout record={props.employee}>
        <Grid container spacing={0}>
            <Grid item xs={3}>
                <Typography sx={{fontWeight: 700}}>Idő</Typography>
            </Grid>
            <Grid item xs={2}>
                <Typography sx={{fontWeight: 700}}>Projekt</Typography>
            </Grid>
            <Grid item xs={2}>
                <Typography sx={{fontWeight: 700}}>Telephely</Typography>
            </Grid>
            <Grid item xs={2}>
                <Typography sx={{fontWeight: 700}}>Munka</Typography>
            </Grid>
            <Grid item xs={1}>
                <Typography sx={{fontWeight: 700}}>Státusz</Typography>
            </Grid>
        </Grid>
        <Divider sx={{width: '100%', borderBottomWidth: '2px',}}/>
        {
            scheduleItems && scheduleItems.map((it: any) => {
                return <Box>
                    <PersonalScheduleGridRow item={it}/>
                    <Divider sx={{width: '100%', borderBottomWidth: '2px', marginTop: '10px'}}/>
                </Box>
            })
        }
    </SimpleShowLayout>
}
