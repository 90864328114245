import * as React from "react";
import {Create, Edit, SimpleForm, TextInput} from 'react-admin';

const AreaForm = () => {
    return <SimpleForm>
        <TextInput source="name" label={"Név (kötelező)"} name={"name"} required/>
    </SimpleForm>
}

export const AreaCreate = () => (
    <Create title={'Térség létrehozása'} redirect={"/area"}>
        <AreaForm/>
    </Create>
);

export const AreaEdit = () => (
    <Edit title={'Térség szerkesztése'}>
        <AreaForm/>
    </Edit>
);