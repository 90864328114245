import * as React from 'react';
import {
    EditButton,
    FunctionField,
    ImageField, NumberField,
    Show,
    SimpleShowLayout,
    TextField,
    TopToolbar,
    useShowContext
} from 'react-admin';

const OfficeShowActions = () => (
    <TopToolbar>
        <EditButton/>
    </TopToolbar>
);

const OfficeShowLayout = () => {
    const {
        error,
        isLoading,
    } = useShowContext();

    if (isLoading) {
        return <div>Loading...</div>;
    }
    if (error) {
        return <div>Error!</div>;
    }
    return (
        <SimpleShowLayout>
            <TextField source="id" label={"ID"}/>
            <TextField source="name" label={"Név"}/>
            <TextField source="phone" label={"Telefonszám"}/>
            <TextField source="email" label={"E-mail"}/>
            <FunctionField
                label="Cím"
                render={(record: any) => `${record.address.country}, ${record.address.zip}, ${record.address.city}, ${record.address.address}`}
            />
            <TextField source="openingHours" label={"Nyitvatartás"}/>
            <NumberField source="location.latitude" label={"Szélességi fok"}/>
            <NumberField source="location.longitude" label={"Hosszúsági fok"}/>
            <ImageField source={"image"} label={"Kép"}/>
        </SimpleShowLayout>
    );
};

export const OfficeShow = () => {
    return <Show title={'Iroda'} actions={<OfficeShowActions/>}>
        <OfficeShowLayout/>
    </Show>;
};
