import * as React from 'react';
import {useEffect, useState} from 'react';
import {apiUrl, httpClient} from "../dataProvider";
import {Box, Divider, Grid, IconButton, Stack, Tooltip, Typography} from "@mui/material";
import {getEmploymentTypeName} from "../enum-translates";
import AddIcon from '@mui/icons-material/Add';
import {Button, useRefresh} from "react-admin";
import DialogTitle from "@mui/material/DialogTitle";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import {JobForm} from "../job/job-create";
import EditOutlinedIcon from "@mui/icons-material/EditOutlined";
import ArticleOutlinedIcon from '@mui/icons-material/ArticleOutlined';
import {EstablishmentJobApplications} from "./establishment-job-applications";
import {EstablishmentNewJobApplication} from "./establishment-new-job-application";
import {EstablishmentDeleteWarningDialog} from "./establishment-delete-warning-dialog";
import DeleteOutlineOutlinedIcon from "@mui/icons-material/DeleteOutlineOutlined";

interface EstablishmentJobsProps {
    establishmentId: number;
}

export const EstablishmentJobs = (props: EstablishmentJobsProps) => {
    const [jobs, setJobs]: any = useState([]);

    const [editJob, setEditJob]: any = useState(null);
    const [deleteJob, setDeleteJob]: any = useState(null);
    const [editOpen, setEditOpen] = useState(false);
    const [deleteOpen, setDeleteOpen] = useState(false);
    const [applicationsOpen, setApplicationsOpen] = useState(false);
    const [newApplicationOpen, setNewApplicationOpen] = useState(false);

    const [createOpen, setCreateOpen] = useState(false);
    const handleClose = () => {
        setCreateOpen(false);
        setEditOpen(false);
        setDeleteOpen(false);
        setApplicationsOpen(false);
        setNewApplicationOpen(false);
    };

    useEffect(() => {
        httpClient(`${apiUrl}/job?establishmentId=${props.establishmentId}&page=1&size=100`, {
            method: 'GET',
        }).then(({json}) => {
            setJobs(json.items);
        })
    }, [editJob, createOpen, editOpen, applicationsOpen, newApplicationOpen, deleteJob, deleteOpen]);

    const refresh = useRefresh();

    return <Grid container spacing={1}>
        <Grid item xs={12}/>
        <Grid item container xs={12}>
            <Button label="Új munka létrehozása" variant={"contained"}
                    startIcon={<AddIcon style={{fontSize: '20px'}}/>}
                    onClick={() => setCreateOpen(true)}
            />
        </Grid>
        <Grid item xs={12}/>
        <Grid item xs={0.5}>
            <Typography fontSize={14} fontWeight={600}>ID</Typography>
        </Grid>
        <Grid item xs={2}>
            <Typography fontSize={14} fontWeight={600}>Munka megnevezése</Typography>
        </Grid>
        <Grid item xs={1.5}>
            <Typography fontSize={14} fontWeight={600}>Típus</Typography>
        </Grid>
        <Grid item xs={4}>
            <Typography fontSize={14} fontWeight={600}>Munkavállalók</Typography>
        </Grid>
        <Grid item xs={1}>
            <Typography fontSize={14} fontWeight={600}>Szerkesztés</Typography>
        </Grid>
        <Grid item xs={1}>
            <Typography fontSize={14} fontWeight={600}>Jelentkezők</Typography>
        </Grid>
        <Grid item xs={1}>
            <Typography fontSize={14} fontWeight={600}>Új jelentkezés</Typography>
        </Grid>
        <Grid item xs={1}>
            <Typography fontSize={14} fontWeight={600}>Törlés</Typography>
        </Grid>
        <Grid item xs={12}>
            <Divider sx={{width: '100%', borderBottomWidth: '3px'}}/>
        </Grid>
        {
            jobs.map((job: any, index: number) => {
                return <Grid item container xs={12} key={job.id}>
                    <Grid item xs={0.5} sx={{display: 'flex', alignItems: 'center'}}>
                        {job.id}
                    </Grid>
                    <Grid item xs={2} sx={{display: 'flex', alignItems: 'center'}}>
                        {job.name}
                    </Grid>
                    <Grid item xs={1.5} sx={{display: 'flex', alignItems: 'center'}}>
                        {getEmploymentTypeName(job.employmentType)}
                    </Grid>
                    <Grid item xs={4} sx={{display: 'flex', alignItems: 'center'}}>
                        <Stack direction={"row"}>
                            <Box>szükséges: <span style={{
                                fontSize: '16px',
                                fontWeight: 700
                            }}>{job.numberOfEmployeesNeeded}</span></Box>
                            <Typography sx={{margin: '0px 10px'}}> / </Typography>
                            <Box>jelentkezett: <span style={{
                                fontSize: '16px',
                                fontWeight: 700
                            }}>{job.numberOfApplications ?? 0}</span></Box>
                            <Typography sx={{margin: '0px 10px'}}> / </Typography>
                            <Box sx={{
                                color: job.numberOfAcceptedApplications ? job.numberOfAcceptedApplications >= job.numberOfEmployeesNeeded ? '#66bb6a' : job.numberOfAcceptedApplications === 0 ? '#f44336' : '#ffa726' : '#f44336'
                            }}>
                                megerősítve: <span style={{
                                fontSize: '16px',
                                fontWeight: 700
                            }}>{job.numberOfAcceptedApplications ?? 0}</span>
                            </Box>
                        </Stack>
                    </Grid>
                    <Grid item xs={1} sx={{display: 'flex', alignItems: 'center', justifyContent: 'center'}}>
                        <IconButton color={'primary'}
                                    onClick={() => {
                                        setEditJob(job);
                                        setEditOpen(true);
                                    }}
                                    sx={{margin: 'auto'}}
                        >
                            <EditOutlinedIcon sx={{fontSize: 24}}/>
                        </IconButton>
                    </Grid>
                    <Grid item xs={1} sx={{display: 'flex', alignItems: 'center', justifyContent: 'center'}}>
                        <IconButton color={'primary'}
                                    onClick={() => {
                                        setEditJob(job);
                                        setApplicationsOpen(true);
                                    }}
                                    sx={{margin: 'auto'}}
                        >
                            <ArticleOutlinedIcon sx={{fontSize: 24}}/>
                        </IconButton>
                    </Grid>
                    <Grid item xs={1} sx={{display: 'flex', alignItems: 'center', justifyContent: 'center'}}>
                        <IconButton color={'primary'}
                                    onClick={() => {
                                        setEditJob(job);
                                        setNewApplicationOpen(true);
                                    }}
                                    sx={{margin: 'auto'}}
                        >
                            <AddIcon sx={{fontSize: 24}}/>
                        </IconButton>
                    </Grid>
                    <Grid item xs={1} sx={{display: 'flex', alignItems: 'center',}}>
                        <Tooltip title={job.numberOfApplications > 0 ? 'A munka nem törölhető, mivel jelentkezések tartoznak hozzá' : ''}>
                            <Box>
                                <IconButton color={'error'}
                                        onClick={() => {
                                            setDeleteJob(job);
                                            setDeleteOpen(true);
                                        }}
                                        disabled={job.numberOfApplications > 0}
                            >
                                <DeleteOutlineOutlinedIcon sx={{fontSize: 24}}/>
                            </IconButton>
                            </Box>
                        </Tooltip>
                    </Grid>
                    <Grid item xs={12}>
                        <Divider sx={{width: '100%', paddingTop: '12px'}}/>
                    </Grid>
                </Grid>
            })
        }
        <Dialog open={createOpen} onClose={handleClose} fullWidth
                maxWidth="md">
            <DialogTitle>Új munka létrehozása</DialogTitle>
            <DialogContent>
                <JobForm establishmentId={props.establishmentId}
                         onSuccess={() => {
                             setCreateOpen(false);
                             refresh();
                         }}
                         isEdit={false}
                         record={undefined}/>
            </DialogContent>
        </Dialog>
        <Dialog open={editOpen} onClose={handleClose} fullWidth
                maxWidth="md">
            <DialogTitle>Munka szerkesztése</DialogTitle>
            <DialogContent>
                <JobForm establishmentId={props.establishmentId}
                         onSuccess={() => {
                             setEditOpen(false);
                             setEditJob(null);
                             refresh();
                         }}
                         record={editJob}
                         isEdit/>
            </DialogContent>
        </Dialog>
        <Dialog open={applicationsOpen} onClose={handleClose} fullWidth
                maxWidth="md">
            <DialogTitle>Munkára jelentkezők</DialogTitle>
            <DialogContent>
                <EstablishmentJobApplications jobId={editJob ? editJob.id : -1}/>
            </DialogContent>
        </Dialog>
        <Dialog open={newApplicationOpen} onClose={handleClose} fullWidth
                maxWidth="md">
            <DialogTitle>Új munkára jelentkezés</DialogTitle>
            <DialogContent>
                <EstablishmentNewJobApplication jobId={editJob ? editJob.id : -1}/>
            </DialogContent>
        </Dialog>
        <EstablishmentDeleteWarningDialog isOpen={deleteOpen}
                                          setIsOpen={setDeleteOpen}
                                          record={deleteJob}
                                          setRecord={setDeleteJob}
                                          deleteTitle={"Munka törlése"}
                                          deleteText={"Biztosan törölni szeretnéd ezt a munkát:"}
                                          endpoint={"job"}
        />
    </Grid>
};
