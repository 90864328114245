import * as React from 'react';
import {useState} from 'react';
import {useTranslate} from 'ra-core';
import {useTiptapEditor} from 'ra-input-rich-text';
import {Button, Dialog, ToggleButton} from '@mui/material';
import AddCircleOutlineOutlinedIcon from "@mui/icons-material/AddCircleOutlineOutlined";
import {apiUrl, httpClient} from "../dataProvider";
import {FormDataConsumer, ImageField, ImageInput, SimpleForm, Create, useNotify} from 'react-admin';
import ImageIcon from '@mui/icons-material/Image';

const convertFileToBase64 = (file:any) =>
    new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.onload = () => resolve(reader.result);
        reader.onerror = reject;

        reader.readAsDataURL(file.rawFile);
    });

export const MyImageButton = (props: any) => {
    const [open, setOpen] = useState(false);
    const notify = useNotify();
    const translate = useTranslate();
    const editor = useTiptapEditor();

    const label = translate('ra.tiptap.image', {_: 'Image'});

    return <>
        <ToggleButton
            aria-label={label}
            title={label}
            {...props}
            disabled={!editor?.isEditable}
            value="image"
            onClick={() => setOpen(true)}
        >
            <ImageIcon fontSize="inherit"/>
        </ToggleButton>
        <Dialog open={open} onClose={() => setOpen(false)}>
            <Create sx={{minWidth: '500px'}}>
                <SimpleForm toolbar={<></>}>
                    <ImageInput source="data" label="Tartalom kép (kötelező)" accept="image/*" placeholder={<p>Kép megadása</p>}
                                name={"data"}
                                isRequired>
                        <ImageField source="src" title="title"/>
                    </ImageInput>
                    <FormDataConsumer>
                        {({formData, ...rest}) =>
                            <Button variant={'contained'}
                                    startIcon={<AddCircleOutlineOutlinedIcon/>}
                                    sx={{width: '100%'}}
                                    onClick={async () => {
                                        const img = await convertFileToBase64(formData.data);
                                        await httpClient(`${apiUrl}/upload-picture`, {
                                            method: 'POST',
                                            body: JSON.stringify({
                                                data: img
                                            }),
                                        }).then(r => {
                                            if (r.status === 200) {
                                                setOpen(false);
                                                editor.chain().focus().setImage({src: r.json.url}).run();
                                            } else {
                                                notify(`Hiba történt az kép létrehozásakor`, {type: 'error'});
                                            }
                                        })
                                    }}>
                                Hozzáadás
                            </Button>}
                    </FormDataConsumer>
                </SimpleForm>
            </Create>
        </Dialog>
    </>
};