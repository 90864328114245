import * as React from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import {
    ArrayInput,
    AutocompleteInput,
    DateInput,
    FormDataConsumer,
    NumberInput,
    ReferenceInput,
    SimpleForm,
    SimpleFormIterator,
    useNotify
} from "react-admin";
import AddCircleOutlineOutlinedIcon from "@mui/icons-material/AddCircleOutlineOutlined";
import Box from "@mui/material/Box";
import {apiUrl, httpClient} from "../../../dataProvider";

interface Props {
    isOpen: boolean;
    setIsOpen: any;
    timeTableRefresh: boolean
    setTimeTableRefresh: any
    establishmentId: number
}

const renderOption = (data: any) => {
    if (data) {
        return `${data.name} (ID: ${data.id})`
    }
    return '';
};

const inputText = (record: any) => `${record.name} (ID: ${record.id})`;


export const ScheduleDayDialog = (props: Props) => {
    const handleClose = () => {
        props.setIsOpen(false);
    };

    const notify = useNotify();

    const filterToQuery = (searchText:string) => ({ query: `%${searchText}%` });

    return <Dialog open={props.isOpen} onClose={handleClose} fullWidth
                   maxWidth="sm">
        <DialogTitle>Új beosztási nap</DialogTitle>
        <DialogContent>
            <SimpleForm toolbar={<></>}>
                <DateInput source="date" label={"Dátum (kötelező)"} name={"date"} fullWidth
                           required={true}
                           inputProps={{
                       max: '2099-12-31'
                   }}/>
                <ArrayInput source="requiredStuff" label={'Szükséges beosztottak'}
                            sx={{
                                '& .RaSimpleFormIterator-action': {
                                    paddingLeft: '15px'
                                },
                                '& .RaSimpleFormIterator-form': {
                                    marginTop: '12px',
                                    "& div": {
                                        marginBottom: '5px',
                                    }
                                },
                                '& .RaSimpleFormIterator-indexContainer': {
                                    display: 'none'
                                }
                            }}>
                    <SimpleFormIterator disableReordering>
                        <NumberInput fullWidth source="requiredStuffNumber" helperText={false}
                                     label={'Darab (kötelező)'} required/>
                        <ReferenceInput source="jobId"
                                        reference="job"
                                        name={"jobId"}
                                        filter={{establishmentId: props.establishmentId}}
                                        helperText={false}
                        >
                            <AutocompleteInput source="name"
                                               label={"Munka (kötelező)"}
                                               size={"small"}
                                               fullWidth
                                               isRequired
                                               inputText={inputText}
                                               optionText={renderOption}
                                               optionValue={"id"}
                                               helperText={false}
                                               filterToQuery={filterToQuery}
                            />
                        </ReferenceInput>
                    </SimpleFormIterator>
                </ArrayInput>
                <Box sx={{height: '30px'}}/>
                <FormDataConsumer>
                    {({formData, ...rest}) =>
                        <Button variant={'contained'}
                                startIcon={<AddCircleOutlineOutlinedIcon/>}
                                sx={{width: '100%', marginTop: '-10px'}}
                                onClick={async () => {
                                    await httpClient(`${apiUrl}/schedule-time-table`, {
                                        method: 'POST',
                                        body: JSON.stringify({
                                            establishmentId: props.establishmentId,
                                            requiredStuff: formData.requiredStuff,
                                            date: formData.date
                                        }),
                                    }).then((r:any) => {
                                        if (r.status === 201) {
                                            props.setTimeTableRefresh(!props.timeTableRefresh);
                                            notify(`Beosztási nap létrehozva`, {type: 'success'});
                                            props.setIsOpen(false)
                                        } else {
                                            notify(`Hiba történt a beosztási nap létrehozásakor`, {type: 'error'});
                                        }
                                    })
                                }}>
                            Hozzáadás
                        </Button>
                    }
                </FormDataConsumer>
            </SimpleForm>
        </DialogContent>
    </Dialog>;
}
