import * as React from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import {FileField, FileInput, FormDataConsumer, SimpleForm, useNotify, useRefresh} from "react-admin";
import AddCircleOutlineOutlinedIcon from "@mui/icons-material/AddCircleOutlineOutlined";
import Box from "@mui/material/Box";
import {apiUrl, httpClient} from "../dataProvider";
import {Stack} from "@mui/material";
import FileDownloadOutlinedIcon from "@mui/icons-material/FileDownloadOutlined";
import {exportExcel} from "../export-excel";
import XLSX from "sheetjs-style";
import {dateTimeFormatter} from "../date-formatter/date-formatter";
import {getJsDateFromExcel} from 'excel-date-to-js';


interface Props {
    isOpen: boolean;
    setIsOpen: any;
}

export async function loadExcel(file: any): Promise<any> {
    return new Promise<any>((resolve, reject) => {
        const reader = new FileReader();
        const rABS = !!reader.readAsBinaryString;

        reader.onload = (e) => {
            // @ts-ignore
            const bstr = e.target.result;
            const wb = XLSX.read(bstr, { type: rABS ? 'binary' : 'array', bookVBA: true });
            const wsname = wb.SheetNames[0];
            const ws = wb.Sheets[wsname];
            const data = XLSX.utils.sheet_to_json(ws);
            resolve(data);
        };

        reader.onerror = (e) => {
            reject(e);
        };

        if (rABS) {
            reader.readAsBinaryString(file);
        } else {
            reader.readAsArrayBuffer(file);
        }
    });
}

const getAccountOwner = (owner:any) => {
    if(owner === '' || owner === null || typeof owner === 'undefined') {
        return null;
    }
    switch (owner) {
        case "Más tulajdonos":
            return "SomebodyElse"
        case "Saját":
            return "Own"
        default:
            return null;
    }
}

const getGender = (gender:any) => {
    if(gender === '' || gender === null || typeof gender === 'undefined') {
        return null;
    }
    switch (gender) {
        case "Férfi":
            return "Male"
        case "Nő":
            return "Female"
        default:
            return null;
    }
}

const getType = (type:any) => {
    if(type === '' || type === null || typeof type === 'undefined') {
        return null;
    }
    switch (type) {
        case "Felnőtt":
            return "Adult"
        case "Diák":
            return "Student"
        case "Nyugdíjas":
            return "Retired"
        case "Deaktivált":
            return "Disabled"
        default:
            return null;
    }
}


export const BulkEmployeeDialog = (props: Props) => {
    const handleClose = () => {
        props.setIsOpen(false);
    };

    const notify = useNotify();
    const excelValues = [{
        'Név (kötelező)': 'PÉLDA BALÁZS (EZT A SORT TÖRÖLJÜK KI)',
        'E-mail (kötelező)': 'pelda.balazs@gmail.com',
        'Telefonszám': '+36305558877',
        'Állandó lakcím ország': 'Magyarország',
        'Állandó lakcím ir.szám': '1111',
        'Állandó lakcím város': 'Budapest',
        'Állandó lakcím utca/házszám': 'Proba ter 11',
        'Postázási cím ország': 'Magyarország',
        'Postázási cím ir.szám': '1111',
        'Postázási cím város': 'Budapest',
        'Postázási cím utca/házszám': 'Test korut 23',
        'Születési hely': 'Békéscsaba',
        'Születési idő': '',
        'Anyja neve': 'Próba Patrícia',
        'Nem': 'Férfi/Nő',
        'Adóazonosító': '12345678-1-21',
        'TAJ szám': '123456789',
        'Típus': 'Felnőtt/Diák/Nyugdíjas/Deaktivált',
        'Megjegyzés': 'Ez egy megjegyzes a munkavállalóról',
        'Bankszámla tulajdonos': 'Saját/Más tulajdonos',
        'Bankszámla szám': '12345678-87654321',
    }];

    const refresh = useRefresh();

    return <Dialog open={props.isOpen} onClose={handleClose} fullWidth
                   maxWidth="sm">
        <DialogTitle><Stack direction={"row"} alignItems={"center"} justifyContent={"space-between"}>
            <Box>Munkavállalók feltöltése</Box>
            <Button variant={'contained'}
                    startIcon={<FileDownloadOutlinedIcon/>}
                    sx={{width: '200px', height: '35px'}}
                    onClick={async () => {
                        exportExcel({
                            excelData: excelValues,
                            fileName: `munkavallalok-minta`
                        }).then(r => {
                        });
                    }}>
                Minta letöltése
            </Button>
        </Stack></DialogTitle>
        <DialogContent>
            <SimpleForm toolbar={<></>}>
                <FileInput source="excelFile"
                           label="Fájl (kötelező)"
                           accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
                           isRequired>
                    <FileField source="src" title="title"/>
                </FileInput>
                <Box sx={{height: '10px'}}/>
                <FormDataConsumer>
                    {({formData, ...rest}) =>
                        <Button variant={'contained'}
                                startIcon={<AddCircleOutlineOutlinedIcon/>}
                                sx={{width: '100%', marginTop: '-10px'}}
                                onClick={async () => {
                                    loadExcel(formData.excelFile.rawFile)
                                        .then(async data => {
                                            const formattedData = data.map((d: any) => {
                                                const birthdate = d['Születési idő'] ? dateTimeFormatter(getJsDateFromExcel(d['Születési idő'])).substring(0,10) : null;
                                                const type = getType(d['Típus']);
                                                const gender = getGender(d['Nem']);
                                                const bankAccountOwner = getAccountOwner(d['Bankszámla tulajdonos']);
                                                return {
                                                    name: d['Név (kötelező)'],
                                                    type: type,
                                                    motherName: d['Anyja neve'] ?? '',
                                                    email: d['E-mail (kötelező)'],
                                                    phone: (d['Telefonszám'] ?? '').toString(),
                                                    gender: gender,
                                                    birthLocation: d['Születési hely'] ?? '',
                                                    birthDate: birthdate,
                                                    socialSecurityNumber: (d['TAJ szám'] ?? '').toString(),
                                                    bankAccountOwner: bankAccountOwner,
                                                    bankAccountNumber: (d['Bankszámla szám'] ?? '').toString(),
                                                    homeAddress: {
                                                        country: d['Állandó lakcím ország'] ?? '',
                                                        zip: d['Állandó lakcím ir.szám'] ?? '',
                                                        city: d['Állandó lakcím város'] ?? '',
                                                        address: d['Állandó lakcím utca/házszám'] ?? '',
                                                    },
                                                    mailingAddress: {
                                                        country: d['Postázási cím ország'] ?? '',
                                                        zip: d['Postázási cím ir.szám'] ?? '',
                                                        city: d['Postázási cím város'] ?? '',
                                                        address: d['Postázási cím utca/házszám'] ?? '',
                                                    },
                                                    note: d['Megjegyzés'] ?? '',
                                                    taxId: (d['Adóazonosító'] ?? '').toString(),
                                                }
                                            });
                                            await httpClient(`${apiUrl}/employee/bulk`, {
                                                method: 'POST',
                                                body: JSON.stringify(formattedData),
                                            }).then(r => {
                                                if (r.status === 200) {
                                                    notify(`Munkavállalók felvéve`, {type: 'success'});
                                                    props.setIsOpen(false);
                                                    refresh();
                                                } else {
                                                    notify(`Hiba történt a munkavállalók létrehozásakor`, {type: 'error'});
                                                }
                                            })
                                        })
                                        .catch();
                                }}>
                            Feltöltés
                        </Button>
                    }
                </FormDataConsumer>
            </SimpleForm>
        </DialogContent>
    </Dialog>;
}