import {
    AutocompleteInput,
    DateTimeInput,
    FormDataConsumer,
    ReferenceInput,
    SelectInput,
    SimpleForm,
    TextInput,
    useGetOne,
    useNotify
} from "react-admin";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import {dateParser, dateTimeFormatter} from "../../../date-formatter/date-formatter";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import AddCircleOutlineOutlinedIcon from "@mui/icons-material/AddCircleOutlineOutlined";
import FileDownloadOutlinedIcon from "@mui/icons-material/FileDownloadOutlined";
import {apiUrl, httpClient} from "../../../dataProvider";
import * as React from "react";
import {jobInputText, jobRenderOption, WorkerDialogProps} from "./schedule-worker-dialog";
import {EmployeeInput} from "./employee-input";

export const ScheduleWorkerEditDialog = (props: WorkerDialogProps) => {
    const handleClose = () => {
        props.setIsOpen(false);
        props.setRecord(null);
    };

    const notify = useNotify();

    let defaultDate = `${props.scheduleTimeTable.date}T00:00`

    //  const { referenceRecord: application, isLoading, error } = useReference({ reference: 'job-application', id: props.record.jobApplicationId});

    const {data: application, isLoading, error} = useGetOne('job-application', {id: props.record.jobApplicationId});
    const filterToQuery = (searchText: string) => ({query: `%${searchText}%`});

    if (isLoading) {
        return <Box/>;
    }
    if (error) {
        return <p>ERROR</p>;
    }
    return <Dialog open={props.isOpen} onClose={handleClose} fullWidth
                   maxWidth="sm">
        <DialogTitle>Munkavállaló beosztása</DialogTitle>
        <DialogContent>
            <SimpleForm toolbar={<></>} record={{...props.record, jobId: application.jobId}}>
                <DateTimeInput source="start" label={"Kezdés (kötelező)"} name={"start"}
                               fullWidth required parse={dateParser} defaultValue={defaultDate}
                               format={dateTimeFormatter}
                               inputProps={{
                                   max: '2099-12-31T23:59'
                               }}
                />
                <DateTimeInput source="end" label={"Befejezés (kötelező)"} name={"end"}
                               fullWidth required parse={dateParser} defaultValue={defaultDate}
                               format={dateTimeFormatter}
                               inputProps={{
                                   max: '2099-12-31T23:59'
                               }}
                />
                <ReferenceInput source="jobId"
                                reference="job"
                                name={"jobId"}
                                filter={{establishmentId: props.establishmentId, ids: props.jobIds}}
                >
                    <AutocompleteInput source="name"
                                       label={"Munka (kötelező)"}
                                       size={"small"}
                                       fullWidth
                                       isRequired
                                       inputText={jobInputText}
                                       optionText={jobRenderOption}
                                       optionValue={"id"}
                                       filterToQuery={filterToQuery}
                    />
                </ReferenceInput>
                <FormDataConsumer>
                    {({formData, ...rest}) => {
                        return <Box sx={{width: '100%'}}>
                            {formData.jobId !== "" && <Box>
                                <EmployeeInput jobId={formData.jobId}/>
                                <SelectInput source="status" label={"Státusz"}
                                             fullWidth
                                             name={"status"}
                                             size={"small"}
                                             defaultValue={'InProgress'}
                                             choices={[
                                                 {id: 'Completed', name: 'Megjelent'},
                                                 {id: 'InProgress', name: 'Folyamatban'},
                                                 {id: 'NotCompleted', name: 'Nem jelent meg'},
                                             ]}/>
                                <TextInput source="insideMessage" label={"Megjegyzés"}
                                           name={"insideMessage"} fullWidth/>
                            </Box>
                            }
                        </Box>
                    }
                    }
                </FormDataConsumer>
                <FormDataConsumer>
                    {({formData, ...rest}) =>
                        <Button variant={'contained'}
                                startIcon={props.record === null ? <AddCircleOutlineOutlinedIcon/> :
                                    <FileDownloadOutlinedIcon/>}
                                sx={{width: '100%'}}
                                onClick={async () => {
                                    await httpClient(`${apiUrl}/schedule/${props.record.id}`, {
                                        method: 'PUT',
                                        body: JSON.stringify({
                                            start: formData.start,
                                            end: formData.end,
                                            status: formData.status,
                                            scheduleTimeTableId: props.scheduleTimeTable.id,
                                            jobApplicationId: formData.jobApplicationId,
                                            insideMessage: formData.insideMessage
                                        }),
                                    }).then(r => {
                                        if (r.status === 200) {
                                            props.setIsOpen(false);
                                            props.setRefetch(!props.refetch);
                                            notify(`Beosztás módosítva`, {type: 'success'});
                                        } else {
                                            notify(`Hiba történt a beosztás módosításakor`, {type: 'error'});
                                        }
                                    }).catch(e => {
                                        notify(e.message, {type: 'error'});
                                    })
                                }}>
                            {props.record === null ? 'Hozzáadás' : 'Mentés'}
                        </Button>}
                </FormDataConsumer>
            </SimpleForm>
        </DialogContent>
    </Dialog>;
}
