import * as React from "react";
import {AutocompleteInput, Create, ReferenceInput, SimpleForm} from 'react-admin';

export const renderOption = (data: any) => {
    if (data) {
        return `${data.name} (ID: ${data.id})`
    }
    return '';
};

export const inputText = (record: any) => `${record.name} (ID: ${record.id})`;

export const renderEmployeeOption = (data: any) => {
    if (data) {
        return `${data.name} (${data.email})`
    }
    return '';
};

export const inputEmployeeText = (record: any) => `${record.name} (${record.email})`;

const filterToQuery = (searchText:string) => ({ query: `%${searchText}%` });

const JobApplicationForm = () => (
    <SimpleForm>
        <ReferenceInput source="jobId"
                        reference="job"
                        name={"jobId"}>
            <AutocompleteInput source="name"
                               label={"Munka (kötelező)"}
                               size={"small"}
                               fullWidth
                               isRequired
                               inputText={inputText}
                               optionText={renderOption}
                               optionValue={"id"}
                               filterToQuery={filterToQuery}
            />
        </ReferenceInput>
        <ReferenceInput source="employeeId"
                        reference="employee"
                        name={"employeeId"}>
            <AutocompleteInput source="name"
                               optionValue={"id"}
                               label={"Munkavállaló (kötelező)"}
                               size={"small"}
                               fullWidth
                               isRequired
                               inputText={inputEmployeeText}
                               optionText={renderEmployeeOption}
                               filterToQuery={filterToQuery}
            />
        </ReferenceInput>
    </SimpleForm>
)

export const JobApplicationCreate = () => (
    <Create title={'Jelentkezés létrehozása'}>
        <JobApplicationForm/>
    </Create>
);
