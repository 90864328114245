import * as React from "react";
import {
    BooleanInput,
    CreateButton,
    Datagrid,
    ExportButton,
    FilterButton,
    FilterForm,
    FunctionField,
    List,
    ReferenceField,
    SelectInput,
    TextField,
    TextInput,
    TopToolbar,
    useListContext,
    usePermissions,
} from 'react-admin';
import {Box, Stack, Typography} from "@mui/material";
import {getEmploymentTypeName} from "../enum-translates";
import {apiUrl, httpClient} from "../dataProvider";
import {canAccess} from "../layout/custom-menu";
import {exportExcel} from "../export-excel";

const exporter = (values: any) => {
    const currentDate = new Date();
    const excelValues = values.map((v:any,index:number) => {
        return {
            'ID': v.id,
            'Munka megnevezése': v.name,
            'Térség': v.area,
            'Munkáltató (ID)': v.employerId,
            'Ország': v.address.country,
            'Ir.szám': v.address.zip,
            'Város': v.address.city,
            'Cím': v.address.address,
            'Kezdés': v.startsAt,
            'Befejezés': v.endsAt,
            'Munkavégzés ideje': v.durationDescription,
            'Típus': v.employmentType,
            'Kép': v.image,
            'Aktív': v.isActive,
            'Kiemelés mértéke': v.highlightOrder,
            'Munkavállaló típusok': v.employeeTypes,
            'Kategóriák': v.categories,
            'Elvárt tudások': v.requiredSkills,
            'Szükséges mukavállalók': v.numberOfEmployeesNeeded,
            'Jelentkezett mukavállalók': v.numberOfApplications,
            'Megerősített mukavállalók': v.numberOfAcceptedApplications,
            'Várható fizetés': v.expectedSalary,
            'Leírás': v.description,
            'Belső megjegyzés': v.internalNote,
            'Felelős admin (ID)': v.administratorInCharge,
            'Jelenléti ív ID': v.attendanceSheetId,
            'Kapcsolat megjegyzés': v.contactNote,
            'Kapcsolattartók (ID)': v.contactPeople,
        }
    });
    exportExcel({
        excelData: excelValues,
        fileName: `munkak-${currentDate.getFullYear()}_${currentDate.getMonth()+1}_${currentDate.getDate()}`
    }).then(r => {});
};

const jobFilters = [
    <TextInput source="query" label={"Kulcsszó"} name={"query"} fullWidth required/>,
    <SelectInput source="employmentType" label={"Típus"}
                 name={"employmentType"}
                 required
                 choices={[
                     {id: 'Occasional', name: 'Alkalmi'},
                     {id: 'PartTime', name: 'Részmunkaidő'},
                     {id: 'FullTime', name: 'Teljes munkaidő'},
                 ]}/>,
    <BooleanInput source="isActive" label={"Aktív"}
                  name={"isActive"}
                  fullWidth
                  defaultValue={true}
    />
];

const CustomToolbar = () => {
    const {filterValues} = useListContext();
    let querystring = '';
    const keys = Object.keys(filterValues);
    keys.forEach((k: any) => {
        querystring += `&${k}=${filterValues[k]}`
    })
    const {permissions} = usePermissions();
    return <TopToolbar sx={{width: '100%', paddingTop: '20px'}}>
        <Stack direction={"column"} sx={{width: '100%'}}>
            <Stack direction="row"
                   justifyContent="flex-end"
                   alignItems={'center'}
                   sx={{width: '100%', paddingBottom: '5px'}}>
                <FilterForm filters={jobFilters}/>
                {canAccess('Super', permissions) && <ExportButton onClick={() => {
                    httpClient(`${apiUrl}/job?export=true&page=1&size=1${querystring}`, {
                        method: 'GET',
                    }).then(({json}) => {
                        exporter(json.items)
                    })}
                } sx={{marginRight: '20px'}}/>
                }
                <div>
                    <FilterButton filters={jobFilters}/>
                </div>
                <CreateButton/>
            </Stack>
        </Stack>
    </TopToolbar>
}

export const JobList = () => (
    <List title={'Munkák'} exporter={false}
          actions={<CustomToolbar/>}
    >
        <Datagrid rowClick={'show'} bulkActionButtons={false}>
            <TextField source="id" label={"ID"}/>
            <ReferenceField source="employerId"
                            reference="employer"
                            label={"Projekt"}>
                <TextField source="name"/>
            </ReferenceField>
            <TextField source="name" label={"Munka megnevezése"}/>
            <FunctionField
                textAlign={"right"}
                label="Típus"
                render={(record: any) => `${getEmploymentTypeName(record.employmentType)}`}
            />
            <FunctionField
                label="Munkavállalók"
                render={(record: any) => {
                    return <Stack direction={"row"}>
                        <Box>szükséges: <span style={{
                            fontSize: '16px',
                            fontWeight: 700
                        }}>{record.numberOfEmployeesNeeded}</span></Box>
                        <Typography sx={{margin: '0px 10px'}}> / </Typography>
                        <Box>jelentkezett: <span style={{
                            fontSize: '16px',
                            fontWeight: 700
                        }}>{record.numberOfApplications ?? 0}</span></Box>
                        <Typography sx={{margin: '0px 10px'}}> / </Typography>
                        <Box sx={{
                            color: record.numberOfAcceptedApplications ? record.numberOfAcceptedApplications >= record.numberOfEmployeesNeeded ? '#66bb6a' : record.numberOfAcceptedApplications === 0 ? '#f44336' : '#ffa726' : '#f44336'
                        }}>
                            megerősítve: <span style={{
                            fontSize: '16px',
                            fontWeight: 700
                        }}>{record.numberOfAcceptedApplications ?? 0}</span>
                        </Box>
                    </Stack>
                }}
            />
        </Datagrid>
    </List>
);