import * as React from "react";
import {AutocompleteInput, Create, DateTimeInput, ReferenceInput, SimpleForm, TextInput} from 'react-admin';
import {Grid, Typography} from "@mui/material";

const filterToQuery = (searchText: string) => ({query: `%${searchText}%`});

const EmployerForm = () => (
    <SimpleForm>
        <Grid container item xs={12} spacing={2}>
            <Grid item xs={6}>
                <TextInput source="name" label={"Név (kötelező)"} name={"name"} fullWidth required/>
            </Grid>
            <Grid item xs={6}>
                <TextInput source="taxNumber" label={"Adószám (kötelező)"} name={"taxNumber"} fullWidth required/>
            </Grid>
        </Grid>
        <Grid container item xs={12} spacing={2}>
            <Grid item xs={6}>
                <ReferenceInput source="importedBy" reference="administrator" name={"importedBy"}>
                    <AutocompleteInput label={"Behozta (kötelező)"} source={"name"} size={"small"} isRequired
                                       filterToQuery={filterToQuery}/>
                </ReferenceInput>
            </Grid>
            <Grid item xs={6}>
                <DateTimeInput source="importedAt" label={"Behozás ideje (kötelező)"} name={"importedAt"} fullWidth
                               required
                               inputProps={{
                                   max: '2099-12-31T23:59'
                               }}/>
            </Grid>
        </Grid>
        <Grid container spacing={1}>
            <Grid item xs={12}>
                <Typography fontSize={'14px'}>Székhely</Typography>
            </Grid>
            <Grid item xs={2}>
                <TextInput source="headquarters.country" label={"Ország (kötelező)"}
                           name={"headquarters.country"} fullWidth required defaultValue={"Magyarország"}/>
            </Grid>
            <Grid item xs={2}>
                <TextInput source="headquarters.zip" label={"Ir.szám (kötelező)"} name={"headquarters.zip"}
                           fullWidth required/>
            </Grid>
            <Grid item xs={4}>
                <TextInput source="headquarters.city" label={"Város (kötelező)"} name={"headquarters.city"}
                           fullWidth required/>
            </Grid>
            <Grid item xs={4}>
                <TextInput source="headquarters.address" label={"Cím (kötelező)"}
                           name={"headquarters.address"} fullWidth required/>
            </Grid>
        </Grid>
        <TextInput source="note" label={"Megjegyzés"} name={"note"} fullWidth/>
    </SimpleForm>
)

export const EmployerCreate = () => (
    <Create title={'Projekt létrehozása'}>
        <EmployerForm/>
    </Create>
);
