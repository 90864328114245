import * as React from 'react';
import {useEffect, useState} from 'react';
import {apiUrl, httpClient} from "../dataProvider";
import {Divider, Grid, IconButton, Typography} from "@mui/material";
import AddIcon from '@mui/icons-material/Add';
import {Button, useRefresh} from "react-admin";
import DialogTitle from "@mui/material/DialogTitle";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import EditOutlinedIcon from "@mui/icons-material/EditOutlined";
import DeleteOutlineOutlinedIcon from '@mui/icons-material/DeleteOutlineOutlined';
import {ContactPersonForm} from "../contact-person/contact-person-create-and-edit";
import {EstablishmentDeleteWarningDialog} from "./establishment-delete-warning-dialog";

interface EstablishmentJobsProps {
    establishmentId: number;
}

export const EstablishmentContacts = (props: EstablishmentJobsProps) => {
    const [contacts, setContacts]: any = useState([]);

    const [editContact, setEditContact]: any = useState(null);
    const [deleteContact, setDeleteContact]: any = useState(null);
    const [editOpen, setEditOpen] = useState(false);
    const [deleteOpen, setDeleteOpen] = useState(false);

    const [createOpen, setCreateOpen] = useState(false);
    const handleClose = () => {
        setCreateOpen(false);
        setEditOpen(false);
    };

    useEffect(() => {
        httpClient(`${apiUrl}/employer-contact-person?establishmentId=${props.establishmentId}&page=1&size=100`, {
            method: 'GET',
        }).then(({json}) => {
            setContacts(json.items);
        })
    }, [editContact, createOpen, editOpen, deleteContact, deleteOpen]);

    const refresh = useRefresh();

    return <Grid container spacing={1}>
        <Grid item xs={12}/>
        <Grid item container xs={12}>
            <Button label="Új kapcsolattartó felvétele" variant={"contained"}
                    startIcon={<AddIcon style={{fontSize: '20px'}}/>}
                    onClick={() => setCreateOpen(true)}
            />
        </Grid>
        <Grid item xs={12}/>
        <Grid item xs={2}>
            <Typography fontSize={14} fontWeight={600}>Név</Typography>
        </Grid>
        <Grid item xs={3}>
            <Typography fontSize={14} fontWeight={600}>Email</Typography>
        </Grid>
        <Grid item xs={3}>
            <Typography fontSize={14} fontWeight={600}>Telefonszám</Typography>
        </Grid>
        <Grid item xs={2}>
            <Typography fontSize={14} fontWeight={600}>Pozíció</Typography>
        </Grid>
        <Grid item xs={1}>
            <Typography fontSize={14} fontWeight={600}>Szerkesztés</Typography>
        </Grid>
        <Grid item xs={1}>
            <Typography fontSize={14} fontWeight={600}>Törlés</Typography>
        </Grid>
        <Grid item xs={12}>
            <Divider sx={{width: '100%', borderBottomWidth: '3px'}}/>
        </Grid>
        {
            contacts.map((contact: any, index: number) => {
                return <Grid item container xs={12} key={contact.id}>
                    <Grid item xs={2} sx={{display: 'flex', alignItems: 'center'}}>
                        {contact.name}
                    </Grid>
                    <Grid item xs={3} sx={{display: 'flex', alignItems: 'center', wordBreak: "break-all"}}>
                        {contact.email}
                    </Grid>
                    <Grid item xs={3} sx={{display: 'flex', alignItems: 'center'}}>
                        {contact.phone}
                    </Grid>
                    <Grid item xs={2} sx={{display: 'flex', alignItems: 'center'}}>
                        {contact.position}
                    </Grid>
                    <Grid item xs={1} sx={{display: 'flex', alignItems: 'center'}}>
                        <IconButton color={'primary'}
                                    onClick={() => {
                                        setEditContact(contact);
                                        setEditOpen(true);
                                    }}
                        >
                            <EditOutlinedIcon sx={{fontSize: 24}}/>
                        </IconButton>
                    </Grid>
                    <Grid item xs={1} sx={{display: 'flex', alignItems: 'center'}}>
                        <IconButton color={'error'}
                                    onClick={() => {
                                        setDeleteContact(contact);
                                        setDeleteOpen(true);
                                    }}
                        >
                            <DeleteOutlineOutlinedIcon sx={{fontSize: 24}}/>
                        </IconButton>
                    </Grid>
                    <Grid item xs={12}>
                        <Divider sx={{width: '100%', paddingTop: '12px'}}/>
                    </Grid>
                </Grid>
            })
        }
        <Dialog open={createOpen} onClose={handleClose} fullWidth
                maxWidth="md">
            <DialogTitle>Új kapcsolattartó felvétele</DialogTitle>
            <DialogContent>
                <ContactPersonForm establishmentId={props.establishmentId}
                                   onSuccess={() => {
                                       setCreateOpen(false);
                                       refresh();
                                   }}
                                   isEdit={false}
                                   record={undefined}/>
            </DialogContent>
        </Dialog>
        <Dialog open={editOpen} onClose={handleClose} fullWidth
                maxWidth="md">
            <DialogTitle>Kapcsolattartó szerkesztése</DialogTitle>
            <DialogContent>
                <ContactPersonForm establishmentId={props.establishmentId}
                                   onSuccess={() => {
                                       setEditOpen(false);
                                       setEditContact(null);
                                       refresh();
                                   }}
                                   record={editContact}
                                   isEdit/>
            </DialogContent>
        </Dialog>
        <EstablishmentDeleteWarningDialog isOpen={deleteOpen}
                                          setIsOpen={setDeleteOpen}
                                          record={deleteContact}
                                          setRecord={setDeleteContact}
                                          deleteTitle={"Kapcsolattartó törlése"}
                                          deleteText={"Biztosan törölni szeretnéd ezt a kapcsolattartót:"}
                                          endpoint={"employer-contact-person"}
        />
    </Grid>
};
