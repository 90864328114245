import * as React from "react";
import {Datagrid, List, TextField} from 'react-admin';

export const SubtractionTypeList = () => (
    <List title={'Levonás típusok'} exporter={false}>
        <Datagrid rowClick="edit" bulkActionButtons={false}>
            <TextField source="id" label={"ID"}/>
            <TextField source="name" label={"Név"}/>
        </Datagrid>
    </List>
);