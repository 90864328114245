import * as React from 'react';
import Box from "@mui/material/Box";
import {ScheduleWorkerCreateDialog} from "./schedule-worker-create-dialog";
import {ScheduleWorkerEditDialog} from "./schedule-worker-edit-dialog";

export interface WorkerDialogProps {
    isOpen: boolean;
    setIsOpen: any;
    scheduleTimeTable: any;
    record: any;
    setRecord: any;
    applications: any
    refetch: boolean
    setRefetch: any
    establishmentId: number
    jobIds: number[]
}

export const employeeRenderOption = (data: any) => {
    if (data) {
        return `${data.name} (ID: ${data.employeeId})`
    }
    return '';
};

export const jobRenderOption = (data: any) => {
    if (data) {
        return `${data.name} (ID: ${data.id})`
    }
    return '';
};

export const employeeInputText = (record: any) => `${record.name} (ID: ${record.employeeId})`;
export const jobInputText = (record: any) => `${record.name} (ID: ${record.id})`;

export const ScheduleWorkerDialog = (props: WorkerDialogProps) => {
    return <Box>
        {
            props.record === null ? <ScheduleWorkerCreateDialog {...props}/> : <ScheduleWorkerEditDialog {...props}/>
        }
    </Box>
}
