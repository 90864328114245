import * as React from "react";
import {Create, Edit, SelectInput, SimpleForm, TextInput} from 'react-admin';
import {Grid} from "@mui/material";

const SkillForm = () => (
    <SimpleForm>
        <Grid container item xs={12} spacing={2} sx={{alignItems: 'center'}}>
            <Grid item xs={6}>
                <TextInput source="name" label={"Név (kötelező)"} name={"name"} required fullWidth/>
            </Grid>
            <Grid item xs={6}>
                <SelectInput source="type" label={"Típus (kötelező)"}
                             required
                             fullWidth
                             choices={[
                                 {id: 'Soft', name: 'Személyiségi jellemző'},
                                 {id: 'Hard', name: 'Képesítés'},
                             ]}/>
            </Grid>
        </Grid>
    </SimpleForm>
)

export const SkillCreate = () => (
    <Create title={'Képesség létrehozása'} redirect={"/skill"}>
        <SkillForm/>
    </Create>
);

export const SkillEdit = () => (
    <Edit title={'Képesség szerkesztése'}>
        <SkillForm/>
    </Edit>
);
