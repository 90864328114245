import * as React from "react";
import {Datagrid, List, TextField, TextInput} from 'react-admin';

const categoryFilters = [
    <TextInput label="Kulcsszó" source="query" name={"query"}/>,
];

export const CategoryList = () => (
    <List title={'Kategóriák'} exporter={false} filters={categoryFilters}>
        <Datagrid rowClick={'edit'} bulkActionButtons={false}>
            <TextField source="id" label={"ID"}/>
            <TextField source="name" label={"Név"}/>
            <TextField source="emoji" label={"Jel (emoji)"}/>
        </Datagrid>
    </List>
);
