import * as React from 'react';
import {useGetOne} from "react-admin";
import Box from "@mui/material/Box";
import {Grid, Stack, Typography} from "@mui/material";
import {getScheduleStatus} from "../../../../enum-translates";
import CheckCircleOutlineOutlinedIcon from "@mui/icons-material/CheckCircleOutlineOutlined";
import HelpOutlineOutlinedIcon from "@mui/icons-material/HelpOutlineOutlined";
import CancelOutlinedIcon from "@mui/icons-material/CancelOutlined";

interface Props {
    item: any;
}
export const PersonalScheduleGridRow = (props: Props) => {
    const { data: app, isLoading, error } = useGetOne('job-application', { id: (props.item && props.item.hasOwnProperty('jobApplicationId')) ? props.item.jobApplicationId : -1});
    const statusIcon = props.item.status === 'Completed' ? <CheckCircleOutlineOutlinedIcon sx={{color: '#12A594'}}/> :
        props.item.status === 'InProgress' ? <HelpOutlineOutlinedIcon/> : <CancelOutlinedIcon sx={{color: '#E5484D'}}/>;
    const statusColor = props.item.status === 'Completed' ? '#12A594' :
        props.item.status === 'InProgress' ? '#fff' : '#E5484D';

    if (isLoading) { return <Box />; }
    if (error) { return <p>ERROR</p>; }
    return <Grid container spacing={1}>
        <Grid item xs={3}>
            <Typography>{props.item.start.substring(0,10).replaceAll('-', '.')}, {new Date(props.item.start).toLocaleString('hu', {weekday: 'long'})} {props.item.start.substring(11,16)} - {props.item.end.substring(11,16)}</Typography>
        </Grid>
        <Grid item xs={2}>
            <EmployerField id={props.item.establishmentId}/>
        </Grid>
        <Grid item xs={2}>
            <EstablishmentField id={props.item.establishmentId}/>
        </Grid>
        <Grid item xs={2}>
            <JobField id={app.jobId}/>
        </Grid>
        <Grid item xs={1}>
            <Stack direction={'row'}>
                {statusIcon}
                <Typography sx={{color: statusColor, marginLeft: '5px'}}>{getScheduleStatus(props.item.status)}</Typography>
            </Stack>
        </Grid>
    </Grid>
}

interface FieldProps {
    id: number
}

const EstablishmentField = (props:FieldProps) => {
    const { data: est, isLoading, error } = useGetOne('establishment', { id: props.id});
    if (isLoading) { return <Box />; }
    if (error) { return <p>ERROR</p>; }
    return <Typography>{est.name}</Typography>
}

const JobField = (props:FieldProps) => {
    const { data: job, isLoading, error } = useGetOne('job', { id: props.id});
    if (isLoading) { return <Box />; }
    if (error) { return <p>ERROR</p>; }
    return <Typography>{job.name}</Typography>
}

const EmployerField = (props:FieldProps) => {
    const { data: est, isLoading, error } = useGetOne('establishment', { id: props.id});
    if (isLoading) { return <Box />; }
    if (error) { return <p>ERROR</p>; }
    return <EmployerFieldInner id={est.employerId}/>
}

const EmployerFieldInner = (props:FieldProps) => {
    const { data: employer, isLoading, error } = useGetOne('employer', { id: props.id});
    if (isLoading) { return <Box />; }
    if (error) { return <p>ERROR</p>; }
    return <Typography>{employer.name}</Typography>
}
