import * as React from 'react';
import {TabbedShowLayout, Edit, Show, Tab, SimpleForm, useShowContext, FunctionField} from 'react-admin';
import {useEffect, useState} from "react";
import {apiUrl, httpClient} from "../dataProvider";
import {EstablishmentJobs} from "./establishment-jobs";
import Box from "@mui/material/Box";
import {Typography} from "@mui/material";
import {ScheduleList} from "../job/schedule/schedule-list";
import {EstablishmentContacts} from "./establishment-contacts";


const EstablishmentShowLayout = () => {
    const {
        error,
        isLoading,
        record,
    } = useShowContext();
    const [projectName, setProjectName]: any = useState('');
    useEffect(() => {
        if (record && projectName === '') {
            httpClient(`${apiUrl}/employer/${record.employerId}`, {
                method: 'GET',
            }).then(({json}) => {
                setProjectName(json.name);
            })
        }
    }, [record])
    if (isLoading) {
        return <div>Loading...</div>;
    }
    if (error) {
        return <div>Error!</div>;
    }
    return (
        <Box>
            <Box sx={{padding: '10px 20px'}}>
                <Typography variant={'h5'}>Telephely: {record.name}</Typography>
                <Typography sx={{fontSize: '16px'}}>Projekt: {projectName}</Typography>
                <Typography
                    sx={{fontSize: '16px'}}>{record.address.country}, {record.address.zip}, {record.address.city},
                    {record.address.address}</Typography>
                {record.note !== '' && record.note !== null && <Box sx={{border: '2px solid #DEA500', borderRadius: '10px', padding: '10px', marginTop: '10px'}}>
                    <Typography sx={{fontSize: '16px'}}>Megjegyzés: {record.note}</Typography>
                </Box>}
            </Box>
            <TabbedShowLayout>
                <Tab label="Beosztás">
                    <Edit actions={<span/>} title={' '}>
                        <SimpleForm toolbar={<div/>}>
                            <ScheduleList jobRecord={record}
                                          establishmentId={record.id}
                            />
                        </SimpleForm>
                    </Edit>
                </Tab>
                <Tab label="Munkák">
                    <SimpleForm toolbar={<div/>}>
                        <EstablishmentJobs establishmentId={record.id}/>
                    </SimpleForm>
                </Tab>
                <Tab label="Kapcsolattartók">
                    <SimpleForm toolbar={<div/>}>
                        <EstablishmentContacts establishmentId={record.id}/>
                    </SimpleForm>
                </Tab>
            </TabbedShowLayout>
        </Box>
    );
};


export const EstablishmentShow = () => {
    return <Show title={'Telephely'}>
        <EstablishmentShowLayout/>
    </Show>;
};
