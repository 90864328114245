import * as React from "react";
import {Edit, SelectInput, SimpleForm, TextInput, useEditContext, useNotify, useRedirect} from 'react-admin';
import {Grid} from "@mui/material";
import dataProvider from "../dataProvider";

const EmployeeApplicationForm = () => {
    const {record} = useEditContext();
    const redirect = useRedirect();
    const notify = useNotify();
    return <SimpleForm onSubmit={async (values) => {
        await dataProvider.update('employee-application', {
            id: record.id,
            data: {
                id: record.id,
                email: values.email,
                name: values.name,
                phone: values.phone,
                status: values.status,
                note: values.note === '' ? null : values.note,
                campaign: values.campaign,
                appliedAt: record.appliedAt
            }
        }).then(r => {
            if(r.data) {
                notify(`Sikeres módosítás`, {type: 'success'});
                redirect('/employee-application')
            }
        })
    }
    }>
        <Grid container item xs={12} spacing={2} sx={{alignItems: 'center'}}>
            <Grid item xs={4}>
                <TextInput source="name" label={"Név (kötelező)"} name={"name"} fullWidth required/>
            </Grid>
            <Grid item xs={4}>
                <TextInput source="email" label={"E-mail"} name={"email"} fullWidth/>
            </Grid>
            <Grid item xs={4}>
                <TextInput source="phone" label={"Telefonszám"} name={"phone"} fullWidth/>
            </Grid>
            <Grid item xs={4}>
                <SelectInput source="status" label={"Státusz"}
                             fullWidth
                             defaultValue={'Initial'}
                             choices={[
                                 {id: 'Initial', name: 'Feldolgozásra vár'},
                                 {id: 'Disapproved', name: 'Elutasítva'},
                                 {id: 'DidNotAnswer', name: 'Nem vette fel'},
                                 {id: 'NeedsCallback', name: 'Vissza kell hívni'},
                                 {id: 'Approved', name: 'Elfogadva'},
                             ]}/>
            </Grid>
            <Grid item xs={8}>
                <TextInput source="campaign" label={"Kampány"} name={"campaign"} fullWidth/>
            </Grid>
            <Grid item xs={12}>
                <TextInput multiline source="note" label={"Megjegyzés"} name={"note"} fullWidth/>
            </Grid>
        </Grid>
    </SimpleForm>
}

export const EmployeeApplicationEdit = () => (
    <Edit title={'Jelentkező szerkesztése'}>
        <EmployeeApplicationForm/>
    </Edit>
);
