import {AutocompleteInput, Create, ReferenceInput, SimpleForm, useNotify} from 'react-admin';
import * as React from "react";
import dataProvider from "../dataProvider";
import {inputEmployeeText, renderEmployeeOption} from "../job-application/job-application-create";

interface Props {
    jobId: number;
}

export const EstablishmentNewJobApplication = (props: Props) => {
    const filterToQuery = (searchText:string) => ({ query: `%${searchText}%` });
    const notify = useNotify();
    return (
        <Create>
            <SimpleForm onSubmit={async (values) => {
                const neededValues = {
                    jobId: props.jobId,
                    employeeId: values.employeeId,
                    status: 'Active'
                }
                await dataProvider.create('job-application', {data: neededValues}).then(res => {
                    if (res.data) {
                        notify(`Sikeres létrehozás`, {type: 'success'});
                    } else {
                        notify(`Hiba történt`, {type: 'error'});
                    }
                })
            }}>
                <ReferenceInput source="employeeId"
                                reference="employee"
                                name={"employeeId"}>
                    <AutocompleteInput source="name"
                                       filterToQuery={filterToQuery}
                                       optionValue={"id"}
                                       label={"Munkavállaló (kötelező)"}
                                       size={"small"}
                                       fullWidth
                                       isRequired={true}
                                       inputText={inputEmployeeText}
                                       optionText={renderEmployeeOption}
                    />
                </ReferenceInput>
            </SimpleForm>
        </Create>
    );
};
