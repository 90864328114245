import {Box, Divider, Grid, IconButton, Stack, Tooltip} from "@mui/material";
import {FunctionField, useNotify} from "react-admin";
import * as React from "react";
import {useEffect, useState} from "react";
import {apiUrl, httpClient} from "../../dataProvider";
import AddOutlinedIcon from '@mui/icons-material/AddOutlined';
import DeleteOutlineOutlinedIcon from '@mui/icons-material/DeleteOutlineOutlined';
import EditOutlinedIcon from '@mui/icons-material/EditOutlined';
import {ScheduleWorkerChip} from "./schedule-worker/schedule-worker-chip";
import {ScheduleWorkerDialog} from "./schedule-worker/schedule-worker-dialog";
import {ScheduleDayEditDialog} from "./schedule-day/schedule-day-edit-dialog";

export interface ScheduleGridRowProp {
    scheduleTimeTable: any
    applications: any
    timeTableRefresh: boolean
    setTimeTableRefresh: any
    establishmentId: number
}

export const ScheduleGridRow = (props: ScheduleGridRowProp) => {
    const [fetchedItems, setFetchedItems] = useState(false);
    const notify = useNotify();
    const [scheduleItems, setScheduleItems]: any = useState();
    useEffect(() => {
        if (typeof props.scheduleTimeTable !== 'undefined') {
            httpClient(`${apiUrl}/schedule?scheduleTimeTableId=${props.scheduleTimeTable.id}&page=1&size=10000`, {
                method: 'GET',
            }).then(({json}) => {
                setScheduleItems(json.items);
                setFetchedItems(true);
            })
        }
    }, [fetchedItems, props.timeTableRefresh])

    const [isOpen, setIsOpen] = useState(false);
    const [record, setRecord]: any = useState(null);

    const [jobs, setJobs]: any[] = useState([]);
    const [json, setJson] = useState({items: []});
    useEffect(() => {
        const jobIds = props.scheduleTimeTable.requiredStuff.map((rs: any) => rs.jobId);
        let idsString = '';
        for (let i = 0; i < jobIds.length; i++) {
            idsString += `ids=${jobIds[i]}`;
            if (i < jobIds.length) {
                idsString += '&'
            }
        }
        httpClient(`${apiUrl}/job?${idsString}page=1&size=10000`, {
            method: 'GET',
        }).then(({json}) => {
            setJobs(json.items.map((it: any) => {
                return {id: it.id, name: it.name}
            }));
        })

        if (scheduleItems) {
            const appIds = scheduleItems.map((rs: any) => rs.jobApplicationId);
            let appIdsString = '';
            for (let i = 0; i < appIds.length; i++) {
                appIdsString += `ids=${appIds[i]}`;
                if (i < appIds.length) {
                    appIdsString += '&'
                }
            }
            httpClient(`${apiUrl}/job-application?${appIdsString}page=1&size=10000`, {
                method: 'GET',
            }).then(({json}) => {
                setJson(json)
            })
        }
    }, [scheduleItems])

    const handleDelete = () => {
        httpClient(`${apiUrl}/schedule-time-table/${props.scheduleTimeTable.id}`, {
            method: 'DELETE',
        }).then(r => {
            if (r.status === 204) {
                notify(`Eltávolítva`, {type: 'success'});
                props.setTimeTableRefresh(!props.timeTableRefresh);
                setFetchedItems(!fetchedItems);
            } else {
                notify(`Hiba történt az eltávolítás során`, {type: 'error'});
            }
        })
    };

    const [editOpen, setEditOpen] = useState(false);

    return <Grid item xs={12} container spacing={1}>
        <Grid item xs={2}>
            <FunctionField
                render={() => `${props.scheduleTimeTable.date.toString().replaceAll('-', '.')}, ${new Date(props.scheduleTimeTable.date).toLocaleString('hu', {weekday: 'long'})}`}
            />
        </Grid>
        <Grid item xs={4}>
            <Stack direction={'column'} flexWrap={'wrap'} justifyContent={'center'} alignItems={'flex-start'}>
                {typeof scheduleItems !== 'undefined' && scheduleItems.length > 0 &&
                    scheduleItems.map((it: any) => {
                        return <ScheduleWorkerChip key={it.id}
                                                   scheduleItem={it}
                                                   setRecord={setRecord}
                                                   setIsOpen={setIsOpen}
                                                   refetch={fetchedItems}
                                                   setRefetch={setFetchedItems}
                        />
                    })
                }
            </Stack>
            <IconButton color={'primary'}
                        style={{marginTop: '4px'}}
                        onClick={() => {
                            setIsOpen(true)
                        }}>
                <AddOutlinedIcon/>
            </IconButton>
            <ScheduleWorkerDialog isOpen={isOpen}
                                  setIsOpen={setIsOpen}
                                  scheduleTimeTable={props.scheduleTimeTable}
                                  record={record}
                                  setRecord={setRecord}
                                  applications={props.applications}
                                  refetch={fetchedItems}
                                  setRefetch={setFetchedItems}
                                  establishmentId={props.establishmentId}
                                  jobIds={jobs.map((j: any) => j.id)}
            />
        </Grid>
        <Grid item xs={5}>
            {props.scheduleTimeTable.requiredStuff.length > 0 &&
                <Stack direction={"row"} spacing={2} alignItems={'flex-start'}>
                    <Box>
                        {props.scheduleTimeTable.requiredStuff.map((s: any) => {
                            return <Box sx={{margin: '5px 0px'}}>
                                kell: <b>{s.requiredStuffNumber} {jobs.find((j: any) => j.id === s.jobId)?.name}</b>
                            </Box>
                        })
                        }
                    </Box>
                    <Box>
                        {props.scheduleTimeTable.requiredStuff.map((s: any) => {
                            let apps: any[] = [];
                            jobs.forEach((j: any) => {
                                const appCount = json.items.filter((it: any) => it.jobId === j.id).length;
                                apps.push({
                                    jobId: j.id,
                                    appCount: scheduleItems.length === 0 ? 0: appCount,
                                });
                            });

                            let appsCount = apps ? apps.find((a: any) => a.jobId === s.jobId)?.appCount : 0
                            let appCountForStatus = appsCount === 0 ? 'Empty' : 'Not full';
                            let status = appsCount >= s.requiredStuffNumber ? 'Full' : appCountForStatus;

                            return <Box sx={{
                                margin: '5px 0px',
                                color: status === 'Full' ? '#66bb6a' : status === 'Empty' ? '#f44336' : '#ffa726'
                            }}>
                                beosztva: <span
                                style={{
                                    fontSize: '16px',
                                    fontWeight: 700,
                                }}>{appsCount}</span>
                            </Box>
                        })}
                    </Box>
                    <IconButton color={'primary'} onClick={() => setEditOpen(true)}>
                        <EditOutlinedIcon/>
                    </IconButton>
                </Stack>
            }
            <ScheduleDayEditDialog isOpen={editOpen}
                                   setIsOpen={setEditOpen}
                                   establishmentId={props.establishmentId}
                                   timeTableRefresh={props.timeTableRefresh}
                                   setTimeTableRefresh={props.setTimeTableRefresh}
                                   initialValues={props.scheduleTimeTable}
            />
        </Grid>
        <Grid item xs={1}>
            <Tooltip title={'Egy nap nem törölhető ameddig hozzá tartozik beosztott munkavállaló.'}>
                <Box>
                    <IconButton sx={{color: '#E5484D'}} onClick={handleDelete} disabled={scheduleItems?.length > 0}>
                        <DeleteOutlineOutlinedIcon/>
                    </IconButton>
                </Box>
            </Tooltip>
        </Grid>
        <Grid item xs={12}>
            <Divider sx={{width: '100%', borderBottomWidth: '1px'}}/>
        </Grid>
    </Grid>
}
