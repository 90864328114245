import * as React from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import {FormDataConsumer, SimpleForm, TextInput, useNotify, useRefresh} from "react-admin";
import Box from "@mui/material/Box";
import {apiUrl, httpClient} from "../dataProvider";
import {Typography} from "@mui/material";

interface Props {
    isOpen: boolean;
    setIsOpen: any;
    record: any;
    setRecord: any;
}

export const ToEmployeeApplicationDialog = (props: Props) => {
    const handleClose = () => {
        props.setRecord(null)
        props.setIsOpen(false);
    };

    const notify = useNotify();
    const refresh = useRefresh();

    return <Dialog open={props.isOpen} onClose={handleClose} fullWidth
                   maxWidth="sm">
        <DialogTitle>Jelentkező felvétele</DialogTitle>
        <DialogContent>
            <SimpleForm toolbar={<></>}>
                <Typography>A jelentkező felvételéhez kérlek add meg az e-mail címét:</Typography>
                <Box sx={{height: '10px'}}/>
                <TextInput source="email" label={"E-mail (kötelező)"} name={"email"} required fullWidth/>
                <Box sx={{height: '10px'}}/>
                <FormDataConsumer>
                    {({formData, ...rest}) =>
                        <Button variant={'contained'}
                                sx={{width: '100%', marginTop: '-10px'}}
                                onClick={async () => {
                                    await httpClient(`${apiUrl}/employee-application/${props.record.id}/toEmployee`, {
                                        method: 'POST',
                                        body: JSON.stringify({
                                            email: formData.email,
                                        })
                                    }).then(r => {
                                        if (r.status === 200) {
                                            handleClose();
                                            notify(`Felvéve munkavállalókhoz`, {type: 'success'});
                                            refresh();
                                        } else {
                                            notify(`Hiba történt`, {type: 'error'});
                                        }
                                    }).catch(error => {
                                        const errorMsg = error?.body.toString();
                                        notify(errorMsg === 'Duplicate email address' ? 'Ez az e-mail cím már használatban van' : 'Hiba történt', {type: 'error'});
                                    })
                                }}>
                            Felvétel munkavállalónak
                        </Button>
                    }
                </FormDataConsumer>
            </SimpleForm>
        </DialogContent>
    </Dialog>;
}
