import {useState} from 'react';
import {Button, Confirm, useCreate, useNotify} from 'react-admin';
import SaveOutlinedIcon from '@mui/icons-material/SaveOutlined';
import {useFormContext} from 'react-hook-form';

interface NotificationProps {
    type: string
}

export const ConfirmSaveButton = (props: NotificationProps) => {
    const [open, setOpen] = useState(false);
    const form = useFormContext();
    const values = form.getValues();
    const handleClick = () => setOpen(true);
    const handleDialogClose = () => setOpen(false);
    const [create, {isLoading, error}] = useCreate('notification', {data: values});
    const notify = useNotify();
    const handleConfirm = () => {
        let neededValues: any = {
            content: values.content,
            image: values.image,
            title: values.title
        }
        if (props.type === 'Topic') {
            neededValues.recipients = {
                topics: ['/topics/all']
            }
        } else if (props.type === 'Users') {
            if (values.hasOwnProperty('userPickType')) {
                if (values.userPickType === 'Pick') {
                    neededValues.recipients = {
                        userIds: values.recipients.userIds
                    }
                } else {
                    neededValues.recipients = {
                        filter: values.recipients.filter
                    }
                }
            }
        } else {
            neededValues.recipients = {
                jobIds: values.recipients.jobIds
            }
        }
        create('notification',
            {
                data: neededValues
            }
        )
        notify(`Értesítés kiküldve`, {type: 'success'});
        setOpen(false);
    }
    return (
        <>
            <Button label="Mentés" onClick={handleClick} variant={"contained"}
                    startIcon={<SaveOutlinedIcon style={{fontSize: '20px'}}/>}
                    disabled={values.templateId === ''}
            />
            <Confirm
                isOpen={open}
                loading={isLoading}
                title="Értesítés kiküldése"
                content="A Megerősítés gombra kattintva az értesítés kiküldésre kerül."
                onConfirm={handleConfirm}
                onClose={handleDialogClose}
            />
        </>
    );
};