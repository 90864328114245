import {Layout, LayoutProps, useTheme} from 'react-admin';
import {CustomMenu} from "./custom-menu";
import CustomAppBar from "./custom-app-bar";
import {useEffect} from "react";
import {getCurrentTheme} from "../App";

export const CustomLayout = (props: JSX.IntrinsicAttributes & LayoutProps) => {
    const [theme, setTheme] = useTheme();
    useEffect(() => {
        setTheme(getCurrentTheme());
    })
    return <Layout {...props} menu={CustomMenu} appBar={CustomAppBar}/>
};