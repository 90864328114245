import * as React from "react";
import {useState} from "react";
import {
    CreateButton,
    Datagrid, DateTimeInput,
    ExportButton,
    FilterButton,
    FilterForm,
    FunctionField,
    List, SelectInput,
    TextField,
    TextInput,
    TopToolbar,
    useListContext,
    usePermissions
} from 'react-admin';
import {getEmployeeStatus, getEmployeeTypeName} from "../enum-translates";
import {Button, Divider, Stack} from "@mui/material";
import {apiUrl, httpClient} from "../dataProvider";
import {canAccess} from "../layout/custom-menu";
import {BulkEmployeeDialog} from "./bulk-employee-dialog";
import FileUploadOutlinedIcon from '@mui/icons-material/FileUploadOutlined';
import {exportExcel} from "../export-excel";
import {dateParser, dateTimeFormatter} from "../date-formatter/date-formatter";

const exporter = (values: any) => {
    const currentDate = new Date();
    const excelValues = values.map((v: any, index: number) => {
        return {
            'ID': v.id,
            'Név': v.name,
            'E-mail': v.email,
            'Telefonszám': v.phone,
            'Állandó lakcím ország': v.homeAddress !== null && typeof v.homeAddress !== 'undefined' ? v.homeAddress.country : '',
            'Állandó lakcím ir.szám': v.homeAddress !== null && typeof v.homeAddress !== 'undefined' ? v.homeAddress.zip : '',
            'Állandó lakcím város': v.homeAddress !== null && typeof v.homeAddress !== 'undefined' ? v.homeAddress.city : '',
            'Állandó lakcím utca/házszám': v.homeAddress !== null && typeof v.homeAddress !== 'undefined' ? v.homeAddress.address : '',
            'Postázási cím ország': v.mailingAddress !== null && typeof v.homeAddress !== 'undefined' ? v.mailingAddress.country : '',
            'Postázási cím ir.szám': v.mailingAddress !== null && typeof v.homeAddress !== 'undefined' ? v.mailingAddress.zip : '',
            'Postázási cím város': v.mailingAddress !== null && typeof v.homeAddress !== 'undefined' ? v.mailingAddress.city : '',
            'Postázási cím utca/házszám': v.mailingAddress !== null && typeof v.homeAddress !== 'undefined' ? v.mailingAddress.address : '',
            'Születési idő': v.birtDate,
            'Születési hely': v.birthLocation,
            'Anyja neve': v.motherName,
            'Nem': v.gender,
            'Adóazonosító': v.taxId,
            'TAJ szám': v.socialSecurityNumber,
            'Eü kiskönyv érvényes': v.healthBookletExpiresAt,
            'Típus': v.type,
            'Státusz': v.status,
            'Bankszámlaszám': v.bankAccountNumber,
            'Bankszámla tulajdonos': v.bankAccountOwner,
            'Megjegyzés': v.note,
            'Profilkép': v.profilePicture,
        }
    });
    exportExcel({
        excelData: excelValues,
        fileName: `munkavallalok-${currentDate.getFullYear()}_${currentDate.getMonth() + 1}_${currentDate.getDate()}`
    }).then(r => {
    });
};

const employeeFilters = [
    <TextInput source="query" label={"Keresés"} name={"query"} fullWidth required alwaysOn/>,
    <DateTimeInput source="freeInRangeFrom" label={"Nincs beosztva ettől (kötelező)"}
                   name={"freeInRangeFrom"} fullWidth
                   format={dateTimeFormatter}
                   parse={dateParser} alwaysOn
                   inputProps={{
                       max: '2099-12-31T23:59'
                   }}
    />,
    <DateTimeInput source="freeInRangeTo" label={"Nincs beosztva eddig (kötelező)"}
                   name={"freeInRangeTo"} fullWidth
                   format={dateTimeFormatter}
                   parse={dateParser} alwaysOn
                   inputProps={{
                       max: '2099-12-31T23:59'
                   }}
    />,
    <SelectInput source="status" label={"Státusz"}
                 fullWidth
                 choices={[
                     {id: 'Active', name: 'Aktív'},
                     {id: 'Inactive', name: 'Inaktív'},
                     {id: 'Blacklisted', name: 'Feketelistás'},
                 ]}/>,
    <SelectInput source="type" label={"Foglalkoztatási forma"}
                 fullWidth
                 defaultValue={null}
                 choices={[
                     {id: 'Adult', name: 'Felnőtt'},
                     {id: 'Student', name: 'Diák'},
                     {id: 'Retired', name: 'Nyugdíjas'},
                     {id: 'Disabled', name: 'Deaktivált'},
                 ]}/>
];

const CustomToolbar = () => {
    const {filterValues} = useListContext();
    let querystring = '';
    const keys = Object.keys(filterValues);
    keys.forEach((k: any) => {
        querystring += `&${k}=${filterValues[k]}`
    })
    const {permissions} = usePermissions();
    const [isOpen, setIsOpen] = useState(false);
    return <TopToolbar sx={{width: '100%', paddingTop: '20px'}}>
        <Stack direction={"column"} sx={{width: '100%'}}>
            <Stack direction="row"
                   justifyContent="flex-end"
                   alignItems={'center'}
                   sx={{width: '100%', paddingBottom: '5px'}}>
                <FilterForm filters={employeeFilters}/>
                {canAccess('Super', permissions) && <ExportButton onClick={() => {
                    httpClient(`${apiUrl}/employee?export=true&page=1&size=1${querystring}`, {
                        method: 'GET',
                    }).then(({json}) => {
                        exporter(json.items)
                    })
                }
                } style={{marginRight: '20px'}}/>
                }
                <Divider orientation={'vertical'} style={{height: '30px'}}
                         sx={{borderRightWidth: 2, marginRight: '15px'}}/>
                <div>
                    <FilterButton filters={employeeFilters}/>
                </div>
                <Divider orientation={'vertical'} style={{height: '30px'}} sx={{borderRightWidth: 2}}/>
                <Button onClick={() => setIsOpen(true)} startIcon={<FileUploadOutlinedIcon/>}
                        style={{margin: '0px 30px'}}>
                    Csoportos feltöltés
                </Button>
                <Divider orientation={'vertical'} style={{height: '30px', marginRight: '10px'}}
                         sx={{borderRightWidth: 2}}/>
                <CreateButton/>
            </Stack>
        </Stack>
        <BulkEmployeeDialog isOpen={isOpen} setIsOpen={setIsOpen}/>
    </TopToolbar>
}

export const EmployeeList = () => (
    <List title={'Munkavállalók'} actions={<CustomToolbar/>}>
        <Datagrid rowClick={'show'} bulkActionButtons={false}>
            <TextField source="id" label={"ID"}/>
            <TextField source="name" label={"Név"}/>
            <TextField source="phone" label={"Telefonszám"}/>
            <TextField source="email" label={"E-mail"}/>
            <TextField source="taxId" label={"Adószám"}/>
            <FunctionField
                label="Státusz"
                render={(record: any) => `${getEmployeeStatus(record.status)}`}
            />
            <FunctionField
                label="Foglalkoztatási forma"
                render={(record: any) => `${getEmployeeTypeName(record.type)}`}
            />
        </Datagrid>
    </List>
);
