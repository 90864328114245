import * as React from "react";
import {Edit, SaveButton, SimpleForm, TextInput, Toolbar, useNotify, useRecordContext, useRedirect} from 'react-admin';
import dataProvider from "../dataProvider";

const CustomToolbar = (props: any) => (
    <Toolbar
        {...props}
        sx={{display: 'flex', justifyContent: 'space-between'}}
    >
        <SaveButton/>
    </Toolbar>
);

const SystemParamForm = () => {
    const record = useRecordContext();
    const redirect = useRedirect();
    const notify = useNotify();
    return <SimpleForm toolbar={<CustomToolbar/>}
                       onSubmit={async (values) => {
                           const neededValues = {
                               name: values.name,
                               value: values.value,
                           }
                           await dataProvider.update('system-params', {id: record.id, data: neededValues}).then(res => {
                               if (res.data) {
                                   notify(`Sikeres módosítás`, {type: 'success'});
                                   redirect('list', 'system-params');
                               } else {
                                   notify(`Hiba történt`, {type: 'error'});
                               }
                           })
                       }}
    >
        <TextInput source="name" label={"Név (kötelező)"} name={"name"} fullWidth required/>
        <TextInput source="value" label={"Érték (kötelező)"} name={"value"} fullWidth required/>
    </SimpleForm>
}

export const SystemParamEdit = () => {
    return <Edit title={'Rendszer paraméter szerkesztése'}>
        <SystemParamForm/>
    </Edit>
}