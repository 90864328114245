import {FunctionField, useGetOne} from "react-admin";
import {Box} from "@mui/material";
import * as React from "react";

interface EmployerFieldWrapperProps {
    jobId: number,
}

export const EmployerFieldWrapper = (props: EmployerFieldWrapperProps) => {
    const {data: job, isLoading, error} = useGetOne('job', {id: props.jobId});
    if (isLoading) {
        return <Box/>;
    }
    if (error) {
        return <p>ERROR</p>;
    }
    return <EmployerFieldInnerWrapper
        establishmentId={job.establishmentId}
    />
}

interface EmployerFieldInnerWrapperProps {
    establishmentId: number,
}

const EmployerFieldInnerWrapper = (props: EmployerFieldInnerWrapperProps) => {
    const {data: establishment, isLoading, error} = useGetOne('establishment', {id: props.establishmentId});
    if (isLoading) {
        return <Box/>;
    }
    if (error) {
        return <p>ERROR</p>;
    }
    return <EmployerField
        employerId={establishment.employerId}
    />
}

interface EmployerFieldProps {
    employerId: number,
}

const EmployerField = (props: EmployerFieldProps) => {
    const {data: employer, isLoading, error} = useGetOne('employer', {id: props.employerId});
    if (isLoading) {
        return <Box/>;
    }
    if (error) {
        return <p>ERROR</p>;
    }
    return <FunctionField
        render={() => `${employer.name}`}
    />
}
