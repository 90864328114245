import {useStore, useTheme} from 'react-admin';
import {brandingDarkTheme, brandingLightTheme} from "../theme";
import * as React from "react";
import {darkRedTheme, lightRedTheme} from "../theme-red";
import {darkPinkTheme, lightPinkTheme} from "../theme-pink";
import {darkBlueTheme, lightBlueTheme} from "../theme-blue";
import {darkPurpleTheme, lightPurpleTheme} from "../theme-purple";
import Select from '@mui/material/Select';
import Box from '@mui/material/Box';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';

export const ThemeChangeButton = () => {
    const [theme, setTheme] = useTheme();
    const [value, setValue] = useStore('theme-type');

    const handleChange = (event: any) => {
        let newTheme = {};
        switch (event.target.value as string) {
            case 'dark-default':
                newTheme = brandingDarkTheme;
                break;
            case 'light-default':
                newTheme = brandingLightTheme;
                break;
            case 'dark-red':
                newTheme = darkRedTheme;
                break;
            case 'light-red':
                newTheme = lightRedTheme;
                break;
            case 'dark-pink':
                newTheme = darkPinkTheme;
                break;
            case 'light-pink':
                newTheme = lightPinkTheme;
                break;
            case 'dark-blue':
                newTheme = darkBlueTheme;
                break;
            case 'light-blue':
                newTheme = lightBlueTheme;
                break;
            case 'dark-purple':
                newTheme = darkPurpleTheme;
                break;
            case 'light-purple':
                newTheme = lightPurpleTheme;
                break;
            default:
                newTheme = brandingDarkTheme;
        }
        setValue(event.target.value as string);
        setTheme(newTheme);
        localStorage.setItem('myThemeType', event.target.value as string);
    };
    // @ts-ignore
    return <Box>
        <FormControl fullWidth>
            <Select
                value={value}
                placeholder={"Értesítés típus"}
                onChange={handleChange}
                name={"Értesítés típus"}
                size={"small"}
                sx={{width: '100%', marginBottom: '15px'}}
            >
                <MenuItem value={'dark-default'}>Alap (sötét)</MenuItem>
                <MenuItem value={'light-default'}>Alap (világos)</MenuItem>
                <MenuItem value={'dark-red'}>Piros (sötét)</MenuItem>
                <MenuItem value={'light-red'}>Piros (világos)</MenuItem>
                <MenuItem value={'dark-blue'}>Kék (sötét)</MenuItem>
                <MenuItem value={'light-blue'}>Kék (világos)</MenuItem>
                <MenuItem value={'dark-purple'}>Lila (sötét)</MenuItem>
                <MenuItem value={'light-purple'}>Lila (világos)</MenuItem>
                <MenuItem value={'dark-pink'}>Rózsaszín (sötét)</MenuItem>
                <MenuItem value={'light-pink'}>Rózsaszín (világos)</MenuItem>
            </Select>
        </FormControl>
    </Box>;
}