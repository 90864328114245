import * as React from "react";
import {useState} from "react";
import {
    AutocompleteArrayInput,
    Create,
    DateInput,
    Edit,
    FormDataConsumer,
    ReferenceArrayInput, SaveButton,
    SelectInput,
    SimpleForm,
    TextInput, Toolbar,
    useNotify,
    usePermissions,
    useRecordContext,
    useRedirect
} from 'react-admin';
import {Box, Grid, Stack, Typography} from "@mui/material";
import {canAccess} from "../layout/custom-menu";
import myDataProvider from "../data-provider-with-image-convert";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import Dialog from "@mui/material/Dialog";
import Button from "@mui/material/Button";
import {EmployeeDeleteWarningDialog} from "./employee-delete-warning-dialog";
import DeleteIcon from "@mui/icons-material/Delete";

interface Props {
    setIsDeleteOpen: any;
    isEdit: boolean;
}

const CustomToolbar = (props: Props) => (
    <Toolbar
        sx={{display: 'flex', justifyContent: 'space-between'}}
    >
        <SaveButton/>
        {props.isEdit && <Button onClick={() => props.setIsDeleteOpen(true)} color={'error'}>
            <Stack direction={'row'} alignItems={'center'}>
                <DeleteIcon/>
                <Typography sx={{marginLeft: '5px'}}>Törlés</Typography>
            </Stack>
        </Button>}
    </Toolbar>
);

export interface FormProps {
    isEdit: boolean;
}

const EmployeeForm = (props: FormProps) => {
    const record = useRecordContext();
    const {permissions} = usePermissions();
    const notify = useNotify();
    const redirect = useRedirect();
    const [isOpen, setIsOpen] = useState(false);
    const [deleteIsOpen, setDeleteIsOpen] = useState(false);
    const filterToQuery = (searchText: string) => ({query: `%${searchText}%`});

    return <Box>
        {record && <Typography sx={{paddingLeft: '18px', paddingTop: '15px', paddingBottom: '5px'}} fontSize={24}
                               fontWeight={700}>
            {record.name} alap információk szerkesztése
        </Typography>}
        <SimpleForm toolbar={<CustomToolbar setIsDeleteOpen={setDeleteIsOpen} isEdit={props.isEdit}/>}
                    onSubmit={async (values) => {
                        const neededValues = {
                            name: values.name,
                            type: values.type,
                            status: values.status,
                            motherName: values.motherName,
                            email: values.email,
                            phone: values.phone,
                            gender: values.gender,
                            birthLocation: values.birthLocation,
                            birthDate: values.birthDate,
                            socialSecurityNumber: values.socialSecurityNumber,
                            bankAccountOwner: values.bankAccountOwner,
                            bankAccountNumber: values.bankAccountNumber,
                            taxId: values.taxId,
                            homeAddress: {
                                country: values.homeAddress.country,
                                city: values.homeAddress.city,
                                zip: values.homeAddress.zip,
                                address: values.homeAddress.address,
                            },
                            mailingAddress: {
                                country: values.mailingAddress.country,
                                city: values.mailingAddress.city,
                                zip: values.mailingAddress.zip,
                                address: values.mailingAddress.address,
                            },
                            skills: values.skills,
                        }
                        if (props.isEdit) {
                            if (record.bankAccountNumber !== values.bankAccountNumber) {
                                setIsOpen(true)
                            } else {
                                await myDataProvider.update('employee', {
                                    id: record.id,
                                    data: neededValues
                                }).then(res => {
                                    if (res.data) {
                                        notify(`Sikeres módosítás`, {type: 'success'});
                                    } else {
                                        notify(`Hiba történt`, {type: 'error'});
                                    }
                                }).catch(r => {
                                    notify(`Hiba történt`, {type: 'error'});
                                })
                            }
                        } else {
                            await myDataProvider.create('employee', {data: neededValues}).then(res => {
                                if (res.data) {
                                    notify(`Sikeres létrehozás`, {type: 'success'});
                                    redirect('list', 'employee');
                                } else {
                                    notify(`Hiba történt`, {type: 'error'});
                                }
                            }).catch(error => {
                                const errorMsg = error?.body.toString();
                                notify(errorMsg === 'Duplicate email address' ? 'Ez az e-mail cím már használatban van' : 'Hiba történt', {type: 'error'});
                            })
                        }
                    }}>
            <Grid container item xs={12} spacing={2} sx={{alignItems: 'center'}}>
                <Grid item xs={4}>
                    <TextInput source="name" label={"Név (kötelező)"} name={"name"} fullWidth required/>
                </Grid>
                <Grid item xs={4}>
                    <SelectInput source="type" label={"Foglalkoztatási forma"}
                                 fullWidth
                                 defaultValue={null}
                                 choices={[
                                     {id: 'Adult', name: 'Felnőtt'},
                                     {id: 'Student', name: 'Diák'},
                                     {id: 'Retired', name: 'Nyugdíjas'},
                                     {id: 'Disabled', name: 'Deaktivált'},
                                 ]}/>
                </Grid>
                <Grid item xs={4}>
                    <SelectInput source="status" label={"Státusz"}
                                 fullWidth
                                 defaultValue={null}
                                 choices={[
                                     {id: 'Active', name: 'Aktív'},
                                     {id: 'Inactive', name: 'Inaktív'},
                                     {id: 'Blacklisted', name: 'Feketelistás'},
                                 ]}/>
                </Grid>
            </Grid>
            <Grid container item xs={12} spacing={2} sx={{alignItems: 'center'}}>
                <Grid item xs={4}>
                    <TextInput source="motherName" label={"Anyja neve"} name={"motherName"} fullWidth/>
                </Grid>
                <Grid item xs={4}>
                    <TextInput source="email" label={"E-mail (kötelező)"} name={"email"} fullWidth required
                               disabled={props.isEdit}/>
                </Grid>
                <Grid item xs={4}>
                    <TextInput source="phone" label={"Telefonszám"} name={"phone"} fullWidth/>
                </Grid>
            </Grid>
            <Grid container item xs={12} spacing={2} sx={{alignItems: 'center'}}>
                <Grid item xs={4}>
                    <SelectInput source="gender" label={"Nem"}
                                 fullWidth
                                 defaultValue={typeof record !== 'undefined' ? record.gender : null}
                                 choices={[
                                     {id: 'Male', name: 'Férfi'},
                                     {id: 'Female', name: 'Nő'},
                                 ]}/>
                </Grid>
                <Grid item xs={4}>
                    <TextInput source="birthLocation" label={"Születési hely"} name={"birthLocation"} fullWidth/>
                </Grid>
                <Grid item xs={4}>
                    <DateInput source="birthDate" label={"Születési idő"} name={"birthDate"} fullWidth
                               inputProps={{
                                   max: '2099-12-31'
                               }}/>
                </Grid>
            </Grid>
            <Grid container item xs={12} spacing={2} sx={{alignItems: 'center'}}>
                <Grid item xs={4}>
                    <TextInput source="socialSecurityNumber" label={"TAJ szám"} name={"socialSecurityNumber"}
                               fullWidth/>
                </Grid>
                <Grid item xs={4}>
                    <SelectInput source="bankAccountOwner" label={"Bankszámla tulajdonos"}
                                 fullWidth
                                 defaultValue={typeof record !== 'undefined' ? record.bankAccountOwner : null}
                                 choices={[
                                     {id: 'Own', name: 'Saját'},
                                     {id: 'SomebodyElse', name: 'Más tulajdonos'},
                                 ]}/>
                </Grid>
                <Grid item xs={4}>
                    <TextInput source="bankAccountNumber" label={"Bankszámlaszám"} name={"bankAccountNumber"}
                               fullWidth disabled={!canAccess('Super', permissions)} inputProps={{maxLength: 26}}/>
                </Grid>
            </Grid>
            <Grid container item xs={12} spacing={2} sx={{alignItems: 'center'}}>
                <Grid item xs={4}>
                    <TextInput source="taxId" label={"Adóazonosító"} name={"taxId"}
                               fullWidth/>
                </Grid>
            </Grid>
            <Grid container spacing={2}>
                <Grid item xs={12}>
                    <Typography fontSize={14}>Állandó lakcím (tartózkodási cím)</Typography>
                </Grid>
                <Grid item xs={2}>
                    <TextInput source="homeAddress.country" label={"Ország"}
                               name={"homeAddress.country"} fullWidth defaultValue={"Magyarország"}/>
                </Grid>
                <Grid item xs={2}>
                    <TextInput source="homeAddress.zip" label={"Ir.szám"} name={"homeAddress.zip"}
                               fullWidth/>
                </Grid>
                <Grid item xs={4}>
                    <TextInput source="homeAddress.city" label={"Város"} name={"homeAddress.city"}
                               fullWidth/>
                </Grid>
                <Grid item xs={4}>
                    <TextInput source="homeAddress.address" label={"Cím"}
                               name={"homeAddress.address"} fullWidth/>
                </Grid>
            </Grid>
            <Grid container spacing={2}>
                <Grid item xs={12}>
                    <Typography fontSize={14}>Postázási cím</Typography>
                </Grid>
                <Grid item xs={2}>
                    <TextInput source="mailingAddress.country" label={"Ország"}
                               name={"mailingAddress.country"} fullWidth defaultValue={"Magyarország"}/>
                </Grid>
                <Grid item xs={2}>
                    <TextInput source="mailingAddress.zip" label={"Ir.szám"} name={"mailingAddress.zip"}
                               fullWidth/>
                </Grid>
                <Grid item xs={4}>
                    <TextInput source="mailingAddress.city" label={"Város"} name={"mailingAddress.city"}
                               fullWidth/>
                </Grid>
                <Grid item xs={4}>
                    <TextInput source="mailingAddress.address" label={"Cím"}
                               name={"mailingAddress.address"} fullWidth/>
                </Grid>
            </Grid>
            <Typography color={'#DEA500'} fontSize={16} fontWeight={600}>Belső információk</Typography>
            <Typography color={'#DEA500'} fontSize={14} sx={{marginBottom: '8px'}}>Az itt megadott információk csak az
                admin
                felhasználók számára lesznek láthatóak</Typography>
            <Box sx={{border: '2px solid #DEA500', borderRadius: '10px', padding: '20px 10px', width: '95%'}}>
                <ReferenceArrayInput source="skills" reference="skill" name={"skills"}>
                    <AutocompleteArrayInput optionText="name" fullWidth label={"Tudások"} size={"small"}
                                            filterToQuery={filterToQuery}/>
                </ReferenceArrayInput>
                <TextInput source="note" label={"Megjegyzés"} name={"note"} multiline fullWidth/>
            </Box>
            <FormDataConsumer>
                {({formData, ...rest}) => {
                    return <Dialog open={isOpen} onClose={() => setIsOpen(false)} fullWidth
                                   maxWidth="sm">
                        <DialogTitle>Munkavállaló szerkesztése</DialogTitle>
                        <DialogContent>
                            <Typography>Biztosan módosítani szeretnéd a bankszámlaszámot?</Typography>
                            <Box sx={{height: '25px'}}/>
                            <Stack direction={'row'} alignItems={'center'} justifyContent={"space-between"}>
                                <Button variant={'contained'}
                                        color={"error"}
                                        sx={{width: '150px'}}
                                        onClick={() => setIsOpen(false)}>
                                    Mégsem
                                </Button>
                                <Button variant={'contained'}
                                        sx={{width: '150px'}}
                                        onClick={async () => {
                                            const neededValues = {
                                                name: formData.name,
                                                type: formData.type,
                                                status: formData.status,
                                                motherName: formData.motherName,
                                                email: formData.email,
                                                phone: formData.phone,
                                                gender: formData.gender,
                                                birthLocation: formData.birthLocation,
                                                birthDate: formData.birthDate,
                                                socialSecurityNumber: formData.socialSecurityNumber,
                                                bankAccountOwner: formData.bankAccountOwner,
                                                bankAccountNumber: formData.bankAccountNumber,
                                                taxId: formData.taxId,
                                                homeAddress: {
                                                    country: formData.homeAddress.country,
                                                    city: formData.homeAddress.city,
                                                    zip: formData.homeAddress.zip,
                                                    address: formData.homeAddress.address,
                                                },
                                                mailingAddress: {
                                                    country: formData.mailingAddress.country,
                                                    city: formData.mailingAddress.city,
                                                    zip: formData.mailingAddress.zip,
                                                    address: formData.mailingAddress.address,
                                                },
                                                skills: formData.skills,
                                            }
                                            await myDataProvider.update('employee', {
                                                id: record.id,
                                                data: neededValues
                                            }).then(res => {
                                                if (res.data) {
                                                    notify(`Sikeres módosítás`, {type: 'success'});
                                                    setIsOpen(false);
                                                } else {
                                                    notify(`Hiba történt`, {type: 'error'});
                                                }
                                            })
                                        }}>
                                    Igen
                                </Button>
                            </Stack>
                        </DialogContent>
                    </Dialog>
                }}
            </FormDataConsumer>
            {record && props.isEdit &&
                <EmployeeDeleteWarningDialog record={record} isOpen={deleteIsOpen} setIsOpen={setDeleteIsOpen}/>}
        </SimpleForm>
    </Box>
}

export const EmployeeCreate = () => (
    <Create title={'Munkavállaló létrehozása'} redirect={'/employee'}>
        <EmployeeForm isEdit={false}/>
    </Create>
);

export const EmployeeEdit = () => (
    <Edit title={'Munkavállaló szerkesztése'}>
        <EmployeeForm isEdit={true}/>
    </Edit>
);
