import * as React from "react";
import {FunctionField, ReferenceField, TextField, useGetOne} from 'react-admin';
import {Divider, Grid, Typography} from "@mui/material";
import Box from "@mui/material/Box";

interface SubstractionsGridProps {
    substractions: any[];
}

export const SubstractionsGrid = (props: SubstractionsGridProps) => {
    return <>
        {props.substractions.length === 0 ?
            <Typography fontSize={16}>Nincsenek levonások</Typography> :
            <Grid container spacing={1}>
                <Grid item xs={2}>
                    <Typography fontSize={14} fontWeight={700}>Típus</Typography>
                </Grid>
                <Grid item xs={2}>
                    <Typography fontSize={14} fontWeight={700}>Érték (Ft)</Typography>
                </Grid>
                <Grid item xs={2}>
                    <Typography fontSize={14} fontWeight={700}>Megjegyzés</Typography>
                </Grid>
                <Grid item xs={2}>
                    <Typography fontSize={14} fontWeight={700}>Létrehozta (Admin ID)</Typography>
                </Grid>
                <Grid item xs={2}>
                    <Typography fontSize={14} fontWeight={700}>Létrehozva</Typography>
                </Grid>
                <Grid item xs={2}>
                    <Typography fontSize={14} fontWeight={700}>Levonva</Typography>
                </Grid>
                <Grid item xs={12}>
                    <Divider sx={{width: '100%', borderBottomWidth: '3px'}}/>
                </Grid>
                {props.substractions.map((s, index) => {
                    const createdAtDate = new Date(s.createdAt);
                    const subtractedAtDate = new Date(s.subtractedAt);
                    return <Grid item xs={12} container spacing={1}>
                        <Grid item xs={2}>
                            <TypeField id={s.typeId}/>
                        </Grid>
                        <Grid item xs={2}>
                            <FunctionField
                                render={() => `${s.amount} Ft`}
                            />
                        </Grid>
                        <Grid item xs={2}>
                            <FunctionField
                                render={() => `${s.note}`}
                            />
                        </Grid>
                        <Grid item xs={2}>
                            <AdminField id={s.createdById}/>
                        </Grid>
                        <Grid item xs={2}>
                            <FunctionField
                                render={() => createdAtDate.toLocaleDateString('hu')}
                            />
                        </Grid>
                        <Grid item xs={2}>
                            <FunctionField
                                render={() => s.subtractedAt === null ? '-' : subtractedAtDate.toLocaleDateString('hu')}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <Divider sx={{width: '100%', borderBottomWidth: '1px'}}/>
                        </Grid>
                    </Grid>
                })}
            </Grid>
        }
    </>
}

interface TypeFieldProps {
    id: number;
}

const TypeField = (props: TypeFieldProps) => {
    const {data: type, isLoading, error} = useGetOne('job-subtraction-type', {id: props.id});
    if (isLoading) {
        return <Box/>;
    }
    if (error) {
        return <p>ERROR</p>;
    }
    return <FunctionField
        render={() => `${type.name}`}
    />
}

const AdminField = (props: TypeFieldProps) => {
    const {data: admin, isLoading, error} = useGetOne('administrator', {id: props.id});
    if (props.id === 0) {
        return <span>Proglimes</span>
    }
    if (isLoading) {
        return <Box/>;
    }
    if (error) {return <p>ERROR</p>;}
    return <FunctionField
        render={() => `${admin.name}`}
    />
}