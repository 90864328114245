import * as React from 'react';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import {
    ChipField,
    DateField,
    FunctionField,
    ReferenceArrayField,
    SimpleShowLayout,
    SingleFieldList,
    TextField
} from "react-admin";
import Box from "@mui/material/Box";
import {Grid, Stack, Typography} from "@mui/material";
import {
    getEmployeeBankAccountOwner,
    getEmployeeGender,
    getEmployeeStatus,
    getEmployeeTypeName
} from "../../enum-translates";
import CloseOutlinedIcon from '@mui/icons-material/CloseOutlined';

interface Props {
    isOpen: boolean;
    setIsOpen: any;
    employee: any;
}

export const ScheduleEmployeeInfoDialog = (props: Props) => {
    const handleClose = () => {
        props.setIsOpen(false);
    };

    return <Dialog open={props.isOpen} onClose={handleClose} fullWidth
                   maxWidth="lg">
        <DialogTitle>
            <Stack direction={'row'} alignItems={'center'} justifyContent={'space-between'}>
                <Box>Munkavállaló adatai</Box>
                <CloseOutlinedIcon sx={{cursor: 'pointer'}}
                                   onClick={() => {
                                       props.setIsOpen(false);
                                   }}
                />
            </Stack>
        </DialogTitle>
        <DialogContent>
            <SimpleShowLayout record={props.employee}>
                <Grid container spacing={0}>
                    <Grid container item xs={12}>
                        <Grid item xs={12}>
                            <SimpleShowLayout>
                                <TextField source="id" label={"ID"}/>
                            </SimpleShowLayout>
                        </Grid>
                    </Grid>
                    <Grid item xs={4}>
                        <SimpleShowLayout>
                            <TextField source="name" label={"Név"}/>
                            <TextField source="motherName" label={"Anyja neve"}/>
                            <FunctionField
                                label="Nem"
                                render={(record: any) => `${getEmployeeGender(record.gender)}`}
                            />
                            <TextField source="socialSecurityNumber" label={"TAJ szám"}/>
                        </SimpleShowLayout>
                    </Grid>
                    <Grid item xs={4}>
                        <SimpleShowLayout>
                            <FunctionField
                                label="Foglalkoztatási forma"
                                render={(record: any) => `${getEmployeeTypeName(record.type)}`}
                            />
                            <TextField source="email" label={"E-mail"}/>
                            <TextField source="birthLocation" label={"Születési hely"}/>
                            <FunctionField
                                label="Állandó lakcím (tartózkodási cím)"
                                render={(record: any) => record.homeAddress !== null ? `${record.homeAddress.country}, ${record.homeAddress.zip}, ${record.homeAddress.city}, ${record.homeAddress.address}` : '-'}
                            />
                        </SimpleShowLayout>
                    </Grid>
                    <Grid item xs={4}>
                        <SimpleShowLayout>
                            <FunctionField
                                label="Státusz"
                                render={(record: any) => `${getEmployeeStatus(record.status)}`}
                            />
                            <TextField source="phone" label={"Telefonszám"}/>
                            <DateField source="birthDate" locales={['hu-HU']} label={"Születési idő"}/>
                            <FunctionField
                                label="Postázási cím"
                                render={(record: any) => record.mailingAddress !== null ? `${record.mailingAddress.country}, ${record.mailingAddress.zip}, ${record.mailingAddress.city}, ${record.mailingAddress.address}` : '-'}
                            />
                        </SimpleShowLayout>
                    </Grid>
                    <Grid container item xs={12}>
                        <Grid item xs={12}>
                            <SimpleShowLayout>
                                <TextField source="taxId" label={"Adóazonosító jel"}/>
                            </SimpleShowLayout>
                        </Grid>
                    </Grid>
                    <Grid container item xs={12}>
                        <Grid item xs={4}>
                            <SimpleShowLayout>
                                <FunctionField
                                    label="Bankszámla tulajdonos"
                                    render={(record: any) => `${getEmployeeBankAccountOwner(record.bankAccountOwner)}`}
                                />
                                <TextField source="bankAccountNumber" label={"Bankszámlaszám"}/>
                            </SimpleShowLayout>
                        </Grid>
                        <Grid item xs={4}>
                            <SimpleShowLayout>
                                <DateField source="healthBookletExpiresAt" locales={['hu-HU']}
                                           label={"Eü kiskönyv érvényes"}/>
                            </SimpleShowLayout>
                        </Grid>
                    </Grid>
                    <Grid item xs={12}>
                        <SimpleShowLayout>
                            <Box>
                                <Typography
                                    sx={{
                                        fontSize: '0.75em',
                                        color: '#d7dbdf',
                                        marginBottom: '10px'
                                    }}>Tudások</Typography>
                                <ReferenceArrayField label="" reference="skill" source="skills">
                                    <SingleFieldList>
                                        <ChipField source="name"/>
                                    </SingleFieldList>
                                </ReferenceArrayField>
                            </Box>
                            <TextField source="note" label={"Megjegyzés"}/>
                        </SimpleShowLayout>
                    </Grid>
                </Grid>
            </SimpleShowLayout>
        </DialogContent>
    </Dialog>;
}