import * as React from "react";
import {Datagrid, DateField, List, TextField, TextInput} from 'react-admin';

const attachmentFilters = [
    <TextInput source="query" label={"Kulcsszó (kötelező)"} name={"query"} fullWidth required/>
];

export const AttachmentList = () => (
    <List title={'Csatolmányok'} filters={attachmentFilters} exporter={false}>
        <Datagrid rowClick="show" bulkActionButtons={false}>
            <TextField source="id" label={"ID"}/>
            <TextField source="name" label={"Név"}/>
            <DateField source="createdAt" locales={['hu-HU']} label={"Létrehozva"}/>
        </Datagrid>
    </List>
);