import * as React from "react";
import {
    Create,
    FormDataConsumer,
    ReferenceArrayInput,
    SelectArrayInput,
    SelectInput,
    SimpleForm,
    TextInput, useNotify
} from 'react-admin';
import dataProvider from "../dataProvider";

const AdministratorForm = () => {
    const notify = useNotify();
    return <SimpleForm onSubmit={async (values) => {
        const neededValues = {
            name: values.name,
            email: values.email,
            roleType: values.roleType,
            accessedEmployerIds: values.roleType === 'Regular' ? values.accessedEmployerIds : [],
        }
        await dataProvider.create('administrator', {data: neededValues}).then(res => {
            if (res.data) {
                notify(`Sikeres létrehozás`, {type: 'success'});
            } else {
                notify(`Hiba történt`, {type: 'error'});
            }
        })
    }}>
        <TextInput source="name" label={"Név (kötelező)"} name={"name"} required fullWidth/>
        <TextInput source="email" label={"E-mail (kötelező)"} name={"email"} required fullWidth/>
        <SelectInput source="roleType" label={"Hozzáférés (kötelező)"}
                     fullWidth
                     required
                     defaultValue={'Regular'}
                     choices={[
                         {id: 'Regular', name: 'Általános'},
                         {id: 'Super', name: 'Szuper admin'},
                     ]}/>
        <FormDataConsumer>
            {({formData, ...rest}) => {
                if (formData.roleType === 'Regular') {
                    return <ReferenceArrayInput name={"accessedEmployerIds"} source="accessedEmployerIds"
                                                reference="employer">
                        <SelectArrayInput fullWidth placeholder={'Projekt'} label={'Projekt hozzáférés'}/>
                    </ReferenceArrayInput>
                }
            }
            }
        </FormDataConsumer>
    </SimpleForm>
}

export const AdministratorCreate = () => (
    <Create title={'Felhasználó hozzáadása'}>
        <AdministratorForm/>
    </Create>
);