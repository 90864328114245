import * as React from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import {FileField, FileInput, FormDataConsumer, SimpleForm, useNotify, useRefresh} from "react-admin";
import AddCircleOutlineOutlinedIcon from "@mui/icons-material/AddCircleOutlineOutlined";
import Box from "@mui/material/Box";
import {apiUrl, httpClient} from "../dataProvider";
import {Stack} from "@mui/material";
import FileDownloadOutlinedIcon from "@mui/icons-material/FileDownloadOutlined";
import {exportExcel} from "../export-excel";
import XLSX from "sheetjs-style";
import {dateTimeFormatter} from "../date-formatter/date-formatter";
import {getJsDateFromExcel} from "excel-date-to-js";

interface Props {
    isOpen: boolean;
    setIsOpen: any;
    setExistingEmployeesOpen: any;
    setExistingEmployees: any;
}

export async function loadExcel(file: any): Promise<any> {
    return new Promise<any>((resolve, reject) => {
        const reader = new FileReader();
        const rABS = !!reader.readAsBinaryString;

        reader.onload = (e) => {
            // @ts-ignore
            const bstr = e.target.result;
            const wb = XLSX.read(bstr, {type: rABS ? 'binary' : 'array', bookVBA: true});
            const wsname = wb.SheetNames[0];
            const ws = wb.Sheets[wsname];
            const data = XLSX.utils.sheet_to_json(ws);
            resolve(data);
        };

        reader.onerror = (e) => {
            reject(e);
        };

        if (rABS) {
            reader.readAsBinaryString(file);
        } else {
            reader.readAsArrayBuffer(file);
        }
    });
}

export const BulkEmployeeApplicationDialog = (props: Props) => {
    const handleClose = () => {
        props.setIsOpen(false);
    };

    const notify = useNotify();
    const excelValues = [{
        'Név (kötelező)': 'PÉLDA BALÁZS (EZT A SORT TÖRÖLJÜK KI)',
        'E-mail (kötelező)': 'pelda.balazs@gmail.com',
        'Telefonszám': '+36305558877',
        'Kampány': '2023 Facebook hirdetés',
        'Jelentkezés ideje': ''
    }];

    const refresh = useRefresh();

    return <Dialog open={props.isOpen} onClose={handleClose} fullWidth
                   maxWidth="sm">
        <DialogTitle><Stack direction={"row"} alignItems={"center"} justifyContent={"space-between"}>
            <Box>Jelentkezők feltöltése</Box>
            <Button variant={'contained'}
                    startIcon={<FileDownloadOutlinedIcon/>}
                    sx={{width: '200px', height: '35px'}}
                    onClick={async () => {
                        exportExcel({
                            excelData: excelValues,
                            fileName: `jelentkezok-minta`
                        }).then(r => {
                        });
                    }}>
                Minta letöltése
            </Button>
        </Stack></DialogTitle>
        <DialogContent>
            <SimpleForm toolbar={<></>}>
                <FileInput source="excelFile"
                           label="Fájl (kötelező)"
                           accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
                           isRequired>
                    <FileField source="src" title="title"/>
                </FileInput>
                <Box sx={{height: '10px'}}/>
                <FormDataConsumer>
                    {({formData, ...rest}) =>
                        <Button variant={'contained'}
                                startIcon={<AddCircleOutlineOutlinedIcon/>}
                                sx={{width: '100%', marginTop: '-10px'}}
                                onClick={async () => {
                                    loadExcel(formData.excelFile.rawFile)
                                        .then(async data => {
                                            const formattedData = data.map((d: any) => {
                                                const appliedAt = d['Jelentkezés ideje'] ? dateTimeFormatter(getJsDateFromExcel(d['Jelentkezés ideje'])).substring(0, 10) : null;
                                                return {
                                                    name: d['Név (kötelező)'],
                                                    email: d['E-mail (kötelező)'],
                                                    phone: (d['Telefonszám'] ?? '').toString(),
                                                    campaign: (d['Kampány'] ?? '').toString(),
                                                    appliedAt: appliedAt,
                                                }
                                            });
                                            await httpClient(`${apiUrl}/employee-application/bulk`, {
                                                method: 'POST',
                                                body: JSON.stringify(formattedData),
                                            }).then(r => {
                                                if (r.status === 200) {
                                                    notify(`Jelentkezők felvéve`, {type: 'success'});
                                                    if(r.json.length !== 0) {
                                                        props.setExistingEmployees(r.json)
                                                        props.setExistingEmployeesOpen(true)
                                                    }
                                                    props.setIsOpen(false);
                                                    refresh();
                                                } else {
                                                    notify(`Hiba történt a jelentkezők létrehozásakor`, {type: 'error'});
                                                }
                                            })
                                        })
                                        .catch();
                                }}>
                            Feltöltés
                        </Button>
                    }
                </FormDataConsumer>
            </SimpleForm>
        </DialogContent>
    </Dialog>;
}
