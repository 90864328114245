import * as React from "react";
import {useEffect} from "react";
import {
    AutocompleteArrayInput,
    AutocompleteInput,
    Create,
    DateTimeInput,
    FormDataConsumer,
    ImageField,
    ImageInput,
    ReferenceArrayInput,
    ReferenceInput,
    SelectInput,
    SimpleForm,
    TextInput,
    Toolbar
} from 'react-admin';
import {ConfirmSaveButton} from "../layout/confirm-save-button";
import {MenuItem, Select} from "@mui/material";
import {dateParser, dateTimeFormatter} from "../date-formatter/date-formatter";
import {useFormContext} from "react-hook-form";

const NotificationForm = () => {
    const [type, setType] = React.useState('Topic');

    const handleChange = (event: any) => {
        setType(event.target.value as string);
    };

    return <SimpleForm toolbar={<Toolbar>
        <ConfirmSaveButton type={type}/>
    </Toolbar>}>
        <TextInput source="title" label={"Cím (kötelező)"} name={"title"} fullWidth required/>
        <TextInput source="content" label={"Tartalom (kötelező)"} name={"content"} fullWidth required/>
        <ImageInput source="image" label="Index kép (kötelező)" accept="image/*" placeholder={<p>Kép megadása</p>}
                    name={"image"}
                    isRequired>
            <ImageField source="src" title="title"/>
        </ImageInput>
        <Select
            value={type}
            placeholder={"Értesítés típus"}
            onChange={handleChange}
            name={"Értesítés típus"}
            size={"small"}
            sx={{width: '100%', marginBottom: '15px'}}
        >
            <MenuItem value={'Topic'}>Minden munkavállaló</MenuItem>
            <MenuItem value={'Users'}>Kiválasztott munkavállalóknak</MenuItem>
            <MenuItem value={'Job'}>Munkához tartozó munkavállalóknak</MenuItem>
        </Select>
        {type === 'Users' && <UsersInput type={type}/>}
        {type === 'Job' && <JobInput type={type}/>}
    </SimpleForm>
}

interface Props {
    type: string
}

const JobInput = (props: Props) => {
    const form = useFormContext();
    useEffect(() => {
        form.setValue('recipients', null);
    }, [])

    const filterToQuery = (searchText: string) => ({query: `%${searchText}%`});

    return <>
        <ReferenceInput source="employerId"
                        reference="employer"
                        name={"employerId"}>
            <AutocompleteInput source="name"
                               optionValue={"id"}
                               label={"Projekt (kötelező)"}
                               size={"small"}
                               fullWidth
                               isRequired={props.type === 'Job'}
                               filterToQuery={filterToQuery}
            />
        </ReferenceInput>
        <FormDataConsumer>
            {({formData, ...rest}) => {
                if (!formData.employerId) {
                    return null;
                }
                return <ReferenceInput source="establishmentId"
                                       reference="establishment"
                                       name={"establishmentId"}
                                       filter={{employerId: formData.employerId}}
                >
                    <AutocompleteInput source="name"
                                       optionValue={"id"}
                                       label={"Telephely (kötelező)"}
                                       size={"small"}
                                       fullWidth
                                       isRequired={props.type === 'Job'}
                                       filterToQuery={filterToQuery}
                    />
                </ReferenceInput>
            }}
        </FormDataConsumer>
        <FormDataConsumer>
            {({formData, ...rest}) => {
                if (!formData.establishmentId) {
                    return null;
                }
                return <ReferenceArrayInput source="recipients.jobIds"
                                            reference="job"
                                            name={"recipients.jobIds"}
                                            filter={{establishmentId: formData.establishmentId}}
                >
                    <AutocompleteArrayInput source="name"
                                            label={"Munka (kötelező)"}
                                            size={"small"}
                                            fullWidth
                                            isRequired={props.type === 'Job'}
                                            optionValue={"id"}
                                            defaultValue={[]}
                                            filterToQuery={filterToQuery}
                    />
                </ReferenceArrayInput>
            }}
        </FormDataConsumer>
    </>
}

const UsersInput = (props: Props) => {
    const form = useFormContext();
    useEffect(() => {
        form.setValue('recipients', null);
    }, [])

    const filterToQuery = (searchText: string) => ({query: `%${searchText}%`});
    return <>
        <SelectInput source="userPickType" label={"Szűrési típus (kötelező)"}
                     fullWidth
                     required={props.type === 'Users'}
                     defaultValue={'Range'}
                     choices={[
                         {id: 'Range', name: 'Nincs beosztva (tól-ig)'},
                         {id: 'Pick', name: 'Választott munkavállalók'},
                     ]}/>
        <FormDataConsumer>
            {({formData, ...rest}) => {
                if (!formData.userPickType) {
                    return null;
                }
                if (formData.userPickType === 'Range') {
                    return <>
                        <DateTimeInput source="recipients.filter.freeInRangeFrom"
                                       label={"Nincs beosztva ettől (kötelező)"}
                                       name={"recipients.filter.freeInRangeFrom"} fullWidth
                                       required={props.type === 'Users'} format={dateTimeFormatter}
                                       parse={dateParser}
                                       inputProps={{
                                           max: '2099-12-31T23:59'
                                       }}/>
                        <DateTimeInput source="recipients.filter.freeInRangeTo"
                                       label={"Nincs beosztva eddig (kötelező)"}
                                       name={"recipients.filter.freeInRangeTo"} fullWidth
                                       required={props.type === 'Users'} format={dateTimeFormatter}
                                       parse={dateParser}
                                       inputProps={{
                                           max: '2099-12-31T23:59'
                                       }}/>
                    </>
                } else {
                    return <ReferenceArrayInput source="recipients.userIds" reference="employee"
                                                name={"recipients.userIds"}>
                        <AutocompleteArrayInput optionText="name" fullWidth
                                                label={"Címzett felhasználók (kötelező)"}
                                                size={"small"}
                                                isRequired={props.type === 'Users'}
                                                defaultValue={[]}
                                                filterToQuery={filterToQuery}
                        />
                    </ReferenceArrayInput>
                }
            }}
        </FormDataConsumer>
    </>
}

export const NotificationCreate = () => (
    <Create title={'Értesítés létrehozása'}>
        <NotificationForm/>
    </Create>
);
