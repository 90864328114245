import * as React from "react";
import {Datagrid, DateField, List, TextField} from 'react-admin';

export const BlogList = () => (
    <List title={'Blog bejegyzések'} exporter={false}>
        <Datagrid rowClick={'show'} bulkActionButtons={false}>
            <TextField source="id" label={"ID"}/>
            <TextField source="title" label={"Cím"}/>
            <TextField source="lead" label={"Alcím"}/>
            <DateField source="createdAt" locales={['hu-HU']} label={"Létrehozva"}/>
        </Datagrid>
    </List>
);