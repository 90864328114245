import * as React from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import {useNotify, useRedirect} from "react-admin";
import Box from "@mui/material/Box";
import {apiUrl, httpClient} from "../dataProvider";
import {Stack, Typography} from "@mui/material";

interface Props {
    isOpen: boolean;
    setIsOpen: any;
    record: any;
}

export const FaqDeleteWarningDialog = (props: Props) => {
    const handleClose = () => {
        props.setIsOpen(false);
    };

    const notify = useNotify();
    const redirect = useRedirect();

    return <Dialog open={props.isOpen} onClose={handleClose} fullWidth
                   maxWidth="xs">
        <DialogTitle>Gyakori kérdés törlése</DialogTitle>
        <DialogContent>
            <Typography>Biztosan törölni szeretnéd ezt a kérdést: <b>{props.record.question}?</b></Typography>
            <Box sx={{height: '25px'}}/>
            <Stack direction={'row'} alignItems={'center'} justifyContent={"space-between"}>
                <Button variant={'contained'}
                        color={"error"}
                        sx={{width: '150px'}}
                        onClick={handleClose}>
                    Mégsem
                </Button>
                <Button variant={'contained'}
                        sx={{width: '150px'}}
                        onClick={async () => {
                            httpClient(`${apiUrl}/faq/${props.record.id}`, {
                                method: 'DELETE',
                            }).then(r => {
                                if (r.status === 204) {
                                    notify(`Eltávolítva`, {type: 'success'});
                                    props.setIsOpen(false);
                                    redirect('list', 'faq');
                                } else {
                                    notify(`Hiba történt az eltávolítás során`, {type: 'error'});
                                }
                            })
                        }}>
                    Igen
                </Button>
            </Stack>
        </DialogContent>
    </Dialog>;
}
