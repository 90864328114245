import * as React from "react";
import {Datagrid, List, TextField} from 'react-admin';

export const StaticPageList = () => (
    <List title={'Statikus oldalak'} exporter={false}>
        <Datagrid rowClick={'show'} bulkActionButtons={false}>
            <TextField source="id" label={"ID"}/>
            <TextField source="title" label={"Cím"}/>
            <TextField source="url" label={"Url"}/>
        </Datagrid>
    </List>
);