import * as React from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import {useNotify, useRefresh} from "react-admin";
import Box from "@mui/material/Box";
import {apiUrl, httpClient} from "../dataProvider";
import {Stack, Typography} from "@mui/material";

interface Props {
    isOpen: boolean;
    setIsOpen: any;
    record: any;
    setRecord: any;
    deleteText: string;
    deleteTitle: string;
    endpoint: string;
}

export const EstablishmentDeleteWarningDialog = (props: Props) => {
    const handleClose = () => {
        props.setRecord(null)
        props.setIsOpen(false);
    };

    const notify = useNotify();
    const refresh = useRefresh();

    if (props.record === null) return null;

    return <Dialog open={props.isOpen} onClose={handleClose} fullWidth
                   maxWidth="xs">
        <DialogTitle>{props.deleteTitle}</DialogTitle>
        <DialogContent>
            <Typography>{props.deleteText} <b>{props.record.name}?</b></Typography>
            <Box sx={{height: '25px'}}/>
            <Stack direction={'row'} alignItems={'center'} justifyContent={"space-between"}>
                <Button variant={'contained'}
                        color={"error"}
                        sx={{width: '150px'}}
                        onClick={handleClose}>
                    Mégsem
                </Button>
                <Button variant={'contained'}
                        sx={{width: '150px'}}
                        onClick={async () => {
                            httpClient(`${apiUrl}/${props.endpoint}/${props.record.id}`, {
                                method: 'DELETE',
                            }).then(r => {
                                if (r.status === 204) {
                                    notify(`Eltávolítva`, {type: 'success'});
                                    refresh();
                                    props.setIsOpen(false);
                                } else {
                                    notify(`Hiba történt az eltávolítás során`, {type: 'error'});
                                }
                            })
                        }}>
                    Igen
                </Button>
            </Stack>
        </DialogContent>
    </Dialog>;
}
