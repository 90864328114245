import * as React from "react";
import {Create, Edit, SimpleForm, TextInput} from 'react-admin';
import {Grid} from "@mui/material";

const SubtractionTypeForm = () => (
    <SimpleForm>
        <Grid container item xs={12} spacing={2} sx={{alignItems: 'center'}}>
            <Grid item xs={6}>
                <TextInput source="name" label={"Név (kötelező)"} name={"name"} required fullWidth/>
            </Grid>
        </Grid>
    </SimpleForm>
)

export const SubtractionTypeCreate = () => (
    <Create title={'Levonás típus létrehozása'} sx={{marginTop: '30px'}} redirect={'/job-subtraction-type'}>
        <SubtractionTypeForm/>
    </Create>
);

export const SubtractionTypeEdit = () => (
    <Edit title={'Levonás típus szerkesztése'} sx={{marginTop: '30px'}}>
        <SubtractionTypeForm/>
    </Edit>
);
