import * as React from "react";
import {Datagrid, FunctionField, List, TextField} from 'react-admin';
import {getSkillType} from "../enum-translates";

export const SkillList = () => (
    <List title={'Képességek'} exporter={false}>
        <Datagrid rowClick="edit" bulkActionButtons={false}>
            <TextField source="id" label={"ID"}/>
            <TextField source="name" label={"Név"}/>
            <FunctionField
                label="Típus"
                render={(record: any) => `${getSkillType(record.type)}`}
            />
        </Datagrid>
    </List>
);