import {SelectInput, useGetList, useGetMany} from "react-admin";
import * as React from "react";
import Box from "@mui/material/Box";

interface Props {
    jobId: number,
}

export const EmployeeInput = (props: Props) => {
    const { data, total, isLoading, error } = useGetList(
        'job-application',
        {
            pagination: { page: 1, perPage: 1000 },
            filter: {jobId: props.jobId}
        }
    );

    const ids:EmployeeAndApplicationId[] = data ? data.map(d => {
        return {
            employeeId: d.employeeId,
            applicationId: d.id
        }
    }) : [];
    if (isLoading) { return <Box />; }
    if (error) { return <p>ERROR</p>; }
    return <EmployeeInputField employeeAndApplicationId={ids}/>
}

interface EmployeeAndApplicationId {
    employeeId: number;
    applicationId: number
}

interface FieldProps {
    employeeAndApplicationId: EmployeeAndApplicationId[]
}

const EmployeeInputField = (props: FieldProps) => {
    const employeeIds = props.employeeAndApplicationId.map(d => d.employeeId);
    const { data, isLoading, error } = useGetMany(
        'employee',
        { ids: employeeIds }
    );

    const choices = data ? data.map(d => {
        const application = props.employeeAndApplicationId.find(a => a.employeeId === d.id);
        if(typeof application === 'undefined') {
            return {}
        }
        return {
            id: application.applicationId,
            name: d.name
        }
    }) : [];

    if (isLoading) { return <Box />; }
    if (error) { return <p>ERROR</p>; }
    return <SelectInput source="jobApplicationId" label={"Munkavállaló"}
                         fullWidth
                         required
                         choices={choices}/>
}