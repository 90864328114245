import {Menu, usePermissions, useTheme} from 'react-admin';
import CircleNotificationsOutlinedIcon from '@mui/icons-material/CircleNotificationsOutlined';
import SettingsOutlinedIcon from '@mui/icons-material/SettingsOutlined';
import SubMenu from "./sub-menu";
import {useState} from "react";
import GroupOutlinedIcon from '@mui/icons-material/GroupOutlined';
import PersonOutlineOutlinedIcon from '@mui/icons-material/PersonOutlineOutlined';
import DesignServicesOutlinedIcon from '@mui/icons-material/DesignServicesOutlined';
import WorkOutlineOutlinedIcon from '@mui/icons-material/WorkOutlineOutlined';
import ApartmentOutlinedIcon from '@mui/icons-material/ApartmentOutlined';
import CategoryOutlinedIcon from '@mui/icons-material/CategoryOutlined';
import ArticleOutlinedIcon from '@mui/icons-material/ArticleOutlined';
import HelpOutlineOutlinedIcon from '@mui/icons-material/HelpOutlineOutlined';
import FeedOutlinedIcon from '@mui/icons-material/FeedOutlined';
import HomeWorkOutlinedIcon from '@mui/icons-material/HomeWorkOutlined';
import MapOutlinedIcon from '@mui/icons-material/MapOutlined';
import FileCopyOutlinedIcon from '@mui/icons-material/FileCopyOutlined';
import SchoolOutlinedIcon from '@mui/icons-material/SchoolOutlined';
import AttachFileOutlinedIcon from '@mui/icons-material/AttachFileOutlined';
import EmailOutlinedIcon from '@mui/icons-material/EmailOutlined';
import SupportAgentOutlinedIcon from '@mui/icons-material/SupportAgentOutlined';
import IndeterminateCheckBoxOutlinedIcon from '@mui/icons-material/IndeterminateCheckBoxOutlined';
import AttachEmailOutlinedIcon from '@mui/icons-material/AttachEmailOutlined';
import ConnectWithoutContactOutlinedIcon from '@mui/icons-material/ConnectWithoutContactOutlined';
import PhoneIphoneOutlinedIcon from '@mui/icons-material/PhoneIphoneOutlined';
import FolderCopyOutlinedIcon from '@mui/icons-material/FolderCopyOutlined';
import {useLocation} from "react-router-dom";
import ContactPageOutlinedIcon from '@mui/icons-material/ContactPageOutlined';

export const canAccess = (role: string, permissions: string[]) => {
    if (typeof permissions === 'undefined') {
        return false;
    }
    return permissions.includes(role) || permissions.includes('Super');
}

export const CustomMenu = () => {
    const [settingsOpen, setSettingsOpen] = useState(true);
    const [jobApplicationsOpen, setJobApplicationsOpen] = useState(true);
    const [jobsOpen, setJobsOpen] = useState(true);
    const [appOpen, setAppOpen] = useState(true);

    const iconStyle = {
        fontSize: '24px',
    }

    const [theme] = useTheme();
    // @ts-ignore
    const mainColor = theme.palette?.primary?.main ?? "#42C9AD"

    const activeStyle = {
        '&.RaMenuItemLink-active': {
            border: `2px solid ${mainColor}`,
            fontWeight: 600,
            color: mainColor,
        },
    }


    let location = useLocation();
    const subMenuStyleActive = {
        fontSize: 14,
        ...activeStyle,
        '& div': {
            minWidth: '34px !important',
        },
    }
    const subMenuStyle = {
        fontSize: 14,
        '& div': {
            minWidth: '34px !important',
        },
    }

    const {permissions} = usePermissions();
    const canAccess = (role: string) => {
        if(typeof permissions === 'undefined') {
            return false;
        }
        return permissions.includes(role) || permissions.includes('Super');
    }

    return <Menu>
        <Menu.Item to="/employer" primaryText="Projektek" leftIcon={<ApartmentOutlinedIcon/>} sx={activeStyle}/>
        <SubMenu
            handleToggle={() => setJobsOpen(!jobsOpen)}
            icon={<WorkOutlineOutlinedIcon style={iconStyle}/>}
            isOpen={jobsOpen}
            name={'Munkák'}
            dense>
            {/*<Menu.Item to="/job" primaryText="Munkák" leftIcon={<WorkOutlineOutlinedIcon style={iconStyle}/>}
                       sx={subMenuStyleActive}/>*/}
            <Menu.Item to="/category" primaryText="Kategóriák" leftIcon={<CategoryOutlinedIcon style={iconStyle}/>}
                       sx={subMenuStyleActive}/>
            <Menu.Item to="/area" primaryText="Térségek" leftIcon={<MapOutlinedIcon style={iconStyle}/>}
                       sx={subMenuStyleActive}/>
            {/*<Menu.Item to="/employer-contact-person" primaryText="Kapcsolattartók"
                       leftIcon={<ConnectWithoutContactOutlinedIcon style={iconStyle}/>}
                       sx={location.pathname.includes('/employer-contact-person') ? subMenuStyleActive : subMenuStyle}/>*/}
        </SubMenu>
        <SubMenu
            handleToggle={() => setJobApplicationsOpen(!jobApplicationsOpen)}
            icon={<GroupOutlinedIcon style={iconStyle}/>}
            isOpen={jobApplicationsOpen}
            name={'Munkavállalók'}
            dense>
            <Menu.Item to="/job-application" primaryText="Munkára jelentkezések"
                       leftIcon={<ArticleOutlinedIcon style={iconStyle}/>}
                       sx={location.pathname.includes('/job-application') ? subMenuStyleActive : subMenuStyle}/>
            <Menu.Item to="/employee" primaryText="Munkavállalók" leftIcon={<GroupOutlinedIcon style={iconStyle}/>} sx={subMenuStyleActive}/>
            <Menu.Item to="/employee-application" primaryText="Jelentkezők" leftIcon={<ContactPageOutlinedIcon style={iconStyle}/>} sx={subMenuStyleActive}/>
            <Menu.Item to="/job-subtraction-type" primaryText="Levonás típusok"
                       leftIcon={<IndeterminateCheckBoxOutlinedIcon style={iconStyle}/>}
                       sx={location.pathname.includes('/job-subtraction-type') ? subMenuStyleActive : subMenuStyle}
                       />
        </SubMenu>
        <SubMenu
            handleToggle={() => setAppOpen(!appOpen)}
            icon={<PhoneIphoneOutlinedIcon style={iconStyle}/>}
            isOpen={appOpen}
            name={'Alkalmazás'}
            dense>
            <Menu.Item to="/notification" primaryText="Értesítések" leftIcon={<CircleNotificationsOutlinedIcon style={iconStyle}/>}
                       sx={subMenuStyleActive}/>
            <Menu.Item to="/blog" primaryText="Blog bejegyzések" leftIcon={<FeedOutlinedIcon style={iconStyle}/>} sx={subMenuStyleActive}/>
            <Menu.Item to="/faq" primaryText="Gyakori kérdések" leftIcon={<HelpOutlineOutlinedIcon/>}
                       sx={subMenuStyleActive}/>
            <Menu.Item to="/static-page" primaryText="Statikus oldalak" leftIcon={<FileCopyOutlinedIcon style={iconStyle}/>}
                       sx={subMenuStyleActive}/>
            <Menu.Item to="/office" primaryText="Irodák" leftIcon={<HomeWorkOutlinedIcon style={iconStyle}/>}
                       sx={subMenuStyleActive}/>
            <Menu.Item to="/coworker" primaryText="Munkatársak" leftIcon={<SupportAgentOutlinedIcon style={iconStyle}/>}
                       sx={subMenuStyleActive}/>
            <Menu.Item to="/email-template" primaryText="E-mail sablonok" leftIcon={<EmailOutlinedIcon style={iconStyle}/>}
                       sx={subMenuStyleActive}/>
        </SubMenu>
        <Menu.Item to="/skill" primaryText="Képességek" leftIcon={<SchoolOutlinedIcon/>} sx={activeStyle}/>
        <Menu.Item to="/document" primaryText="Belsős dokumentumok" leftIcon={<FolderCopyOutlinedIcon/>} sx={activeStyle}/>
        {canAccess('Super') && <SubMenu
            handleToggle={() => setSettingsOpen(!settingsOpen)}
            icon={<SettingsOutlinedIcon style={iconStyle}/>}
            isOpen={settingsOpen}
            name={'Beállítások'}
            dense>
            <Menu.Item to="/administrator" primaryText="Felhasználók" sx={subMenuStyleActive}
                       leftIcon={<PersonOutlineOutlinedIcon style={iconStyle}/>}/>
            {/*<Menu.Item to="/system-params" primaryText="Rendszer paraméterek"
                       leftIcon={<DesignServicesOutlinedIcon style={iconStyle}/>}/>*/}
        </SubMenu>}
    </Menu>
};
