import * as React from "react";
import {
    CreateButton,
    Datagrid,
    DateField,
    ExportButton,
    FilterButton,
    FilterForm,
    List,
    TextField,
    TextInput,
    TopToolbar,
    useListContext,
    usePermissions
} from 'react-admin';
import {Stack} from "@mui/material";
import {apiUrl, httpClient} from "../dataProvider";
import {canAccess} from "../layout/custom-menu";
import {exportExcel} from "../export-excel";

const exporter = (values:any) => {
    const currentDate = new Date();
    const excelValues = values.map((v:any,index:number) => {
        return {
            'ID': v.id,
            'Név': v.name,
            'Adószám': v.taxNumber,
            'Székhely ország': v.headquarters.country,
            'Székhely ir.szám': v.headquarters.zip,
            'Székhely város': v.headquarters.city,
            'Székhely utca/házszám': v.headquarters.address,
            'Megjegyzés': v.canStartAt,
            'Behozta (ID)': v.canFinishAt,
            'Behozás ideje': v.employmentType,
        }
    });
    exportExcel({
        excelData: excelValues,
        fileName: `munkaadok-${currentDate.getFullYear()}_${currentDate.getMonth()+1}_${currentDate.getDate()}`
    }).then(r => {});
};
const employerFilters = [
    <TextInput source="query" label={"Kulcsszó"} name={"query"} fullWidth required/>
];

const CustomToolbar = () => {
    const { filterValues } = useListContext();
    let querystring = '';
    const keys = Object.keys(filterValues);
    keys.forEach((k:any) => {
        querystring += `&${k}=${filterValues[k]}`
    })
    const {permissions} = usePermissions();
    return <TopToolbar sx={{width: '100%', paddingTop: '20px'}}>
        <Stack direction={"column"} sx={{width: '100%'}}>
            <Stack direction="row"
                   justifyContent="flex-end"
                   alignItems={'center'}
                   sx={{width: '100%', paddingBottom: '5px'}}>
                <FilterForm filters={employerFilters}/>
                {canAccess('Super', permissions) && <ExportButton onClick={() => {
                    httpClient(`${apiUrl}/employer?export=true&page=1&size=1${querystring}`, {
                        method: 'GET',
                    }).then(({json}) => {
                        exporter(json.items)
                    })
                }
                } sx={{marginRight: '20px'}}/>}
                <div>
                    <FilterButton filters={employerFilters}/>
                </div>
                <CreateButton/>
            </Stack>
        </Stack>
    </TopToolbar>
}

export const EmployerList = () => (
    <List title={'Projektek'} actions={<CustomToolbar/>}>
        <Datagrid rowClick={'show'} bulkActionButtons={false}>
            <TextField source="id" label={"ID"}/>
            <TextField source="name" label={"Név"}/>
            <TextField source="taxNumber" label={"Adószám"}/>
            <DateField source="importedAt" locales={['hu-HU']} label={"Partner ekkortól"}/>
        </Datagrid>
    </List>
);