import * as React from "react";
import {Create, Edit, SimpleForm, TextInput} from 'react-admin';
import {
    ClearButtons,
    FormatButtons,
    LevelSelect,
    LinkButtons,
    ListButtons, QuoteButtons,
    RichTextInput,
    RichTextInputToolbar
} from "ra-input-rich-text";
import {MyImageButton} from "../layout/image-upload-button";

const StaticPageForm = () => {
    return <SimpleForm>
        <TextInput source="title" label={"Cím (kötelező)"} name={"title"} fullWidth required/>
        <TextInput source="url" label={"URL (kötelező)"} name={"url"} fullWidth required/>
        <RichTextInput source="content" label={"Tartalom (kötelező)"} name={"content"} fullWidth isRequired
                       toolbar={
                           <RichTextInputToolbar>
                               <LevelSelect/>
                               <FormatButtons/>
                               <ListButtons/>
                               <LinkButtons/>
                               <QuoteButtons/>
                               <ClearButtons/>
                               <MyImageButton/>
                           </RichTextInputToolbar>
                       }/>
    </SimpleForm>
}

export const StaticPageCreate = () => (
    <Create title={'Statikus oldal létrehozása'}>
        <StaticPageForm/>
    </Create>
);

export const StaticPageEdit = () => (
    <Edit title={'Statikus oldal szerkesztése'}>
        <StaticPageForm/>
    </Edit>
);