import {useEffect, useState} from "react";
import {apiUrl, httpClient} from "../dataProvider";
import {Divider, Grid, Stack, Typography} from "@mui/material";
import {Button, RaRecord, useNotify} from "react-admin";
import FileSaver from "file-saver";
import FileDownloadOutlinedIcon from "@mui/icons-material/FileDownloadOutlined";
import Box from "@mui/material/Box";
import DeleteOutlineOutlinedIcon from "@mui/icons-material/DeleteOutlineOutlined";
import * as React from "react";
import {DocumentDeleteWarningDialog} from "./document-delete-warning-dialog";

export interface EmployeeDocumentsListProps {
    record: RaRecord;
    refresh: boolean;
    setRefresh: any;
}

export const EmployeeDocumentsList = (props: EmployeeDocumentsListProps) => {
    const [documents, setDocuments] = useState([]);
    useEffect(() => {
        if (typeof props.record !== 'undefined') {
            httpClient(`${apiUrl}/employee/${props.record.id}/attachment?page=1&size=1000`, {
                method: 'GET',
            }).then(({json}) => {
                setDocuments(json.items);
            })
        }
    }, [props.record, props.refresh]);
    const notify = useNotify();
    const [isOpen, setIsOpen] = useState(false);
    const [currentRecord, setCurrentRecord] = useState(null);

    return <Grid container spacing={1} sx={{padding: '16px'}}>
        <Grid item xs={12}><Typography fontSize={18} fontWeight={700}>Korábbi dokumentumok</Typography></Grid>
        <Grid item xs={4}><Typography fontWeight={700}>Név</Typography></Grid>
        <Grid item xs={4}><Typography fontWeight={700}>Létrehozva</Typography></Grid>
        <Grid item xs={4}/>
        <Grid item xs={12}>
            <Divider sx={{width: '100%', borderBottomWidth: '2px'}}/>
        </Grid>
        {
            documents.map((d: any) => {
                const createdAtDate = new Date(d.createdAt);
                return <Grid item container>
                    <Grid item xs={4}
                          sx={{display: 'flex', alignItems: 'center'}}><Typography>{d.name}</Typography></Grid>
                    <Grid item xs={4} sx={{
                        display: 'flex',
                        alignItems: 'center'
                    }}><Typography>{createdAtDate.toLocaleDateString('hu')}</Typography></Grid>
                    <Grid item xs={4}>
                        <Stack direction={"row"} alignItems={"center"} justifyContent={"flex-end"}>
                            <Button label="Letöltés"
                                    onClick={() => {
                                        FileSaver.saveAs(
                                            d.path,
                                            `${d.name}`
                                        );
                                    }}
                                    variant={"contained"}
                                    startIcon={<FileDownloadOutlinedIcon style={{fontSize: '20px'}}/>}
                            />
                            <Box width={'25px'}/>
                            <Button label="Törlés" onClick={() => {
                                setCurrentRecord(d);
                                setIsOpen(true);
                            }}
                                    variant={"contained"}
                                    sx={{
                                        backgroundColor: '#ea1426',
                                        '&:hover': {
                                            backgroundColor: '#ea1426'
                                        }
                                    }}
                                    startIcon={<DeleteOutlineOutlinedIcon
                                        style={{fontSize: '20px'}}/>}
                            />
                        </Stack>
                    </Grid>
                    <Grid item xs={12}>
                        <Divider sx={{width: '100%', borderBottomWidth: '1px', marginTop: '10px'}}/>
                    </Grid>
                    <DocumentDeleteWarningDialog
                        isOpen={isOpen}
                        setIsOpen={setIsOpen}
                        record={currentRecord}
                        setRecord={setCurrentRecord}
                        refresh={props.refresh}
                        setRefresh={props.setRefresh}
                        employeeId={props.record.id}
                    />
                </Grid>
            })
        }

    </Grid>
}
