import * as React from "react";
import {Edit, SimpleForm, TextInput, SaveButton, Toolbar} from 'react-admin';
import {
    ClearButtons,
    FormatButtons,
    LevelSelect,
    LinkButtons,
    ListButtons, QuoteButtons,
    RichTextInput,
    RichTextInputToolbar
} from "ra-input-rich-text";
import {MyImageButton} from "../layout/image-upload-button";
import {Stack, Typography} from "@mui/material";

const CustomToolbar = (props: any) => (
    <Toolbar
        {...props}
        sx={{display: 'flex', justifyContent: 'space-between'}}
    >
        <SaveButton/>
    </Toolbar>
);

const EmailTemplateForm = () => (
    <SimpleForm toolbar={<CustomToolbar/>}>
        <Stack direction={'column'} sx={{marginBottom: '25px'}}>
            <Typography fontWeight={700} fontSize={18}>Magyarázat</Typography>
            <Typography>{`{{password}}`} - jelszó</Typography>
            <Typography>{`{{name}}`} - név</Typography>
            <Typography>{`https://app.biztoskesz.hu/{{url_slug}}?token={{token}}`} - applikációba irányító
                url</Typography>
            <Typography>{`{{address}}`} - pontos cím (pl.: munka helye)</Typography>
            <Typography>{`{{image}}`} - kep</Typography>
            <Typography>{`{{data}}`} - leírás</Typography>
            <Typography>{`{{start_date}}`} - kezdés időpontja (óra-perc)</Typography>
            <Typography>{`{{end_date}}`} - befejezés időpontja (óra-perc)</Typography>
            <Typography>{`{{title}}`} - cím</Typography>
            <Typography>{`{{content}}`} - értesítés tartalma</Typography>
            <Typography>{`https://app.biztoskesz.hu/auth/passwordReset?token={{token}}`} - jelszó helyreállítás
                url</Typography>
        </Stack>
        <TextInput source="subject" label={"Tárgy (kötelező)"} name={"subject"} fullWidth required/>
        <RichTextInput source="content" label={"Tartalom (kötelező)"} name={"content"} fullWidth isRequired
                       toolbar={
                           <RichTextInputToolbar>
                               <LevelSelect />
                               <FormatButtons />
                               <ListButtons />
                               <LinkButtons />
                               <QuoteButtons />
                               <ClearButtons />
                               <MyImageButton/>
                           </RichTextInputToolbar>
                       }
        />
    </SimpleForm>
)

export const EmailTemplateEdit = () => (
    <Edit title={'E-mail sablon szerkesztése'}>
        <EmailTemplateForm/>
    </Edit>
);
