import * as React from 'react';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import Box from "@mui/material/Box";
import {Stack} from "@mui/material";
import CloseOutlinedIcon from '@mui/icons-material/CloseOutlined';
import {PersonalScheduleGrid} from "./personal-schedule-grid";

interface Props {
    isOpen: boolean;
    setIsOpen: any;
    employee: any;
}

export const ScheduleEmployeePersonalScheduleDialog = (props: Props) => {
    const handleClose = () => {
        props.setIsOpen(false);
    };

    return <Dialog open={props.isOpen} onClose={handleClose} fullWidth
                   maxWidth="lg">
        <DialogTitle>
            <Stack direction={'row'} alignItems={'center'} justifyContent={'space-between'}>
                <Box>Munkavállaló beosztása</Box>
                <CloseOutlinedIcon sx={{cursor: 'pointer'}}
                                   onClick={() => {
                                       props.setIsOpen(false);
                                   }}
                />
            </Stack>
        </DialogTitle>
        <DialogContent>
            <PersonalScheduleGrid isOpen={props.isOpen} employee={props.employee}/>
        </DialogContent>
    </Dialog>;
}