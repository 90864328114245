import * as React from "react";
import {useState} from "react";
import {
    CreateButton,
    Datagrid,
    DateField,
    DateTimeInput,
    FilterButton,
    FilterForm,
    FunctionField,
    List,
    SelectInput,
    TextField,
    TextInput,
    TopToolbar
} from 'react-admin';
import {Button, Divider, IconButton, Stack} from "@mui/material";
import {BulkEmployeeApplicationDialog} from "./bulk-employee-application-dialog";
import FileUploadOutlinedIcon from '@mui/icons-material/FileUploadOutlined';
import {getEmployeeApplicationStatus} from "../enum-translates";
import {useNavigate} from "react-router-dom";
import EditOutlinedIcon from "@mui/icons-material/EditOutlined";
import {ToEmployeeApplicationDialog} from "./to-employee-application-dialog";
import {ToEmployeeWarningDialog} from "./to-employee-warning-dialog";
import Box from "@mui/material/Box";
import {dateParser, dateTimeFormatter} from "../date-formatter/date-formatter";
import {ExistingEmployeesDialog} from "./existing-employees-dialog";

const employeeFilters = [
    <TextInput source="query" label={"Keresés"} name={"query"} fullWidth alwaysOn/>,
    <SelectInput source="status" label={"Státusz"}
                 fullWidth
                 choices={[
                     {id: 'Initial', name: 'Feldolgozásra vár'},
                     {id: 'Disapproved', name: 'Elutasítva'},
                     {id: 'DidNotAnswer', name: 'Nem vette fel'},
                     {id: 'NeedsCallback', name: 'Vissza kell hívni'},
                     {id: 'Approved', name: 'Elfogadva'},
                 ]}/>,
    <TextInput source="campaign" label={"Kampány"} name={"campaign"} fullWidth/>,
    <DateTimeInput source="dateStart" label={"Jelentkezett (-tól)"}
                   name={"dateStart"} fullWidth alwaysOn
                   inputProps={{
                       max: '2099-12-31T23:59'
                   }}
                   format={dateTimeFormatter}
                   parse={dateParser}
    />,
    <DateTimeInput source="dateEnd" label={"Jelentkezett (-ig)"}
                   name={"dateEnd"} fullWidth alwaysOn
                   inputProps={{
                       max: '2099-12-31T23:59'
                   }}
                   format={dateTimeFormatter}
                   parse={dateParser}
    />,
];

const CustomToolbar = () => {
    const [isOpen, setIsOpen] = useState(false);
    const [existingIsOpen, setExistingIsOpen] = useState(false);
    const [existingIsEmployees, setExistingEmployees] = useState([{},{},{},{}]);

    return <TopToolbar sx={{width: '100%', paddingTop: '20px'}}>
        <Stack direction={"column"} sx={{width: '100%'}}>
            <Stack direction="row"
                   justifyContent="flex-end"
                   alignItems={'center'}
                   sx={{width: '100%', paddingBottom: '5px'}}>
                <FilterForm filters={employeeFilters}/>
                <div>
                    <FilterButton filters={employeeFilters}/>
                </div>
                <Divider orientation={'vertical'} style={{height: '30px'}} sx={{borderRightWidth: 2}}/>
                <Button onClick={() => setIsOpen(true)} startIcon={<FileUploadOutlinedIcon/>}
                        style={{margin: '0px 30px'}}>
                    Csoportos feltöltés
                </Button>
                <Divider orientation={'vertical'} style={{height: '30px', marginRight: '10px'}}
                         sx={{borderRightWidth: 2}}/>
                <CreateButton/>
            </Stack>
        </Stack>
        <BulkEmployeeApplicationDialog isOpen={isOpen}
                                       setIsOpen={setIsOpen}
                                       setExistingEmployeesOpen={setExistingIsOpen}
                                       setExistingEmployees={setExistingEmployees}/>
        <ExistingEmployeesDialog isOpen={existingIsOpen}
                                 setIsOpen={setExistingIsOpen}
                                 existingEmployees={existingIsEmployees}
                                 setExistingEmployees={setExistingEmployees}
        />
    </TopToolbar>
}

export const EmployeeApplicationList = () => {
    const navigate = useNavigate();
    const [isEmailRequiredOpen, setIsEmailRequiredOpen] = useState(false);
    const [isWarningOpen, setIsWarningOpen] = useState(false);
    const [currentRecord, setCurrentRecord] = useState(null);
    return <List title={'Jelentkezők'} actions={<CustomToolbar/>}>
        <>
            <Datagrid bulkActionButtons={false}>
                <TextField source="name" label={"Név"}/>
                <TextField source="phone" label={"Telefonszám"} sx={{whiteSpace: "nowrap"}}/>
                <TextField source="email" label={"E-mail"}/>
                <TextField source="campaign" label={"Kampány"}/>
                <DateField source="appliedAt" locales={['hu-HU']} label={"Jelentkezett"}/>
                <FunctionField
                    label="Státusz"
                    render={(record: any) => `${getEmployeeApplicationStatus(record.status)}`}
                />
                <FunctionField
                    label="Átrakás"
                    render={(record: any) => <Button variant={'contained'}
                                                     sx={{width: '85px'}}
                                                     onClick={async () => {
                                                         if (record.email === "" || record.email === null) {
                                                             setCurrentRecord(record);
                                                             setIsEmailRequiredOpen(true);
                                                             return;
                                                         } else {
                                                             setCurrentRecord(record);
                                                             setIsWarningOpen(true);
                                                             return;
                                                         }
                                                     }}>
                        Felvétel
                    </Button>}
                />
                <FunctionField
                    label="Szerk"
                    render={(record: any) => <Box sx={{display: 'flex', justifyContent: 'center', maxWidth: '70px'}}>
                        <IconButton color={'primary'}
                                    onClick={() => navigate(`/employee-application/${record.id}/edit`)}>
                            <EditOutlinedIcon/>
                        </IconButton>
                    </Box>}
                />
            </Datagrid>
            <ToEmployeeApplicationDialog record={currentRecord}
                                         setRecord={setCurrentRecord}
                                         isOpen={isEmailRequiredOpen}
                                         setIsOpen={setIsEmailRequiredOpen}/>
            <ToEmployeeWarningDialog record={currentRecord}
                                     setRecord={setCurrentRecord}
                                     isOpen={isWarningOpen}
                                     setIsOpen={setIsWarningOpen}/>
        </>
    </List>
};
