import * as React from "react";
import {Chip, MenuItem, Menu, ListItemIcon, ListItemText} from "@mui/material";
import HelpOutlineOutlinedIcon from '@mui/icons-material/HelpOutlineOutlined';
import CheckCircleOutlineOutlinedIcon from '@mui/icons-material/CheckCircleOutlineOutlined';
import CancelOutlinedIcon from '@mui/icons-material/CancelOutlined';
import LinearScaleOutlinedIcon from '@mui/icons-material/LinearScaleOutlined';
import EditOutlinedIcon from '@mui/icons-material/EditOutlined';
import DeleteOutlineOutlinedIcon from '@mui/icons-material/DeleteOutlineOutlined';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import {useEffect, useState} from "react";
import {useGetOne, useNotify} from "react-admin";
import CalendarMonthOutlinedIcon from '@mui/icons-material/CalendarMonthOutlined';
import {apiUrl, httpClient} from "../../../dataProvider";
import {ScheduleEmployeeInfoDialog} from "../schedule-employee-info-dialog";
import Box from "@mui/material/Box";
import {ScheduleEmployeePersonalScheduleDialog} from "./personal-schedule/schedule-employee-personal-schedule-dialog";

export interface ScheduleWorkerChipProp {
    scheduleItem: any;
    setRecord: any;
    setIsOpen: any;
    refetch: boolean
    setRefetch: any
}

export const ScheduleWorkerChip = (props: ScheduleWorkerChipProp) => {
    const notify = useNotify();
    const scheduleItem = props.scheduleItem;
    const chipIcon = scheduleItem.status === 'Completed' ? <CheckCircleOutlineOutlinedIcon/> :
        scheduleItem.status === 'InProgress' ? <HelpOutlineOutlinedIcon/> : <CancelOutlinedIcon/>;

    const chipColor = scheduleItem.status === 'Completed' ? '#12A594' :
        scheduleItem.status === 'InProgress' ? 'rgba(2, 192, 151, 0.095)' : '#E5484D';

    const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
    const open = Boolean(anchorEl);
    const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(null);
    };

    const startDate = new Date(`${scheduleItem.start}`)
    const endDate = new Date(`${scheduleItem.end}`)
    const handleEdit = () => {
        props.setRecord({
            id: scheduleItem.id,
            start: `${scheduleItem.start}`,
            end: `${scheduleItem.end}`,
            status: scheduleItem.status,
            employmentName: scheduleItem.employmentName,
            jobApplicationId: scheduleItem.jobApplicationId,
        });
        props.setIsOpen(true);
        setAnchorEl(null);
    };
    const handleDelete = () => {
        httpClient(`${apiUrl}/schedule/${scheduleItem.id}`, {
            method: 'DELETE',
        }).then(r => {
            if (r.status === 204) {
                notify(`Beosztott eltávolítva`, {type: 'success'});
            } else {
                notify(`Hiba történt a beosztott eltávolításakor`, {type: 'error'});
            }
        })
        setAnchorEl(null);
        props.setRefetch(!props.refetch);
    };

    const [isScheduleOpen, setIsScheduleOpen] = useState(false);
    const handleEmployeeSchedule = () => {
        setIsScheduleOpen(true)
        setAnchorEl(null);
    };

    const startDateString = startDate.toLocaleTimeString('hu', {hour: '2-digit', minute: '2-digit'});
    const endDateString = endDate.toLocaleTimeString('hu', {hour: '2-digit', minute: '2-digit'});

    const [application, setApplication]: any = useState();
    useEffect(() => {
        if (typeof application === 'undefined') {
            httpClient(`${apiUrl}/job-application/${props.scheduleItem.jobApplicationId}`, {
                method: 'GET',
            }).then(({json}) => {
                setApplication(json);
                // setFetchedItems(true);
            })
        }
    }, [props.refetch])

    const [workerData, setWorkerData]: any = useState();
    useEffect(() => {
        if (typeof application !== 'undefined' && typeof workerData === 'undefined') {
            // @ts-ignore
            httpClient(`${apiUrl}/employee/${application.employeeId}`, {
                method: 'GET',
            }).then(({json}) => {
                setWorkerData(json);
                // setFetchedItems(true);
            })
        }
    }, [application])

    const [isInfoOpen, setIsInfoOpen] = useState(false);
    const handleShowInfo = () => {
        setIsInfoOpen(true);
        setAnchorEl(null);
    };
    const { data: job, isLoading, error } = useGetOne('job', { id: (application && application.hasOwnProperty('jobId')) ? application.jobId : -1});
    if (isLoading) { return <Box />; }
    if (error) { return <p>ERROR</p>; }
    return <>
        <Chip icon={chipIcon}
              label={`${workerData ? workerData.name : ''} (${startDateString} - ${endDateString}) - ${job ? job.name : ''}`}
              variant={'filled'}
              sx={{
                  background: `${chipColor}`,
                  transition: 'all 0.4s',
                  '&:hover': {
                      background: `${chipColor}`,
                      transform: 'scale(98%)',
                  },
                  margin: '5px 7px',
                  color: 'white',
              }}
              deleteIcon={<LinearScaleOutlinedIcon/>}
              onDelete={handleClick}
        />
        <ScheduleEmployeeInfoDialog employee={workerData} isOpen={isInfoOpen} setIsOpen={setIsInfoOpen}/>
        <ScheduleEmployeePersonalScheduleDialog employee={workerData} isOpen={isScheduleOpen} setIsOpen={setIsScheduleOpen}/>
        <Menu
            id="basic-menu"
            anchorEl={anchorEl}
            open={open}
            onClose={handleClose}
            MenuListProps={{
                'aria-labelledby': 'basic-button',
            }}
            anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'left',
            }}
            transformOrigin={{
                vertical: 'top',
                horizontal: 'left',
            }}
        >
            <MenuItem onClick={handleShowInfo}>
                <ListItemIcon>
                    <InfoOutlinedIcon fontSize="small"/>
                </ListItemIcon>
                <ListItemText>Munkavállaló adatai</ListItemText>
            </MenuItem>
            <MenuItem onClick={handleEmployeeSchedule}>
                <ListItemIcon>
                    <CalendarMonthOutlinedIcon fontSize="small"/>
                </ListItemIcon>
                <ListItemText>Munkavállaló beosztása</ListItemText>
            </MenuItem>
            <MenuItem onClick={handleEdit}>
                <ListItemIcon>
                    <EditOutlinedIcon fontSize="small"/>
                </ListItemIcon>
                <ListItemText>Szerkesztés</ListItemText>
            </MenuItem>
            <MenuItem onClick={handleDelete}>
                <ListItemIcon>
                    <DeleteOutlineOutlinedIcon fontSize="small"/>
                </ListItemIcon>
                <ListItemText>Törlés</ListItemText>
            </MenuItem>
        </Menu>
    </>
};