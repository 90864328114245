import * as React from "react";
import {Create, Edit, SelectInput, SimpleForm, TextInput} from 'react-admin';

const AttachmentTypeForm = () => (
    <SimpleForm>
        <TextInput source="name" label={"Név (kötelező)"} name={"name"} required/>
        <SelectInput source="scope" label={"Csatolás (kötelező)"}
                     required
                     choices={[
                         {id: 'Employee', name: 'Munkavállaló'},
                         {id: 'Job', name: 'Munka'},
                     ]}/>
    </SimpleForm>
)

export const AttachmentTypeCreate = () => (
    <Create title={'Csatolmány típus létrehozása'}>
        <AttachmentTypeForm/>
    </Create>
);

export const AttachmentTypeEdit = () => (
    <Edit title={'Csatolmány típus szerkesztése'}>
        <AttachmentTypeForm/>
    </Edit>
);
