import * as React from "react";
import {Datagrid, List, TextField, TextInput} from 'react-admin';

const emailTemplateFilters = [
    <TextInput source="query" label={"Kulcsszó"} name={"query"} fullWidth required/>
];

export const EmailTemplateList = () => (
    <List title={'E-mail sablonok'} filters={emailTemplateFilters} exporter={false}>
        <Datagrid rowClick="show" bulkActionButtons={false}>
            <TextField source="id" label={"ID"}/>
            <TextField source="subject" label={"Tárgy"}/>
        </Datagrid>
    </List>
);