import * as React from 'react';
import {EditButton, ReferenceField, Show, SimpleShowLayout, TextField, TopToolbar, useShowContext} from 'react-admin';

const AttachmentShowActions = () => (
    <TopToolbar>
        <EditButton/>
    </TopToolbar>
);

const AttachmentShowLayout = () => {
    const {
        error,
        isLoading,
        record,
    } = useShowContext();

    if (isLoading) {
        return <div>Loading...</div>;
    }
    if (error) {
        return <div>Error!</div>;
    }
    return (
        <SimpleShowLayout>
            <TextField source="id" label={"ID"}/>
            <TextField source="name" label={"Név"}/>
            <TextField source="path" label={"Elérési útvonal"}/>
            <ReferenceField source="typeId" reference="attachment-type" label={"Csatolmány típus"}>
                <TextField source="name" />
            </ReferenceField>
        </SimpleShowLayout>
    );
};


export const AttachmentShow = () => {
    return <Show title={'Csatolmány'}>
        <AttachmentShowLayout/>
    </Show>;
};
