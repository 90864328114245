import * as React from "react";
import {Card, CardContent} from '@mui/material';
import {PasswordInput, SimpleForm, Title, useNotify} from 'react-admin';
import {config} from "../config";
import {getJwtHeader} from "../auth/authProvider";

const validatePasswordChange = (values: any) => {
    const errors = {};
    if (values.newPassword !== values.newPasswordAgain) {
        //@ts-ignore
        errors.newPasswordAgain = 'A két jelszó nem egyezik meg';
    }
    return errors
};

const PasswordChangePage = () => {
    const notify = useNotify();
    return <Card>
        <Title title="Jelszó változtatás"/>
        <CardContent>
            <SimpleForm validate={validatePasswordChange}
                        onSubmit={async (values) => {
                            await fetch(`${config.apiUrl}/auth/change-password`, {
                                method: 'POST',
                                headers: {
                                    'Content-Type': 'application/json',
                                    'Authorization': getJwtHeader()
                                },
                                body: JSON.stringify({
                                    oldPassword: values.oldPassword,
                                    newPassword: values.newPassword,
                                    isAdmin: true
                                })
                            }).then(async res => {
                                if (!res.ok) {
                                    await res.text().then(text => {
                                        notify(text, {type: 'error'});
                                    })
                                } else {
                                    notify(`Sikeres jelszó változtatás!`, {type: 'success'});
                                }
                            })
                        }}>
                <PasswordInput source="oldPassword" label={"Jelenlegi jelszó"} name={"oldPassword"}/>
                <PasswordInput source="newPassword" label={"Új jelszó"} name={"newPassword"}/>
                <PasswordInput source="newPasswordAgain" label={"Új jelszó mégegyszer"} name={"newPasswordAgain"}/>
            </SimpleForm>
        </CardContent>
    </Card>
};

export default PasswordChangePage;