import {Divider, Grid, Typography} from "@mui/material";
import * as React from "react";
import {ScheduleGridRow} from "./schedule-grid-row";

export interface ScheduleGridProp {
    scheduleItems: any
    applications: any
    timeTableRefresh: boolean
    setTimeTableRefresh: any
    establishmentId: number
}

export const ScheduleGrid = (props: ScheduleGridProp) => {
    return <Grid container spacing={1}>
        <Grid item xs={2}>
            <Typography fontSize={14} fontWeight={600}>Dátum</Typography>
        </Grid>
        <Grid item xs={4}>
            <Typography fontSize={14} fontWeight={600}>Beosztottak</Typography>
        </Grid>
        <Grid item xs={5}>
            <Typography fontSize={14} fontWeight={600}>Munkavállalók</Typography>
        </Grid>
        <Grid item xs={1}>
            <Typography fontSize={14} fontWeight={600}>Törlés</Typography>
        </Grid>
        <Grid item xs={12}>
            <Divider sx={{width: '100%', borderBottomWidth: '3px'}}/>
        </Grid>
        {typeof props.scheduleItems !== 'undefined' && props.scheduleItems.map((s: any, index: number) => {
            return <ScheduleGridRow key={`${s.id} - ${index}`}
                                    scheduleTimeTable={s}
                                    applications={props.applications}
                                    timeTableRefresh={props.timeTableRefresh}
                                    setTimeTableRefresh={props.setTimeTableRefresh}
                                    establishmentId={props.establishmentId}
            />
        })}
    </Grid>
}