import * as React from 'react';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';

interface Props {
    isOpen: boolean;
    setIsOpen: any;
    existingEmployees: any[];
    setExistingEmployees: any;
}

export const ExistingEmployeesDialog = (props: Props) => {
    const handleClose = () => {
        props.setIsOpen(false);
        props.setExistingEmployees([])
    };

    const style:any = {
        padding: '8px',
        textAlign: 'center',
    }

    return <Dialog open={props.isOpen} onClose={handleClose} fullWidth
                   maxWidth="md">
        <DialogTitle>Az alábbi jelentkezők nem lettek feltöltve</DialogTitle>
        <DialogContent>
            <table width={'100%'} style={{
                display: 'block',
                height: '500px',
                overflowY: 'scroll',
            }}>
                <tr>
                    <th style={style}>Név</th>
                    <th style={style}>E-mail</th>
                    <th style={style}>Telefonszám</th>
                    <th style={style}>Kampány</th>
                    <th style={style}>Jelentkezés ideje</th>
                </tr>
                {props.existingEmployees.map((e) => {
                    return (
                        <tr>
                            <td style={style}>{e.name}</td>
                            <td style={style}>{e.email}</td>
                            <td style={style}>{e.phone}</td>
                            <td style={style}>{e.campaign}</td>
                            <td style={style}>{e.appliedAt}</td>
                        </tr>)
                })}
            </table>
        </DialogContent>
    </Dialog>;
}
