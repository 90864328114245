import * as React from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import {useNotify, useRefresh} from "react-admin";
import Box from "@mui/material/Box";
import {apiUrl, httpClient} from "../dataProvider";
import {Stack, Typography} from "@mui/material";

interface Props {
    isOpen: boolean;
    setIsOpen: any;
    record: any;
    setRecord: any;
}

export const ToEmployeeWarningDialog = (props: Props) => {
    const handleClose = () => {
        props.setRecord(null)
        props.setIsOpen(false);
    };

    const notify = useNotify();
    const refresh = useRefresh();

    return <Dialog open={props.isOpen} onClose={handleClose} fullWidth
                   maxWidth="xs">
        <DialogTitle>Jelentkező felvétele</DialogTitle>
        <DialogContent>
            <Typography>Biztosan felveszed őt munkavállalónak: <br/><b>{props.record?.name}?</b></Typography>
            <Box sx={{height: '25px'}}/>
            <Stack direction={'row'} alignItems={'center'} justifyContent={"space-between"}>
                <Button variant={'contained'}
                        color={"error"}
                        sx={{width: '150px'}}
                        onClick={handleClose}>
                    Mégsem
                </Button>
                <Button variant={'contained'}
                        sx={{width: '150px'}}
                        onClick={async () => {
                            await httpClient(`${apiUrl}/employee-application/${props.record.id}/toEmployee`, {
                                method: 'POST',
                                body: JSON.stringify({})
                            }).then(r => {
                                if (r.status === 200) {
                                    handleClose()
                                    refresh();
                                    notify(`Felvéve munkavállalókhoz`, {type: 'success'});
                                } else {
                                    notify(`Hiba történt`, {type: 'error'});
                                }
                            }).catch(error => {
                                const errorMsg = error?.body.toString();
                                notify(errorMsg === 'Duplicate email address' ? 'Ez az e-mail cím már használatban van' : 'Hiba történt', {type: 'error'});
                            })
                        }}>
                    Igen
                </Button>
            </Stack>
        </DialogContent>
    </Dialog>;
}
