export interface Config {
    NODE_ENV: string;
    apiUrl: string;
}

if (!process.env.REACT_APP_API_URL && !process.env.NX_REACT_APP_API_URL) {
    throw Error('No REACT_APP_API_URL env variable found.' +
        'If you are in development mode, make sure you copy .env.default to .env, and configure tha application.');
}

export const config: Config = {
    NODE_ENV: process.env.NODE_ENV,
    apiUrl: process.env.REACT_APP_API_URL || process.env.NX_REACT_APP_API_URL || "",
};
