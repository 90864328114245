const dateFormatRegex = /^\d{4}-\d{2}-\d{2}$/;

const convertDateToString = (value:any) => {
    if (!(value instanceof Date) || isNaN(value.getDate())) return '';
    const pad = '00';
    const yyyy = value.getFullYear().toString();
    const MM = (value.getMonth() + 1).toString();
    const dd = value.getDate().toString();
    const hh = (value.getHours() < 10 ? `0${value.getHours().toString()}` : value.getHours().toString());
    const min = (value.getMinutes() < 10 ? `0${value.getMinutes().toString()}` : value.getMinutes().toString());
    return `${yyyy}-${(pad + MM).slice(-2)}-${(pad + dd).slice(-2)}T${hh}:${min}`;
};

export const dateTimeFormatter = (v: any) => {
    if (v == null || v === '') return '';
    if (v instanceof Date) return convertDateToString(v);
    if (dateFormatRegex.test(v)) return v;
    const newDate = v.includes('Z') ? v.substring(0,16) : v;

    return convertDateToString(new Date(newDate));
};

export const dateParser = (v: any) => {
    const regexp = /(\d{4})-(\d{2})-(\d{2})(T|\s)(\d{2}):(\d{2})/
    let match = regexp.exec(v)
    if (match === null) return

    let year = match[1]
    let month = match[2]
    let day = match[3]
    let hour = match[5]
    let min = match[6]

    const date = [year, month, day].join('-')
    const time = [hour, min].join(':')
    const result = date + 'T' + time + ':00'
    return result
}
