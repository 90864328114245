export const getTemplateTypeName = (type: string) => {
    switch(type) {
        case "info":
            return "Információ"
        case "error":
            return "Hiba"
        case "warning":
            return "Figyelmeztetés"
        case "success":
            return "Siker"
        default:
            return "-"
    }
}

// employment

export const getEmploymentTypeName = (type: string) => {
    switch(type) {
        case "Occasional":
            return "Alkalmi"
        case "PartTime":
            return "Részmunkaidő"
        case "FullTime":
            return "Teljes munkaidő"
        default:
            return "-"
    }
}

// employee

export const getEmployeeStatus = (type: string) => {
    switch(type) {
        case "Active":
            return "Aktív"
        case "Inactive":
            return "Inaktív"
        case "Blacklisted":
            return "Feketelistás"
        default:
            return "-"
    }
}

export const getEmployeeTypeName = (type: string) => {
    switch(type) {
        case "Adult":
            return "Felnőtt"
        case "Student":
            return "Diák"
        case "Retired":
            return "Nyugdíjas"
        case "Disabled":
            return "Deaktivált"
        default:
            return "-"
    }
}

export const getEmployeeGender = (type: string) => {
    switch(type) {
        case "Male":
            return "Férfi"
        case "Female":
            return "Nő"
        default:
            return "-"
    }
}

export const getEmployeeBankAccountOwner = (type: string) => {
    switch(type) {
        case "Own":
            return "Saját"
        case "SomebodyElse":
            return "Más tulajdonos"
        default:
            return "-"
    }
}

// job

export const getJobStatus = (type: string) => {
    switch(type) {
        case "NotApplied":
            return "Nem jelentkezett"
        case "Applied":
            return "Jelentkezett"
        case "Rejected":
            return "Elutasítva"
        case "Accepted":
            return "Elfogadva"
        case "Completed":
            return "Befejezve"
        case "Failed":
            return "Hibás"
        default:
            return "-"
    }
}

// skill

export const getSkillType = (type: string) => {
    switch(type) {
        case "Soft":
            return "Személyiségi jellemző"
        case "Hard":
            return "Képesítés"
        default:
            return "-"
    }
}

// attachment-type

export const getAttachmentTypeScope = (type: string) => {
    switch(type) {
        case "Employee":
            return "Munkavállaló"
        case "Job":
            return "Munka"
        default:
            return "-"
    }
}

// job-application

export const getJobApplicationStatus = (type: string) => {
    switch(type) {
        case "Active":
            return "Aktív"
        case "Inactive":
            return "Inaktív"
        case "NotYetDecided":
            return "Döntés alatt"
        case "WaitingForEmailVerification":
            return "E-mail megerősítésre vár"
        default:
            return "-"
    }
}

export const getJobApplicationEmploymentType = (type: string) => {
    switch(type) {
        case "White":
            return "Bejelentve"
        case "Black":
            return "Nincs bejelentve"
        default:
            return "-"
    }
}

export const getJobApplicationSuccessStatus = (type: boolean) => {
    switch(type) {
        case true:
            return "Sikeresen elvégezve"
        case false:
            return "Nem jelent meg"
        default:
            return "-"
    }
}

export const getJobApplicationSalaryType = (type: string) => {
    switch(type) {
        case "Fix":
            return "Fix"
        case "HourlyWage":
            return "Órabér"
        default:
            return "-"
    }
}

export const getScheduleStatus = (type: string) => {
    switch(type) {
        case "Completed":
            return "Megjelent"
        case "InProgress":
            return "Folyamatban"
        case "NotCompleted":
            return "Nem jelent meg"
        default:
            return "-"
    }
}

export const getEmployeeApplicationStatus = (type: string) => {
    switch(type) {
        case "Initial":
            return "Feldolgozásra vár"
        case "Disapproved":
            return "Elutasítva"
        case "DidNotAnswer":
            return "Nem vette fel"
        case "NeedsCallback":
            return "Vissza kell hívni"
        case "Approved":
            return "Elfogadva"
        default:
            return "-"
    }
}