import * as React from "react";
import {ImageField, ImageInput, SimpleForm, TextInput, useNotify, SaveButton, Toolbar} from 'react-admin';
import {Box, Grid, Typography} from "@mui/material";
import myDataProvider from "../data-provider-with-image-convert";
import dataProvider from "../dataProvider";

interface ContactCreateProps {
    establishmentId: number;
    onSuccess: any;
    isEdit: boolean;
    record: any;
}

const ContactEditToolbar = (props:any) => (
    <Toolbar {...props} >
        <SaveButton />
    </Toolbar>
);

export const ContactPersonForm = (props: ContactCreateProps) => {
    const notify = useNotify();

    return <SimpleForm
        toolbar={<ContactEditToolbar/>}
        record={props?.isEdit ? props.record : null}
        onSubmit={async (values) => {
            const neededValues = {
                establishmentId: props.establishmentId,
                name: values.name,
                email: values.email,
                phone: values.phone,
                position: values.position,
                facebookLink: values.facebookLink,
                profilePicture: values.profilePicture,
                note: values.note,
            }
            if (!props.isEdit) {
                await myDataProvider.create('employer-contact-person', {data: neededValues}).then(res => {
                    if (res.data) {
                        notify(`Sikeres létrehozás`, {type: 'success'});
                        props.onSuccess();
                    } else {
                        notify(`Hiba történt`, {type: 'error'});
                    }
                })
            } else {
                await dataProvider.update('employer-contact-person', {
                    id: props.record.id,
                    data: neededValues
                }).then(res => {
                    if (res.data) {
                        notify(`Sikeres módosítás`, {type: 'success'});
                        props.onSuccess();
                    } else {
                        notify(`Hiba történt`, {type: 'error'});
                    }
                })
            }
        }}
    >
        <Grid container item xs={12} spacing={2}>
            <Grid item xs={6}>
                <TextInput source="name" label={"Név (kötelező)"} name={"name"} required fullWidth/>
            </Grid>
            <Grid item xs={6}>
                <TextInput source="email" label={"Email (kötelező)"} name={"email"} required fullWidth/>
            </Grid>
        </Grid>
        <Grid container item xs={12} spacing={2}>
            <Grid item xs={6}>
                <TextInput source="phone" label={"Telefonszám (kötelező)"} name={"phone"} required fullWidth/>
            </Grid>
            <Grid item xs={6}>
                <TextInput source="position" label={"Pozíció"} name={"position"} fullWidth/>
            </Grid>
        </Grid>
        <Grid container item xs={12} spacing={2}>
            <Grid item xs={6}>
                <TextInput source="facebookLink" label={"Facebook link (pl.: https://m.me/biztos.kesz)"} name={"facebookLink"} fullWidth/>
            </Grid>
        </Grid>
        <Grid container item xs={12} spacing={2}>
            <Grid item xs={12}>
                <TextInput source="note" label={"Megjegyzés"} name={"note"} fullWidth/>
            </Grid>
        </Grid>
        {(props.record && props.record.hasOwnProperty("profilePicture")) && props.record.profilePicture !== null && <Box>
            <Typography sx={{fontSize: '0.75em', color: '#B2BAC2', marginBottom: '0.2em'}}>Jelenlegi
                profilkép</Typography>
            <img src={`${props.record.profilePicture}`} alt={"current profile picture"}
                 style={{margin: '10px 0px', maxHeight: '200px'}}/>
        </Box>
        }
        <ImageInput source="profilePicture" label="Profil kép" accept="image/*" placeholder={<p>Kép megadása</p>}
                    name={"profilePicture"}>
            <ImageField source="src" title="title"/>
        </ImageInput>
    </SimpleForm>
}
