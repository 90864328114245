import * as React from 'react';
import {useEffect, useState} from 'react';
import {
    DateField,
    FunctionField,
    ReferenceField,
    Show,
    SimpleShowLayout,
    Tab,
    TabbedShowLayout,
    TextField,
    useShowContext
} from 'react-admin';
import {Divider, Grid, Typography} from "@mui/material";
import {apiUrl, httpClient} from "../dataProvider";
import {useNavigate} from "react-router-dom";
import Box from "@mui/material/Box";

const EmployerShowLayout = () => {
    const {
        record
    } = useShowContext();
    const [jobs, setJobs]: any = useState();
    useEffect(() => {
        if (typeof jobs === 'undefined' && typeof record !== 'undefined') {
            httpClient(`${apiUrl}/job?employerId=${record.id}&page=1&size=100`, {
                method: 'GET',
            }).then(({json}) => {
                setJobs(json.items);
            })
        }
    }, []);

    const [establishments, setEstablishments]: any = useState();
    const [fetchEst, setFetchEst] = useState(false);
    useEffect(() => {
        if (typeof establishments === 'undefined' && typeof record !== 'undefined') {
            httpClient(`${apiUrl}/establishment?employerId=${record.id}&page=1&size=100`, {
                method: 'GET',
            }).then(({json}) => {
                setEstablishments(json.items);
                setFetchEst(true);
            })
        }
    }, []);

    const navigate = useNavigate();

    return <Box>
        {record && <Typography sx={{paddingLeft: '18px', paddingTop: '15px'}} fontSize={24}
                               fontWeight={700}>
            {record.name}
        </Typography>}
        <TabbedShowLayout>
            <Tab label="Telephelyek">
                {fetchEst && typeof establishments !== 'undefined' && establishments.length === 0 ?
                    <Typography fontSize={16}>Nincsenek telephelyek</Typography> :
                    <>
                        <Grid container spacing={1}>
                            <Grid item xs={6}>
                                <Typography fontSize={14} fontWeight={600}>Név</Typography>
                            </Grid>
                            <Grid item xs={6}>
                                <Typography fontSize={14} fontWeight={600}>Cím</Typography>
                            </Grid>
                            <Grid item xs={12}>
                                <Divider sx={{width: '100%', borderBottomWidth: '3px'}}/>
                            </Grid>
                            {/*//@ts-ignore*/}
                            {typeof establishments !== 'undefined' && establishments.map((s, index) => {
                                return <Grid item xs={12} container spacing={1}
                                             sx={{
                                                 cursor: 'pointer',
                                             }}
                                             onClick={() => navigate(`/establishment/${s.id}/show`)}>
                                    <Grid item xs={6}>
                                        <FunctionField
                                            render={() => `${s.name}`}
                                        />
                                    </Grid>
                                    <Grid item xs={6}>
                                        <FunctionField
                                            label=""
                                            render={() => `${s.address.country}, ${s.address.zip}, ${s.address.city}, ${s.address.address}`}
                                        />
                                    </Grid>
                                    <Grid item xs={12}>
                                        <Divider sx={{width: '100%'}}/>
                                    </Grid>
                                </Grid>
                            })}
                        </Grid>
                    </>
                }
            </Tab>
            <Tab label="Alap információk">
                <Grid container spacing={0}>
                    <Grid item xs={12}>
                        <SimpleShowLayout>
                            <TextField source="id" label={"ID"}/>
                            <TextField source="name" label={"Név"}/>
                            <TextField source="taxNumber" label={"Adószám"}/>
                            <FunctionField
                                label="Telephely"
                                render={(record: any) => `${record.headquarters.country}, ${record.headquarters.zip}, ${record.headquarters.city}, ${record.headquarters.address}`}
                            />
                        </SimpleShowLayout>
                    </Grid>
                    <Grid container item xs={12}>
                        <Grid item xs={6}>
                            <SimpleShowLayout>
                                <ReferenceField source="importedBy"
                                                reference="administrator"
                                                label={"Behozta"}
                                >
                                    <TextField source="name"/>
                                </ReferenceField>
                                <DateField source="importedAt" locales={['hu-HU']} label={"Partner ekkortól"}/>
                                <TextField source="note" label={"Megjegyzés"}/>
                            </SimpleShowLayout>
                        </Grid>
                    </Grid>
                </Grid>
            </Tab>
        </TabbedShowLayout>
    </Box>;
};

export const EmployerShow = () => {
    return <Show title={'Projekt'}>
        <EmployerShowLayout/>
    </Show>;
};
