import * as React from "react";
import {useEffect, useState} from "react";
import {
    AutocompleteArrayInput,
    AutocompleteInput,
    BooleanInput,
    Create,
    DateTimeInput,
    FormTab,
    ImageField,
    ImageInput,
    NumberInput,
    ReferenceArrayInput,
    ReferenceInput,
    SaveButton,
    SelectArrayInput,
    SelectInput,
    TabbedForm,
    TextInput,
    Toolbar,
    useNotify
} from 'react-admin';
import {Box, Grid, Typography} from "@mui/material";
import {
    ClearButtons,
    FormatButtons,
    LevelSelect,
    LinkButtons,
    ListButtons,
    QuoteButtons,
    RichTextInput,
    RichTextInputToolbar
} from "ra-input-rich-text";
import {MyImageButton} from "../layout/image-upload-button";
import {apiUrl, httpClient} from "../dataProvider";
import {dateParser, dateTimeFormatter} from "../date-formatter/date-formatter";
import myDataProvider from "../data-provider-with-image-convert";

interface JobCreateProps {
    establishmentId: number;
    onSuccess: any;
    isEdit: boolean;
    record: any;
}

const JobEditToolbar = (props: any) => (
    <Toolbar {...props} >
        <SaveButton/>
    </Toolbar>
);

export const JobForm = (props: JobCreateProps) => {
    const filterToQuery = (searchText: string) => ({query: `%${searchText}%`});

    const empty: any[] = [];
    const [areas, setAreas] = useState(empty);
    const [size, setSize] = useState(0);
    useEffect(() => {
        httpClient(`${apiUrl}/area?page=1&size=1`, {
            method: 'GET',
        }).then(({json}) => {
            setSize(json.count)
        })
    }, []);
    useEffect(() => {
        if (size !== 0) {
            httpClient(`${apiUrl}/area?page=1&size=${size}`, {
                method: 'GET',
            }).then(({json}) => {
                setAreas(json.items)
            })
        }
    }, [size]);

    const notify = useNotify();

    return <TabbedForm
        toolbar={<JobEditToolbar/>}
        record={props?.isEdit ? props.record : null}
        onSubmit={async (values) => {
            const neededValues = {
                establishmentId: props.establishmentId,
                name: values.jobName,
                area: values.area,
                employmentType: values.employmentType,
                isActive: values.isActive,
                durationDescription: values.durationDescription,
                startsAt: values.startsAt,
                endsAt: values.endsAt,
                expectedSalary: values.expectedSalary,
                image: values.image,
                description: values.description,
                categories: values.categories,
                requiredSkills: values.requiredSkills,
                employeeTypes: values.employeeTypes,
                contactNote: '',
                contactPeople: [],
                administratorInCharge: values.administratorInCharge,
                internalNote: values.internalNote,
                numberOfEmployeesNeeded: values.numberOfEmployeesNeeded,
                highlightOrder: values.highlightOrder,
            }
            if (!props.isEdit) {
                await myDataProvider.create('job', {data: neededValues}).then(res => {
                    if (res.data) {
                        notify(`Sikeres létrehozás`, {type: 'success'});
                        props.onSuccess();
                    } else {
                        notify(`Hiba történt`, {type: 'error'});
                    }
                })
            } else {
                await myDataProvider.update('job', {
                    id: props.record.id,
                    data: neededValues
                }).then(res => {
                    if (res.data) {
                        notify(`Sikeres módosítás`, {type: 'success'});
                        props.onSuccess();
                    } else {
                        notify(`Hiba történt`, {type: 'error'});
                    }
                })
            }
        }}>
        <FormTab label="Információk">
            <Grid container item xs={12} spacing={2}>
                <Grid item xs={6}>
                    <TextInput source="name" label={"Munka megnevezése (kötelező)"} name={"jobName"} fullWidth
                               required/>
                </Grid>
                <Grid item xs={6}>
                    <AutocompleteInput source="area"
                                       optionValue={"name"}
                                       label={"Térség (kötelező)"}
                                       size={"small"}
                                       fullWidth
                                       isRequired
                                       freeSolo
                                       choices={areas}
                                       allowCreate
                                       createText={'+Új térség'}
                                       onCreate={(filter) => {
                                           const newCategoryName = window.prompt('Új térség', filter);
                                           const newCategory = {id: areas.length + 1, name: newCategoryName};
                                           areas.push(newCategory);
                                           return newCategory;
                                       }}
                                       filterToQuery={filterToQuery}
                    />
                </Grid>
            </Grid>
            <Grid container item xs={12} spacing={2} sx={{alignItems: 'center'}}>
                <Grid item xs={6}>
                    <SelectInput source="employmentType" label={"Típus (kötelező)"}
                                 name={"employmentType"}
                                 required
                                 fullWidth
                                 choices={[
                                     {id: 'Occasional', name: 'Alkalmi'},
                                     {id: 'PartTime', name: 'Részmunkaidő'},
                                     {id: 'FullTime', name: 'Teljes munkaidő'},
                                 ]}
                    />
                </Grid>
            </Grid>
            <Grid container item xs={12} spacing={2}>
                <Grid item xs={12}>
                    <TextInput source="durationDescription" label={"Munkavégzés ideje"} name={"durationDescription"}
                               fullWidth/>
                </Grid>
            </Grid>
            <Grid container item xs={12} spacing={2}>
                <Grid item xs={6}>
                    <DateTimeInput format={dateTimeFormatter} source="startsAt" parse={dateParser} label={"Kezdés"}
                                   name={"startsAt"} fullWidth
                                   inputProps={{
                                       max: '2099-12-31T23:59'
                                   }}/>
                </Grid>
                <Grid item xs={6}>
                    <DateTimeInput format={dateTimeFormatter} source="endsAt" parse={dateParser} label={"Befejezés"}
                                   name={"endsAt"} fullWidth
                                   inputProps={{
                                       max: '2099-12-31T23:59'
                                   }}/>
                </Grid>
            </Grid>
            <Grid container item xs={12} spacing={2} sx={{alignItems: 'center'}}>
                <Grid item xs={6}>
                    <TextInput source="expectedSalary" label={"Várható fizetés (kötelező)"} name={"expectedSalary"}
                               fullWidth
                               required/>
                </Grid>
            </Grid>
            {(props.record && props.record.hasOwnProperty("image")) && props.record.image !== null && <Box>
                <Typography sx={{fontSize: '0.75em', color: '#B2BAC2', marginBottom: '0.2em'}}>Jelenlegi
                    kép</Typography>
                <img src={`${props.record.image}`} alt={"current profile picture"}
                     style={{margin: '10px 0px', maxHeight: '200px'}}/>
            </Box>
            }
            <ImageInput source="image" label="Kép" accept="image/*" placeholder={<p>Kép megadása</p>}
                        name={"image"}>
                <ImageField source="src" title="title"/>
            </ImageInput>
            <RichTextInput source="description" label={"Leírás (kötelező)"} name={"description"} fullWidth isRequired
                           toolbar={
                               <RichTextInputToolbar>
                                   <LevelSelect/>
                                   <FormatButtons/>
                                   <ListButtons/>
                                   <LinkButtons/>
                                   <QuoteButtons/>
                                   <ClearButtons/>
                                   <MyImageButton/>
                               </RichTextInputToolbar>
                           }/>
            <ReferenceArrayInput source="categories" reference="category" name={"categories"}>
                <AutocompleteArrayInput optionText="name" fullWidth label={"Kategóriák (kötelező)"} isRequired
                                        size={"small"} filterToQuery={filterToQuery}/>
            </ReferenceArrayInput>
            <ReferenceArrayInput source="requiredSkills" reference="skill" name={"requiredSkills"}>
                <AutocompleteArrayInput optionText="name" fullWidth label={"Elvárt tudások (kötelező)"} isRequired
                                        size={"small"} filterToQuery={filterToQuery}/>
            </ReferenceArrayInput>
            <SelectArrayInput source={"employeeTypes"}
                              fullWidth
                              label={"Munkavállaló típusok (kötelező)"}
                              choices={[
                                  {id: "Adult", name: "Felnőtt"},
                                  {id: "Student", name: "Diák"},
                                  {id: "Retired", name: "Nyugdíjas"},
                              ]}
                              required
            />
            {/*<TextInput source="contactNote" label={"Kapcsolat megjegyzés"} name={"contactNote"} fullWidth/>
            <ReferenceArrayInput source="contactPeople" reference="employer-contact-person" name={"contactPeople"}>
                <AutocompleteArrayInput optionText="name" fullWidth label={"Kapcsolattartók"} size={"small"}/>
            </ReferenceArrayInput>*/}
            <Typography color={'#DEA500'} fontSize={16} fontWeight={600}>Belső információk</Typography>
            <Typography color={'#DEA500'} fontSize={14} sx={{marginBottom: '8px'}}>Az itt megadott információk csak az
                admin
                felhasználók számára lesznek láthatóak</Typography>
            <Box sx={{border: '2px solid #DEA500', borderRadius: '10px', padding: '20px 10px'}}>
                <Grid container item xs={12} spacing={2}>
                    <Grid item xs={6}>
                        <ReferenceInput source="administratorInCharge" reference="administrator"
                                        name={"administratorInCharge"}>
                            <AutocompleteInput label={"Felelős (kötelező)"} source={"name"} size={"small"} fullWidth
                                               isRequired filterToQuery={filterToQuery}/>
                        </ReferenceInput>
                    </Grid>
                    <Grid item xs={6}>
                        <BooleanInput source="isActive" label={"Aktív (kötelező)"}
                                      name={"isActive"}
                                      fullWidth
                                      defaultValue={true}
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <TextInput source="internalNote" label={"Belső megjegyzés"} name={"internalNote"} fullWidth/>
                    </Grid>
                </Grid>
                <Grid container spacing={2}>
                    <Grid item xs={6}>
                        <NumberInput source="numberOfEmployeesNeeded" label={"Szükséges munkavállalók (kötelező)"}
                                     name={"numberOfEmployeesNeeded"} fullWidth required/>
                    </Grid>
                    <Grid item xs={6}>
                        <NumberInput source="highlightOrder" label={"Kiemelés mértéke"}
                                     name={"highlightOrder"} fullWidth/>
                        <Typography color={'#DEA500'} fontSize={14} sx={{marginBottom: '8px'}}>Minél nagyobb az érték
                            annál előrébb kerül a munka az ajánlások során</Typography>
                    </Grid>
                </Grid>
            </Box>
        </FormTab>
    </TabbedForm>
}

export const JobCreate = () => (
    <Create title={'Munka létrehozása'}>
        <Box></Box>
        {/*<JobForm establishmentId={props.establishmentId}/>*/}
    </Create>
);
