import * as React from "react";
import {useState} from "react";
import {Button, FileField, FileInput, RaRecord, SimpleForm, TextInput, Toolbar, useNotify} from 'react-admin';
import {apiUrl, httpClient} from "../dataProvider";
import SaveOutlinedIcon from "@mui/icons-material/SaveOutlined";
import {useFormContext} from "react-hook-form";
import Box from "@mui/material/Box";
import {EmployeeDocumentsList} from "./employee-documents-ist";
import {Divider, Grid, Typography} from "@mui/material";

export interface Props {
    record: RaRecord;
}

interface EmployeeDocumentsToolbarProps {
    record: RaRecord;
    refresh: boolean;
    setRefresh: any;
}

const EmployeeDocumentsToolbar = (props: EmployeeDocumentsToolbarProps) => {
    const form = useFormContext();
    const notify = useNotify();
    const values = form.getValues();
    return <Toolbar {...props} >
        <Button label="Mentés" onClick={async () => {
            await Promise.all([values.path].map(convertFileToBase64))
                .then(transformedNewPictures => {
                    httpClient(`${apiUrl}/employee/${props.record.id}/attachment`, {
                        method: 'POST',
                        body: JSON.stringify({
                            name: `${values.docName}.${values.path.rawFile.name.split('.')[1]}`,
                            path: transformedNewPictures[0]
                        }),
                    }).then(r => {
                        if (r.status === 201) {
                            notify(`Dokumentum felvéve`, {type: 'success'});
                            props.setRefresh(!props.refresh);
                            form.reset();
                        } else {
                            notify(`Hiba történt a dokumentum létrehozásakor`, {type: 'error'});
                        }
                    })
                })
        }} variant={"contained"}
                startIcon={<SaveOutlinedIcon style={{fontSize: '20px'}}/>}
        />
    </Toolbar>
};

const convertFileToBase64 = (file: any) =>
    new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.onload = () => resolve(reader.result);
        reader.onerror = reject;

        reader.readAsDataURL(file.rawFile);
    });

export const EmployeeDocumentsForm = (props: Props) => {
    const [refresh, setRefresh] = useState(false);
    return <Box>
        <EmployeeDocumentsList record={props.record} refresh={refresh} setRefresh={setRefresh}/>
        <Divider sx={{width: '100%', borderBottomWidth: '3px', marginBottom: '25px', marginTop: '10px'}}/>
        <Typography fontSize={18} fontWeight={700} sx={{marginLeft: '16px'}}>Új dokumentum</Typography>
        <SimpleForm toolbar={<EmployeeDocumentsToolbar record={props.record} refresh={refresh} setRefresh={setRefresh}/>}>
            <TextInput source="docName" label={"Név (kötelező)"} name={"docName"} required fullWidth/>
            <FileInput source="path" label="Fájl (kötelező)">
                <FileField source="src" title="title"/>
            </FileInput>
        </SimpleForm>
    </Box>
}

