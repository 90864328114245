import * as React from "react";
import {Datagrid, List, TextField, TextInput} from 'react-admin';

const coworkerFilters = [
    <TextInput label="Kulcsszó" source="query" name={"query"}/>,
];

export const CoworkerList = () => (
    <List title={'Munkatársak'}
          exporter={false} filters={coworkerFilters}
    >
        <Datagrid bulkActionButtons={false} rowClick={"show"}>
            <TextField source="id" label={"ID"} sortable={false}/>
            <TextField source="name" label={"Név"}/>
            <TextField source="email" label={"E-mail"}/>
            <TextField source="phone" label={"Telefonszám"}/>
        </Datagrid>
    </List>
);