import * as React from "react";
import {Button, IconButton, Stack} from "@mui/material";
import {AutocompleteInput, DateInput, FormDataConsumer, ReferenceInput, TextInput} from "react-admin";
import ChevronLeftOutlinedIcon from "@mui/icons-material/ChevronLeftOutlined";
import {dateTimeFormatter} from "../../date-formatter/date-formatter";
import ChevronRightOutlinedIcon from "@mui/icons-material/ChevronRightOutlined";
import FileDownloadOutlinedIcon from "@mui/icons-material/FileDownloadOutlined";
import {useFormContext} from "react-hook-form";
import {apiUrl, httpClient} from "../../dataProvider";
import AddOutlinedIcon from "@mui/icons-material/AddOutlined";
import {exportExcel} from "../../export-excel";

export interface ScheduleFiltersProp {
    timeTableRefresh: boolean
    setTimeTableRefresh: any
    record: any
    isOpen: boolean
    setIsOpen: any
    establishmentId: number
}

function treatAsUTC(date: Date): any {
    var result = new Date(date);
    result.setMinutes(result.getMinutes() - result.getTimezoneOffset());
    return result;
}

function daysBetween(startDate: Date, endDate: Date) {
    var millisecondsPerDay = 24 * 60 * 60 * 1000;
    return (treatAsUTC(endDate) - treatAsUTC(startDate)) / millisecondsPerDay;
}

const exporter = (values: any, jobId: number) => {
    const excelValues = values.map((v: any, index: number) => {
        return {
            'Dátum': v.date,
            'Név': v.name,
            'Kezdés': v.start,
            'Befejezés': v.end,
            'Pozíció': v.employmentName,
        }
    });
    exportExcel({
        excelData: excelValues,
        fileName: `beosztas_${jobId}`
    }).then(r => {
    });
}

export const setToMonday = (date: any) => {
    var day = date.getDay() || 7;
    if (day !== 1)
        date.setHours(-24 * (day - 1));
    return date;
}

export const addDays = (date: any, days: number) => {
    const copy = new Date(Number(date))
    copy.setDate(date.getDate() + days)
    return copy
}

export const getDateString = (date: any) => {
    const dateMonth = date.getMonth() + 1 >= 10 ? date.getMonth() + 1 : `0${date.getMonth() + 1}`;
    const dateDay = date.getDate() >= 10 ? date.getDate().toString() : `0${date.getDate().toString()}`;

    return `${date.getFullYear().toString()}-${dateMonth}-${dateDay}`;
}

const renderOption = (data: any) => {
    if (data) {
        return `${data.name} (ID: ${data.id})`
    }
    return '';
};

const inputText = (record: any) => `${record.name} (ID: ${record.id})`;

export const ScheduleFilters = (props: ScheduleFiltersProp) => {
    const form = useFormContext();
    const formValues = form.getValues();
    let filterDateStart = setToMonday(new Date());
    let filterDateEnd = addDays(filterDateStart, 7);

    const filterStartString = getDateString(filterDateStart);
    const filterEndString = getDateString(filterDateEnd);
    const filterToQuery = (searchText:string) => ({ query: `%${searchText}%` });

    return <Stack direction={'row'} spacing={1}>
        <ReferenceInput source="jobId"
                        reference="job"
                        name={"jobId"}
                        filter={{establishmentId: props.establishmentId}}
                        helperText={false}
        >
            <AutocompleteInput source="name"
                               label={"Munka"}
                               size={"small"}
                               fullWidth
                               inputText={inputText}
                               optionText={renderOption}
                               optionValue={"id"}
                               helperText={false}
                               onChange={() => {
                                   form.setValue('jobId', formValues.jobId)
                                   props.setTimeTableRefresh(!props.timeTableRefresh)
                               }}
                               filterToQuery={filterToQuery}
            />
        </ReferenceInput>
        <FormDataConsumer>
            {({formData, ...rest}) => {
                return <IconButton color={'primary'}
                                   sx={{marginTop: '4px'}}
                                   onClick={() => {
                                       const fromYear = formValues.from.toString().substring(0, 4);
                                       const fromMonth = formValues.from.toString().substring(5, 7);
                                       const fromDay = formValues.from.toString().substring(8, 10);
                                       const fromDateValue = new Date(parseInt(fromYear), parseInt(fromMonth) - 1, parseInt(fromDay));

                                       const toYear = formValues.to.toString().substring(0, 4);
                                       const toMonth = formValues.to.toString().substring(5, 7);
                                       const toDay = formValues.to.toString().substring(8, 10);
                                       const toDateValue = new Date(parseInt(toYear), parseInt(toMonth) - 1, parseInt(toDay));

                                       const diffDays = daysBetween(fromDateValue, toDateValue);
                                       const to = new Date(toDateValue.getTime() - 86400000 * diffDays);
                                       const from = new Date(fromDateValue.getTime() - 86400000 * diffDays);

                                       form.setValue('from', dateTimeFormatter(from));
                                       form.setValue('to', dateTimeFormatter(to));
                                       props.setTimeTableRefresh(!props.timeTableRefresh)
                                   }}>
                    <ChevronLeftOutlinedIcon/>
                </IconButton>
            }
            }
        </FormDataConsumer>
        <DateInput source="from" label={"Ettől"} name={"from"} fullWidth defaultValue={filterStartString}
                   onChange={() => props.setTimeTableRefresh(!props.timeTableRefresh)}
                   inputProps={{
                       max: '2099-12-31T23:59'
                   }}/>
        <DateInput source="to" label={"Eddig"} name={"to"} fullWidth defaultValue={filterEndString}
                   onChange={() => props.setTimeTableRefresh(!props.timeTableRefresh)}
                   inputProps={{
                       max: '2099-12-31T23:59'
                   }}/>
        <FormDataConsumer>
            {({formData, ...rest}) => {
                return <IconButton
                    color={'primary'}
                    style={{marginTop: '4px'}}
                    onClick={() => {
                        const fromYear = formValues.from.toString().substring(0, 4);
                        const fromMonth = formValues.from.toString().substring(5, 7);
                        const fromDay = formValues.from.toString().substring(8, 10);
                        const fromDateValue = new Date(parseInt(fromYear), parseInt(fromMonth) - 1, parseInt(fromDay));

                        const toYear = formValues.to.toString().substring(0, 4);
                        const toMonth = formValues.to.toString().substring(5, 7);
                        const toDay = formValues.to.toString().substring(8, 10);
                        const toDateValue = new Date(parseInt(toYear), parseInt(toMonth) - 1, parseInt(toDay));

                        const diffDays = daysBetween(fromDateValue, toDateValue);
                        const to = new Date(toDateValue.getTime() + 86400000 * diffDays);
                        const from = new Date(fromDateValue.getTime() + 86400000 * diffDays);

                        form.setValue('from', dateTimeFormatter(from));
                        form.setValue('to', dateTimeFormatter(to));
                        props.setTimeTableRefresh(!props.timeTableRefresh)
                    }}
                >
                    <ChevronRightOutlinedIcon/>
                </IconButton>
            }
            }
        </FormDataConsumer>
        <IconButton color={'primary'}
                    style={{marginTop: '4px'}}
                    onClick={async () => {
                        let dateQueryString = '';
                        if (typeof formValues.from !== "undefined" && formValues.from !== '') {
                            dateQueryString += `&from=${formValues.from.substring(0,10)}`
                        }
                        if (typeof formValues.to !== "undefined" && formValues.to !== '') {
                            dateQueryString += `&to=${formValues.to.substring(0,10)}`
                        }
                        await httpClient(`${apiUrl}/schedule-export?establishmentId=${props.establishmentId}${dateQueryString}`, {
                                method: 'GET',
                            }
                        ).then(({json}) => {
                            exporter(json, props.record.id as number)
                        })
                    }}>
            <FileDownloadOutlinedIcon/>
        </IconButton>
        <Button variant="outlined"
                onClick={() => {
                    props.setIsOpen(!props.isOpen)
                }}
                size={'small'}
                startIcon={<AddOutlinedIcon/>}
                style={{height: '36px', minWidth: '88px', marginTop: '3px'}}
        >
            Új nap
        </Button>
    </Stack>
};
