import * as React from "react";
import {useEffect, useState} from "react";
import {
    Button,
    Edit,
    FormTab,
    SaveButton,
    TabbedForm,
    Toolbar,
    useEditContext,
    useNotify,
    useRedirect, useUpdate
} from 'react-admin';
import dataProvider, {apiUrl, httpClient} from "../../dataProvider";
import {JobApplicationInfosTab} from "./job-application-infos-tab";
import {JobApplicationFeedbackTab} from "./job-application-feedback-tab";
import {JobApplicationSubtractionsTab} from "./job-application-subtractions-tab";
import {DocumentDeleteWarningDialog} from "../../document/document-delete-warning-dialog";
import DeleteOutlineOutlinedIcon from "@mui/icons-material/DeleteOutlineOutlined";
import {useFormContext} from "react-hook-form";
import myDataProvider from "../../data-provider-with-image-convert";

const CustomToolbar = (props: any) => {
    const [deleteOpen, setDeleteOpen] = useState(false);
    const notify = useNotify();
    const redirect = useRedirect();

    const [update] = useUpdate();
    const {getValues} = useFormContext();

    const handleClick = (e: { preventDefault: () => void; }) => {
        e.preventDefault();
        const {id, ...data} = getValues();
        if (props.tab === `${id}tab`) {
            const jobApplicationValues: any = {
                jobId: data.jobId,
                employeeId: data.employeeId,
                status: data.status,
                canStartAt: data.canStartAt,
                canFinishAt: data.canFinishAt,
                willBeAbleToCome: data.willBeAbleToCome,
            }
            if (data.salary.type && data.salary.salary) {
                jobApplicationValues.salary = {salary: data.salary.salary, type: data.salary.type}
            }
            if (data.employmentType) {
                jobApplicationValues.employmentType = data.employmentType
            }
            update(
                'job-application',
                {id, data: jobApplicationValues},
                {
                    onSuccess: () => {
                        notify(`Jelentkezés módosítva`, {type: 'success'});
                    }
                }
            );
        } else if(props.tab === '1' && props.feedBackId) {
            const feedBackValues = {
                actualWorkingHours: data.actualWorkingHours,
                note: data.note,
                jobApplicationId: id,
            }
            update(
                'job-feedback',
                {id: props.feedBackId, data: feedBackValues},
                {
                    onSuccess: () => {
                        notify(`Utókövetés módosítva`, {type: 'success'});
                    }
                }
            );
        } else if(props.tab === '1') {
            const feedBackValues = {
                actualWorkingHours: data.actualWorkingHours,
                note: data.note,
                jobApplicationId: id,
            }
            myDataProvider.create(
                'job-feedback',
                {data: feedBackValues},
            ).then(res => {
                if (res.data) {
                    notify(`Utókövetés létrehozva`, {type: 'success'});
                } else {
                    notify(`Hiba történt`, {type: 'error'});
                }
            });
        }
    };

    return <Toolbar
        sx={{display: 'flex', justifyContent: 'space-between'}}
    >
        <SaveButton onClick={handleClick}/>
        {!props.noDelete && <Button label="Törlés" onClick={() => setDeleteOpen(true)}
                                    variant={"text"}
                                    sx={{
                                        color: '#ea1426',
                                        '&:hover': {
                                            color: '#ea1426'
                                        }
                                    }}
                                    startIcon={<DeleteOutlineOutlinedIcon
                                        style={{fontSize: '20px'}}/>}
        />}
        <DocumentDeleteWarningDialog title={'Munkára jelentkezés törlése'}
                                     description={'Biztosan törölni szeretnéd ezt a jelentkezést? A jelentkezéshez tartozó összes beosztás és visszajelzés törlődik'}
                                     isOpen={deleteOpen}
                                     setIsOpen={setDeleteOpen}
                                     onAccept={() => {
                                         dataProvider.delete('job-application', {id: props.id}).then(r => {
                                             redirect('list', 'job-application');
                                             notify(`Jelentkezés eltávolítva`, {type: 'success'});
                                         });
                                         setDeleteOpen(false)
                                     }}/>
    </Toolbar>
};

// @ts-ignore
const JobApplicationForm = () => {
    const {record} = useEditContext();
    const [currentTab, setCurrentTab] = useState(`${record.id}`);
    const notify = useNotify();
    const redirect = useRedirect();

    useEffect(() => {
        if (typeof window !== 'undefined') {
            const tab = window.location.href.match('([^\\/]+$)');
            const link = window.location.href.split('job-application');
            const counter = link[1].split('/');
            let newValue = tab !== null ? tab[0] : '';
            if (counter.length < 3) {
                newValue += 'tab';
            }
            setCurrentTab(newValue)
        }
    }, [window.location.href]);

    const [feedBack, setFeedBack]: any = useState();
    const [fetched, setFetched] = useState(false);
    useEffect(() => {
        if (typeof feedBack === 'undefined') {
            httpClient(`${apiUrl}/job-feedback?applicationId=${record.id}&page=1&size=1`, {
                method: 'GET',
            }).then(({json}) => {
                setFeedBack(json.items[0]);
                setFetched(true);
            })
        }
    })

    const [substractions, setSubstractions] = useState();
    const [fetchSubs, setFetchSubs] = useState(false);
    useEffect(() => {
        if (typeof feedBack !== 'undefined') {
            // @ts-ignore
            httpClient(`${apiUrl}/job-subtraction?feedbackId=${feedBack.id}&page=1&size=100`, {
                method: 'GET',
            }).then(({json}) => {
                setSubstractions(json.items)
                setFetchSubs(true)
            })
        }
    }, [feedBack, fetchSubs])

    const firstTabRequired = currentTab === `${record.id}tab`;
    const thirdTabRequired = currentTab === `1`;
    const fourthTabRequired = currentTab === `2`;

    return <TabbedForm toolbar={fourthTabRequired ? <></> :
        <CustomToolbar id={record.id} noDelete={thirdTabRequired} tab={currentTab} feedBackId={feedBack?.id}/>}
                       onSubmit={async (values) => {
                           if (firstTabRequired) {
                               await dataProvider.update('job-application', {
                                   id: record.id,
                                   data: {
                                       id: record.id,
                                       jobId: values.jobId,
                                       employeeId: values.employeeId,
                                       status: values.status,
                                       salary: (values.salary.salary === null || values.salary.type === '') ? null : values.salary,
                                       contractAttachmentId: values.contractAttachmentId === '' ? null : values.contractAttachmentId,
                                       willBeAbleToCome: values.willBeAbleToCome,
                                       employmentType: values.employmentType === '' ? null : values.employmentType,
                                       canStartAt: values.canStartAt,
                                       canFinishAt: values.canFinishAt,
                                   }
                               }).then(r => {
                                   redirect('/job-application')
                                   notify(`Sikeres módosítás`, {type: 'success'});
                               })
                           } else if (thirdTabRequired) {
                               if (typeof feedBack === 'undefined') {
                                   await httpClient(`${apiUrl}/job-feedback`, {
                                       method: 'POST',
                                       body: JSON.stringify({
                                           jobApplicationId: record.id,
                                           actualWorkingHours: values.actualWorkingHours,
                                           note: values.note
                                       }),
                                   }).then(r => {
                                       if (r.status === 201) {
                                           setFetchSubs(!fetchSubs);
                                           notify(`Utókövetés létrehozva`, {type: 'success'});
                                       } else {
                                           notify(`Hiba történt az utókövetés létrehozásakor`, {type: 'error'});
                                       }
                                   })
                               } else {
                                   await httpClient(`${apiUrl}/job-feedback/${feedBack.id}`, {
                                       method: 'PUT',
                                       body: JSON.stringify({
                                           jobApplicationId: record.id,
                                           wasJobSuccessful: values.wasJobSuccessful,
                                           actualWorkingHours: values.actualWorkingHours,
                                           note: values.note
                                       }),
                                   }).then(r => {
                                       if (r.status === 200) {
                                           setFetchSubs(!fetchSubs);
                                           notify(`Utókövetés módosítva`, {type: 'success'});
                                       } else {
                                           notify(`Hiba történt az utókövetés módosításakor`, {type: 'error'});
                                       }
                                   })
                               }
                           }
                       }}>
        <FormTab label="Alap információk">
            <JobApplicationInfosTab required={firstTabRequired}/>
        </FormTab>
        <FormTab label="Utókövetés">
            <JobApplicationFeedbackTab feedBack={feedBack}
                                       fetched={fetched}
                                       required={thirdTabRequired}/>
        </FormTab>
        {typeof feedBack !== 'undefined' && <FormTab label="Levonások">
            <JobApplicationSubtractionsTab setFetchSubs={setFetchSubs}
                                           required={fourthTabRequired}
                                           fetchSubs={fetchSubs}
                                           substractions={substractions}
                                           feedBackId={feedBack.id}
            />
        </FormTab>
        }
    </TabbedForm>
}

export const JobApplicationEdit = () => {
    return <Edit title={'Jelentkezés szerkesztése'}>
        <JobApplicationForm/>
    </Edit>
};
