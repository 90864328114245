import * as React from "react";
import {Create, SelectInput, SimpleForm, TextInput, FormDataConsumer, FileField, FileInput} from 'react-admin';
import Box from "@mui/material/Box";

interface DocCreateProps {
    parentId?: number | null;
}

const DocumentForm = (props: DocCreateProps) => {
    return <SimpleForm>
        <SelectInput source="type" label={"Típus (kötelező)"}
                     fullWidth
                     required
                     defaultValue={'Folder'}
                     choices={[
                         {id: 'Folder', name: 'Mappa'},
                         {id: 'File', name: 'Fájl'},
                     ]}/>
        <FormDataConsumer>
            {({formData, ...rest}) => {
               return <Box sx={{width: '100%'}}>
                    {(formData.type === 'Folder' || typeof formData.type === 'undefined') &&
                        <TextInput source={'name'} name={'name'} label={'Név (kötelező)'} fullWidth/>}
                </Box>
            }
            }
        </FormDataConsumer>
        <Box sx={{display: 'none'}}>
            <TextInput source={'parentId'} name={'parentId'} label={'Szülő (kötelező)'}
                       defaultValue={props?.parentId ?? null}/>
        </Box>
        <FormDataConsumer>
            {({formData, ...rest}) =>
                <Box>
                    {formData.type === 'File' && <FileInput source="url" label="Fájl (kötelező)" isRequired>
                        <FileField source="src" title="title"/>
                    </FileInput>
                    }
                </Box>
            }
        </FormDataConsumer>
    </SimpleForm>
}

export const DocumentCreate = (props: DocCreateProps) => (
    <Create title={'Mappa/fájl létrehozása'}>
        <DocumentForm parentId={props.parentId}/>
    </Create>
);
