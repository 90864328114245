import * as React from "react";
import {Create, Edit, ImageField, ImageInput, SimpleForm, TextInput, useRecordContext} from 'react-admin';
import {Box, Grid, Typography} from "@mui/material";

const CoworkerForm = () => {
    const record = useRecordContext();

    return <SimpleForm>
        <Grid container item xs={12} spacing={2}>
            <Grid item xs={6}>
                <TextInput source="name" label={"Név (kötelező)"} name={"name"} required fullWidth/>
            </Grid>
            <Grid item xs={6}>
                <TextInput source="email" label={"E-mail"} name={"email"} fullWidth/>
            </Grid>
        </Grid>
        <Grid container item xs={12} spacing={2}>
            <Grid item xs={6}>
                <TextInput source="phone" label={"Telefonszám"} name={"phone"} fullWidth/>
            </Grid>
            <Grid item xs={6}>
                <TextInput source="position" label={"Pozíció"} name={"position"} fullWidth/>
            </Grid>
        </Grid>
        <Grid container item xs={12} spacing={2}>
            <Grid item xs={6}>
                <TextInput source="facebookLink" label={"Facebook link (pl.: https://m.me/biztos.kesz)"} name={"facebookLink"} fullWidth/>
            </Grid>
        </Grid>
        {(record && record.hasOwnProperty("profilePicture")) && record.profilePicture !== null && <Box>
            <Typography sx={{fontSize: '0.75em', color: '#B2BAC2', marginBottom: '0.2em'}}>Jelenlegi
                profilkép</Typography>
            <img src={`${record.profilePicture}`} alt={"current profile picture"}
                 style={{margin: '10px 0px', maxHeight: '200px'}}/>
        </Box>
        }
        <ImageInput source="profilePicture" label="Profil kép" accept="image/*" placeholder={<p>Kép megadása</p>}
                    name={"profilePicture"}>
            <ImageField source="src" title="title"/>
        </ImageInput>
    </SimpleForm>
}

export const CoworkerEdit = () => (
    <Edit title={'Munkatárs szerkesztése'}>
        <CoworkerForm/>
    </Edit>
);

export const CoworkerCreate = () => (
    <Create title={'Munkatárs hozzáadása'}>
        <CoworkerForm/>
    </Create>
);
