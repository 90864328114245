import * as React from "react";
import {useState} from "react";
import FolderOpenOutlinedIcon from "@mui/icons-material/FolderOpenOutlined";
import FolderOutlinedIcon from "@mui/icons-material/FolderOutlined";
import InsertDriveFileOutlinedIcon from "@mui/icons-material/InsertDriveFileOutlined";
import {Stack} from "@mui/material";
import FileSaver from "file-saver";
import Box from "@mui/material/Box";
import {ApiDocument} from "./document-list";
import DownloadOutlinedIcon from '@mui/icons-material/DownloadOutlined';
import DownloadIcon from '@mui/icons-material/Download';
import DeleteOutlineOutlinedIcon from '@mui/icons-material/DeleteOutlineOutlined';
import DeleteIcon from '@mui/icons-material/Delete';
import {useNotify, useRefresh, useTheme} from "react-admin";
import dataProvider from "../dataProvider";
import {DocumentDeleteWarningDialog} from "./document-delete-warning-dialog";

interface Props {
    document: ApiDocument;
    setParentIdFilter: any;
}

export const LinkedDoc = (props: Props) => {
    const [hover, setHover] = useState(false);
    const [hoverDownload, setHoverDownload] = useState(false);
    const [hoverDelete, setHoverDelete] = useState(false);
    const folderIcon = hover ? <FolderOpenOutlinedIcon/> : <FolderOutlinedIcon/>;
    const fileIcon = <InsertDriveFileOutlinedIcon/>;
    const [theme] = useTheme();
    // @ts-ignore
    const mainColor = theme.palette?.text?.primary ?? "#42C9AD"
    const refresh = useRefresh();
    const notify = useNotify();
    const [deleteOpen, setDeleteOpen] = useState(false);

    return <Box sx={{
        width: '100%',
        padding: '5px',
        borderRadius: '8px',
        border: props.document.type === 'File' ? `1px solid ${mainColor}` : 'none'
    }}
                onClick={async () => {
                    if (props.document.type === 'Folder') {
                        props.setParentIdFilter({
                            parentId: props.document.id
                        })
                    }
                }}
                onMouseEnter={() => setHover(true)}
                onMouseLeave={() => setHover(false)}>

        <Stack direction={"row"} alignItems={'center'} justifyContent={'space-between'} sx={{width: '100%'}}>
            <Stack direction={"row"} alignItems={'center'}
                   sx={{
                       cursor: props.document.type === 'Folder' ? 'pointer' : '',
                       fontSize: '20px',
                       width: 'max-content'
                   }}>
                {props.document.type === 'Folder' ? folderIcon : fileIcon}
                <Box sx={{marginLeft: '10px'}}>
                    {props.document.name}
                </Box>
            </Stack>
            {props.document.type === 'File' &&
                <Stack direction={"row"} alignItems={'center'} justifyContent={'flex-end'} sx={{paddingTop: '5px'}}>
                    <Box onMouseEnter={() => setHoverDownload(true)}
                         onMouseLeave={() => setHoverDownload(false)}
                         sx={{cursor: 'pointer', marginRight: '5px'}}
                         onClick={() => {
                             if (props.document.url !== null) {
                                 FileSaver.saveAs(
                                     props.document.url,
                                     `${props.document.name}`
                                 );
                             }
                         }
                         }
                    >
                        {!hoverDownload ? <DownloadOutlinedIcon color={'primary'} sx={{fontSize: 24,}}/> :
                            <DownloadIcon color={'primary'} sx={{fontSize: 24,}}/>}
                    </Box>
                    <Box onMouseEnter={() => setHoverDelete(true)}
                         onMouseLeave={() => setHoverDelete(false)}
                         sx={{cursor: 'pointer', marginRight: '5px'}}
                         onClick={() => setDeleteOpen(true)}
                    >
                        {!hoverDelete ? <DeleteOutlineOutlinedIcon color={'error'} sx={{fontSize: 24,}}/> :
                            <DeleteIcon color={'error'} sx={{fontSize: 24,}}/>}
                    </Box>
                    <DocumentDeleteWarningDialog title={'Dokumentum törlése'}
                                                 description={'Biztosan törölni szeretnéd ezt a dokumentumot?'}
                                                 isOpen={deleteOpen}
                                                 setIsOpen={setDeleteOpen}
                                                 onAccept={() => {
                                                     dataProvider.delete('document', {id: props.document.id}).then(r => {
                                                         refresh();
                                                         notify(`Fájl eltávolítva`, {type: 'success'});
                                                     });
                                                     setDeleteOpen(false)
                                                 }}/>
                </Stack>}
        </Stack>
    </Box>
}
