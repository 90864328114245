import {FunctionField, useGetOne} from "react-admin";
import {Box} from "@mui/material";
import * as React from "react";

interface EstablishmentFieldWrapperProps {
    jobId: number,
}

export const EstablishmentFieldWrapper = (props: EstablishmentFieldWrapperProps) => {
    const {data: job, isLoading, error} = useGetOne('job', {id: props.jobId});
    if (isLoading) {
        return <Box/>;
    }
    if (error) {
        return <p>ERROR</p>;
    }
    return <EstablishmentField
        establishmentId={job.establishmentId}
    />
}

interface EstablishmentFieldProps {
    establishmentId: number,
}

const EstablishmentField = (props: EstablishmentFieldProps) => {
    const {data: establishment, isLoading, error} = useGetOne('establishment', {id: props.establishmentId});
    if (isLoading) {
        return <Box/>;
    }
    if (error) {
        return <p>ERROR</p>;
    }
    return <FunctionField
        render={() => `${establishment.name}`}
    />
}
